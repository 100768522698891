// 静态路由
export default [
  {
    path: '/feature/settings',
    name: 'guide',
    component: () => import('@/views/treasurebox/FeatureSettings.vue'),
    meta: {
      header: true,
      title: '常用功能设置'
    }
  }
]
