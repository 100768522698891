export default {
  namespaced: true,
  state: () => ({
    commonFunctions: [], // 常用功能模块
    tabsUseableFunctions: [], // tab菜单下可用功能模块
    tabsAllFunctions: [], // tab菜单下所有功能模块
    boxFeatureType: localStorage.getItem('box_feature_type') || 'all', // 百宝箱功能类型
    commonMaxNum: 8 //常用功能限制数量
  }),
  mutations: {
    changeCommonFunction(state, list = []) {
      state.commonFunctions = list
    },
    changeTabsUseableFunctions(state, list = []) {
      state.tabsUseableFunctions = list
    },
    changeTabsAllFunctions(state, list = []) {
      state.tabsAllFunctions = list
    },
    changeBoxFeatureType(state, value) {
      state.boxFeatureType = value
      localStorage.setItem('box_feature_type', value)
    },
    changeCommonMaxNum (state, value) {
      state.commonMaxNum = value
    }
  },
  actions: {
    setCommonFunction({ commit }, list = []) {
      commit('changeCommonFunction', JSON.parse(JSON.stringify(list)))
    },
    setTabsUseableFunctions({ commit }, list = []) {
      commit('changeTabsUseableFunctions', list)
    },
    setTabsAllFunctions({ commit }, list = []) {
      commit('changeTabsAllFunctions', list)
    },
    setBoxFeatureType({ commit }, value) {
      commit('changeBoxFeatureType', value)
    },
    // 设置常用功能最大限制数量
    setCommonMaxNum ({ commit }, value) {
      commit('changeCommonMaxNum', value)
    }
  },
  getters: {
    // 常用功能
    commonFunctions(state) {
      return state.commonFunctions || []
    },
    // 可用功能
    tabsUseableFunctions(state) {
      return state.tabsUseableFunctions || []
    },
    // 所有功能
    tabsAllFunctions(state) {
      return state.tabsAllFunctions || []
    },
    boxFeatureType(state) {
      return state.boxFeatureType || 'all'
    },
    // 常用功能最大限制数量
    commonMaxNum (state) {
      return state.commonMaxNum || 8
    }
  }
}
