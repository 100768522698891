import MonitorApiBase from './base'
import {
  ajaxFollowProjectInfo,
  ajaxFollowProjectAdd,
  ajaxFollowProjectCancel
} from '../api/follow-project'

export default class MonitorProjectApi extends MonitorApiBase {
  constructor({ id, fid = '' }) {
    super()
    this.id = id
    this.fid = fid
  }

  /**
   * 提供覆盖的ajax请求
   * @return {{}}
   */
  async ajaxGetState() {
    return await ajaxFollowProjectInfo({ sid: this.id }).then((res) => {
      const result = this.createModel()
      result.canFollow = res?.data?.showFollow || false
      result.follow = res?.data?.flag || false

      if (res?.data?.info) {
        result.expands = res.data.info
      }
      if (res?.data?.fig) {
        this.fid = res.data.fig
      }
      return result
    })
  }
  async ajaxAdd() {
    return ajaxFollowProjectAdd({ sid: this.id }).then((res) => {
      const result = {
        success: false,
        msg: '',
        data: {}
      }
      result.success = res?.error_code === 0 && res?.data?.status
      result.data = res?.data
      if (res?.data?.followId) {
        this.fid = res.data.followId
      }
      if (result.success) {
        this.model.expands.used += 1
        this.model.expands.surplus = Math.max(this.model.expands.surplus - 1, 0)
      } else {
        result.msg = res?.error_msg || '抱歉，操作失败'
      }
      return result
    })
  }
  async ajaxRemove() {
    return ajaxFollowProjectCancel({ fid: this.fid }).then(
      (res) => {
        const result = {
          success: false,
          msg: '',
          data: {}
        }
        result.success = res?.error_code === 0 && res?.data === 'success'
        result.data = res?.data
        if (result.success) {
          this.fid = ''
          this.model.expands.surplus += 1
          this.model.expands.used = Math.max(this.model.expands.used - 1, 0)
        } else {
          result.msg = res?.error_msg || '抱歉，操作失败'
        }
        return result
      }
    )
  }
}
