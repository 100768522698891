import request from '@/api'

// 获取已添加的信息id数组
export function ajaxGetInfoIds() {
  return request({
    url: '/jyapi/biService/getInfoId',
    method: 'post'
  })
}

// 添加信息
export function ajaxSetInfoId(data) {
  return request({
    url: '/jyapi/biService/addProject',
    method: 'post',
    data
  })
}

export function getUserResource() {
  return request({
    url: '/jyapi/resourceCenter/haspowers',
    method: 'post'
  })
}
