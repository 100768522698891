// 落地页路由
export default [
  {
    path: '/service/consult',
    name: 'consultingService',
    component: () => import('@/views/landing/consultingService.vue'),
    meta: {
      header: true,
      title: '咨询服务'
    }
  }
]