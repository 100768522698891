<template>
  <!-- 与 vant cell 布局不同 -->
  <!-- vant cell label 文字长度不能超过宽度的一般，但在此组件不存在这个问题 -->
  <div
    class="j-row-cell bg-white"
    :class="{ 'cell-clickable': isLink }"
    @click="onClick"
    ref="cellRef"
    v-on="$listeners"
  >
    <div class="j-row-cell-content" :class="contentClass">
      <div class="j-row-cell-hd">
        <div class="cell-title">
          <slot name="title">
            <span class="cell-title-text">{{ title }}</span>
          </slot>
        </div>
        <div class="cell-value">
          <slot name="value">
            <span class="cell-value-text ellipsis">{{ value }}</span>
          </slot>
          <van-icon name="arrow" v-if="isLink" />
        </div>
      </div>
      <div class="j-row-cell-ft">
        <div class="cell-label">
          <slot name="label">
            <div class="cell-label-text">{{ label }}</div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant'
export default {
  name: 'JCell',
  components: {
    [Icon.name]: Icon
  },
  props: {
    contentClass: {
      type: Array,
      default() {
        return []
      }
    },
    isLink: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClick() {
      this.$emit('onClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.j-row-cell {
  padding: 0 16px;
  &-content {
    padding: 16px 0;
  }
  &-hd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cell-title {
      color: #171826;
      font-size: 16px;
      line-height: 24px;
    }
    .cell-value {
      display: flex;
      align-items: center;
      font-size: 14px;
      max-width: 50%;
      color: #9b9ca3;
      .cell-value-text {
        flex: 1;
      }
      .van-icon {
        margin-left: 4px;
        color: #c0c4cc;
      }
    }
  }
  &-ft {
    .cell-label {
      margin-top: 4px;
      color: #171826;
      font-size: 13px;
      line-height: 20px;
    }
  }
}
</style>
