// 数据超市路由
export default [
  {
    path: '/',
    name: 'data-smt-list',
    component: () => import('@/views/dataSmt/index.vue'),
    meta: {
      header: true,
      title: '数据超市'
    }
  },
  {
    path: '/list',
    name: 'data-smt-search',
    component: () => import('@/views/dataSmt/list.vue'),
    meta: {
      header: false,
      title: '数据超市搜索'
    }
  },
  {
    path: '/list/:id',
    name: 'data-smt-detail',
    component: () => import('@/views/dataSmt/detail.vue'),
    meta: {
      header: true,
      title: '数据超市产品详情'
    }
  }
]
