import rgbaster from 'rgbaster'

/**
 * 计算图片主题色
 * @param {String} url 图片url（禁止跨域）
 */
export async function calcImgThemeColor(url) {
  const result = await rgbaster(url, {
    ignore: ['rgb(255,255,255)', 'rgb(0,0,0)']
  })
  return result[0]
}

/**
 * 色值转换
 * rgb转16进制颜色
 * @param {String} color rgba进制字符串
 * @returns Hex
 */
export function rgb2Hex(color) {
  const rgb = color.split(',')
  const r = parseInt(rgb[0].split('(')[1])
  const g = parseInt(rgb[1])
  const b = parseInt(rgb[2].split(')')[0])
  const hex = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
  return hex
}

/**
 * 色值转换
 * 16进制转rgba
 * @param {String} hex 16进制字符串
 * @param {Number|String} opacity 透明度0-1
 * @returns Object
 */
export function hexToRgba(hex, opacity) {
  const group = {
    red: parseInt('0x' + hex.slice(1, 3)),
    green: parseInt('0x' + hex.slice(3, 5)),
    blue: parseInt('0x' + hex.slice(5, 7)),
    rgba: ''
  }
  const rgba = `rgba(${group.red}, ${group.green}, ${group.blue}, ${opacity})`
  return {
    ...group,
    rgba
  }
}
