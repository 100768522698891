<template>
  <div class="history-list">
    <div class="title-group" v-show="getList.length">
      <slot name="header-title">
        <span class="title">{{ title }}</span>
      </slot>
      <slot name="header-right">
        <div class="delete-icon" @click="$emit('delete')">
          <AppIcon name="dataDelete" />
        </div>
      </slot>
    </div>
    <ul
      :class="{
        'list-item': type === 'list',
        'tag-item': type === 'tag'
      }"
    >
      <li
        v-active="{ css: 'hover' }"
        class="hover-css--slide"
        v-for="(item, index) in getList"
        :key="index"
        @click="$emit('click', item)"
      >
        <div
          class="content-text"
          :class="{
            'van-ellipsis': type === 'list',
            'word-break': type === 'tag'
          }"
        >
          {{ item.label }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { AppIcon } from '@/ui'
export default {
  name: 'HistoryList',
  components: {
    [AppIcon.name]: AppIcon
  },
  props: {
    type: {
      type: String,
      default: 'tag',
      validate(value) {
        return ['list', 'tag'].includes(value)
      }
    },
    /**
     * 标题
     */
    title: {
      type: String,
      default: '历史搜索'
    },
    /**
     * 最大历史条数
     */
    max: {
      type: Number,
      default: 10
    },
    /**
     * 数据集合
     */
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    getList() {
      return this.list
        .map((v) => {
          const stringItemTransform = typeof v === 'string' ? { label: v } : v
          return Object.assign(
            {
              label: v?.label || v
            },
            stringItemTransform
          )
        })
        .filter((v) => !!v.label)
        .slice(0, this.max)
    }
  }
}
</script>

<style lang="scss" scoped>
$history-list-color: #171826;

$history-list-padding: 16px;
$history-list-size: 14px;
$history-list-line-height: 40px;

.word-break {
  word-break: break-all;
}

.history-list {
  .title-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 16px;
    padding: 16px;
    line-height: 24px;
    color: #171826;
    box-sizing: border-box;
    .delete-icon {
      .iconfont {
        font-size: 20px;
        color: #9b9ca3;
      }
    }
  }
  ul {
    list-style: none;
    font-size: $history-list-size;
    line-height: $history-list-line-height;
    color: $history-list-color;
    li {
      position: relative;
      outline: none;
      border: none;
      float: unset;
      font-size: inherit;
      font-family: inherit;
      color: inherit;
      background: inherit;
      box-sizing: border-box;
      word-break: break-all;
      z-index: 1;
      &::after {
        margin-left: $history-list-padding;
      }
    }
    &.list-item {
      margin: 0;
      padding: 0;
      li {
        padding: 0 $history-list-padding;
      }
    }
    &.tag-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 16px;
      li {
        margin: 0 8px 12px 0;
        padding: 4px 12px;
        line-height: 22px;
        color: #5f5e64;
        border-radius: 4px;
        background-color: #f7f9fa;
      }
    }
  }
}
</style>
