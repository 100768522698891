/**
 * 判断某个元素是否在滚动区域的视口中(和视口重叠)
 * element: 目标元素dom
 * scrollContainer: 滚动区域(如果是body滚动，则此参数不传)
 */
export function isElementInScrollArea(element, scrollContainer) {
  const elementRect = element.getBoundingClientRect()
  if (scrollContainer) {
    const containerRect = scrollContainer.getBoundingClientRect()

    // 判断元素的上边界和下边界是否在滚动容器的上边界和下边界之间
    const isElementAboveContainer = elementRect.bottom < containerRect.top
    const isElementBelowContainer = elementRect.top > containerRect.bottom

    // 如果元素在滚动容器的上下边界之间，则认为它在滚动区域内
    return !(isElementAboveContainer || isElementBelowContainer)
  } else {
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight
    return (
      elementRect.top < viewportHeight &&
      elementRect.bottom > 0 &&
      elementRect.left < viewportWidth &&
      elementRect.right > 0
    )
  }
}