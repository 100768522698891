<template>
  <div
    class="jy-checkbox-group"
    :class="{
      [getConfig.maxClass]: getMaxStatus
    }"
  >
    <CheckBox
      v-for="(item, index) in getOptions"
      :key="index"
      v-bind="item.props"
      :checked-icon="getConfig.icon"
      :disabled="item.disabled"
      :marked="marked"
      :value="getSelectList.includes(String(item[getConfig.findKey]))"
      @change="onChangeItem($event, item)"
    >
      <!-- @slot 默认插槽提供 item, 自定义插槽提供 $index 索引  -->
      <slot v-if="item.slot" :name="item.slot" :item="item" :$index="index" />
      <slot v-else :item="item">
        {{ item[getConfig.findLabel] }}
      </slot>
      <span v-if="item.vip" class="v-vip-icon" />
      <span v-if="item.tag" class="jy-tag">{{ item.tag }}</span>
    </CheckBox>
  </div>
</template>

<script>
import { CheckBox } from '@/ui'

export default {
  name: 'CheckboxGroup',
  components: {
    [CheckBox.name]: CheckBox
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * 所有选中项的标识符,对应 options item key
     */
    value: {
      type: [String, Array],
      default: () => []
    },
    marked: {
      type: Boolean,
      default: false
    },
    /**
     * 配置项
     *
     * findKey: key 从 options item 中用于选中的属性值
     *
     * findLabel: label 从 options item 中用于展示的属性值
     *
     * icon: true 是否展示选中图标
     *
     * multiple: true 是否多选
     *
     * all: String 全选对应的 key options=> key
     *
     * max: Number 多选时最大选中数量
     *
     * maxClass 'is-max' 达到最大选中数量时 class
     *
     * splitCharacter ',' 绑定value为String类型时的自定义分割字符 class
     */
    config: {
      type: Object,
      default: () => {}
    },
    /**
     * 选项数组
     *
     * [{ key, label, props }}] || [key]
     *
     * key: String 选中后 value 中对应新增的选项标识
     *
     * label: String 用于展示的选项描述
     *
     * tag: String 用于tag的文字
     *
     * disabled: false 是否禁止选中
     *
     * props Object 对应 CheckBox 的props
     *
     * props.beforeChangeAction CheckBox 前置钩子
     *
     * props.style CheckBox style
     */
    options: {
      type: Array,
      default: () => []
    },
    /**
     * change 前置钩子函数，用于自定义校验
     */
    beforeChangeAction: {
      type: Function,
      default: (v) => v
    }
  },
  computed: {
    getConfig() {
      return Object.assign(
        {
          findKey: 'key',
          findLabel: 'label',
          icon: true,
          multiple: true,
          all: undefined,
          max: undefined,
          maxClass: 'is-max',
          splitCharacter: ','
        },
        this.config
      )
    },
    getOptions() {
      return this.options.map((v) => {
        if (typeof v !== 'object') {
          return {
            [this.getConfig.findKey]: v.toString(),
            [this.getConfig.findLabel]: v.toString()
          }
        }
        v[this.getConfig.findKey] = String(v[this.getConfig.findKey])
        v[this.getConfig.findLabel] = String(v[this.getConfig.findLabel])
        return v
      })
    },
    getAvailableLength() {
      return this.getOptions.filter(
        (v) =>
          !(v?.disabled || v[this.getConfig.findKey] === this.getConfig.all)
      ).length
    },
    getSelectList() {
      let result = this.value
      if (typeof result === 'string') {
        result = result.split(this.getConfig.splitCharacter)
        if (result[0] === '' && this.getConfig.all !== result[0]) {
          result.shift()
        }
      }
      return result
    },
    getMaxLength() {
      return typeof this.getConfig.max === 'number'
        ? this.getConfig.max
        : this.getOptions.length
    },
    getMaxStatus() {
      return (
        this.getConfig.multiple &&
        this.getMaxLength <= this.getSelectList.length
      )
    }
  },
  methods: {
    onChangeItemOfIndex(status, index) {
      return this.onChangeItem(status, this.getOptions[index])
    },
    onChangeItem(status, item) {
      /**
       *  item 点击事件 (item)
       *
       * @event item-select
       */
      this.$emit('item-select', item)

      // 当前是否禁用状态
      if (item.disabled) {
        return
      }

      let arrayTransform = this.getSelectList.filter((v) => {
        return !(v === this.getConfig.all || v === item[this.getConfig.findKey])
      })

      // 单选
      if (!this.getConfig.multiple) {
        if (status) {
          arrayTransform = []
          arrayTransform.push(item[this.getConfig.findKey])
        } else {
          arrayTransform = [].concat(this.getSelectList)
        }
      } else {
        // 当前可以继续选择
        const canAdd =
          typeof this.getConfig.max === 'number'
            ? arrayTransform.length < this.getConfig.max
            : true
        // 当前选择是否为全选
        const isCheckAll = item[this.getConfig.findKey] === this.getConfig.all

        // 多选
        if (isCheckAll) {
          arrayTransform = []
        } else {
          if (status) {
            if (canAdd) {
              arrayTransform.push(item[this.getConfig.findKey])
            } else {
              /**
               * 达到最大选中项数量后，点击事件 (item)
               *
               * @event max-select
               */
              this.$emit('max-select', item)
            }
          }
        }

        // 恢复全选状态
        if (typeof this.getConfig.all !== 'undefined') {
          if (
            this.getAvailableLength === arrayTransform.length ||
            arrayTransform.length === 0
          ) {
            arrayTransform = [this.getConfig.all]
          }
        }
      }

      if (typeof this.value === 'string') {
        arrayTransform = arrayTransform.join(this.getConfig.splitCharacter)
      }
      this.$emit('change', this.beforeChangeAction(arrayTransform))
    }
  }
}
</script>

<style lang="scss" scoped>
.jy-checkbox-group {
  &.is-max {
    .jy-checkbox:not(.checked) {
      opacity: 0.65;
    }
  }
  .jy-tag {
    margin-left: 4px;
  }
  .jy-checkbox {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
  .v-vip-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-left: 2px;
    background: url(@/assets/image/icon/vip/v_icon.png) no-repeat center;
    background-size: contain;
    margin-bottom: 1px;
  }
}
</style>
