import request from '@/api'
import qs from 'qs'

// 查看有无赠品活动
export function getGiftList(data) {
  data = qs.stringify(data)
  return request({
    url: '/jyCoupon/giveInfo',
    method: 'post',
    noToast: true,
    data
  })
}

// 查询当前价格产品可以使用的优惠券、自动领券
export function showCoupons(data) {
  data = qs.stringify(data)
  return request({
    url: '/jyCoupon/showCoupons',
    method: 'post',
    noToast: true,
    data
  })
}

// 获取用户所有奖券信息
export function getCouponsInfo(data) {
  data = qs.stringify(data)
  return request({
    url: '/jyCoupon/getInfoByUser',
    method: 'post',
    noToast: true,
    data
  })
}
