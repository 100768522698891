import { createDecryptIframe } from './decrypt-iframe'

let cacheIframe = null
export default function registerDecryptInterceptor (service) {
  // 注册响应拦截器
  service.interceptors.response.use(async (response) => {
    const res = response.data
    const headers = response.headers

    // 如果请求不是200，有可能是403(被拉黑了)，就直接跳过本次拦截
    if (response.status !== 200) {
      return response
    }

    // 需要验证
    const needDecrypt1 = res.antiEncrypt && res.data
    const needDecrypt2 = headers.antiEncrypt && res.data
    if (needDecrypt1 || needDecrypt2) {
      // 等待解密iframe加载完成
      const iframe = createDecryptIframe()
      cacheIframe = iframe
      const base64Key = res.secretKey
      const cipherText = res.data
      const result = await iframe.doDecrypt(base64Key, cipherText)
      if (result && result.plainText) {
        const p = JSON.parse(result.plainText)
        console.log('解密后的内容: -------->', p)
        response.data = p
      }
      return response
    } else {
      return response
    }
  }, error => {
    return error
  })
}