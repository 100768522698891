import { appStorage } from '@/utils/storage'
import { vSwitch2restfulApiUserTypeMap } from '@/data'
import {
  ajaxUserEntPower,
  ajaxUserPower,
  getAccountInfo,
  getUserID,
  userVipSwitchState,
  getMySelectEntInfo,
  getUserIdentity,
  changeUserIdentity,
  getMineCompany,
  getIsWhiteList
} from '@/api/modules'

export default {
  namespaced: true,
  state: () => ({
    userSimpleInfo: {},
    vSwitch: '', // f免费用户，s商机管理用户，v超级订阅，m大会员
    // 用户权限信息
    power: {
      // isUpgrade: true, // boolean 是否是免费订阅升级用户 默认true
      // vipPowerType: 0, // int 超级订阅权益类型 0无 1个人 2企业
      // memberPowerType: 0, // int 大会员权益类型 0无 1个人 2企业
      // isSubCount: false // true是子账号
    },
    entPower: appStorage.get(
      'entPower',
      {},
      {
        login: true
      }
    ),
    userInfo: appStorage.get(
      'useInfo',
      {},
      {
        login: true
      }
    ),
    // 用户当前(所在/选中)企业信息
    mySelectEntInfo: {
      // ent_id: '', // string 企业id
      // chat_ent_id: '', // string 客服工作台用到的企业id
      // ent_name string: '', // 企业名
      // startdate: 0, // int 有效期开始时间
      // enddate: 0, // int 有效期截至时间
      // ent_model: 0, // int 1公司统一订阅 2个人订阅
      // ent_status: 0, // int 0未购买 1已购买
      // dept_id: 0, // 部门id
      // dept_eid: '', // 加密后的部门id
      // dept_pid: '', // 顶级部门id
      // dept_name: '', // 部门名称
      // dept_subdis: '', // 订阅分发
      // admin_department: false, // boolean 是否是部门管理员
      // admin_system: false, // boolean 是否是系统管理员
      // quota: 0, // int 限额人数
      // power_checked: 0, // int 权限分配已选择人数
      // user_power: 0, // int 是否分配权限, 0未分配 1已分配
      // user_name: '', // 当前人姓名
      // ent_mail: '', // 邮箱
      // ent_industryclass: '', // 行业分类
      // ent_auth_status: 0, // 企业认证状态, -1未通过 0未认证 1已认证
      // ent_auth_reason: '', // 认证不通过原因
      // ent_code: '', // 统一社会信用代码
      // ent_auth_time: '', // 认证时间
      // ent_legal_name: '', // 法人名称
      // user_id: '', // 加密后组织结构用户id
      // product_type: '', // 分配的产品类型
      // vip_power: 0, // int 是否分配了超级订阅, 0否 1是
      // member_power: 0, // int 是否分配了大会员, 0否 1是
      // isnew_entniche: 0, // int 是否是新版商机管理, 0否 1是
      // ent_subscribe: 0, // int 是否有企业订阅权限, 0否 1是
      // ent_subscribe_manager: 0, // int 是否有企业订阅管理权限, 0否 1是
      // power_source: 0, // int 商机管理权限开通来源, 1赠送的商机管理服务
      // dept_subscribe: 0, // int 各部门是否可以自行订阅, 0否 1是
    },
    // 用户身份列表
    userIdentityList: [],
    // 用户当前身份
    userCurrentIdentity: null,
    // 用户所属公司信息
    userCompanyInfo: null,
    // 白名单用户
    whiteListStatus: false
  }),
  mutations: {
    changeVipSwitch(state, data) {
      state.vSwitch = data
    },
    changePower(state, data) {
      state.power = data
    },
    changeEntPower(state, data) {
      state.entPower = data
      appStorage.set('entPower', data, {
        login: true
      })
    },
    changeUserInfo(state, data) {
      state.userInfo = data
      appStorage.set('userInfo', data, {
        login: true
      })
    },
    changeMySelectEntInfo(state, data) {
      state.mySelectEntInfo = data
    },
    changeUserSimpleInfo(state, data) {
      state.userSimpleInfo = data
    },
    // 用户身份信息
    userIdentityInfo(state, data) {
      state.userIdentityList = data
      state.userCurrentIdentity = data.filter((item) => item.checked)
        ? data.filter((item) => item.checked)[0]
        : null
    },
    // 用户所属公司信息
    updateCompanyInfo(state, data) {
      state.userCompanyInfo = data
    },
    updateIsWhiteList(state, data) {
      state.whiteListStatus = data
    }
  },
  actions: {
    async getUserSimpleInfo({ commit }, payload = {}) {
      try {
        const data = (await getUserID(payload)) || {}
        commit('changeUserSimpleInfo', data)
        return data
      } catch (err) {
        const data = {}
        commit('changeUserSimpleInfo', data)
        return data
      }
    },
    async getUserInfo({ commit }) {
      try {
        const { error_code: code, data = {} } = await getAccountInfo()
        if (code === 0) {
          commit('changeUserInfo', data)
        }
        return data
      } catch (error) {
        const data = {}
        commit('changeUserInfo', data)
        return data
      }
    },
    async userVipSwitchState({ commit }, payload) {
      try {
        const { error_code: code, data = {} } =
          await userVipSwitchState(payload)
        if (code === 0) {
          commit('changeVipSwitch', data.vt)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async checkPower({ dispatch, state = {} }, payload = {}) {
      if (Object.keys(state.power)?.length) {
        return state.power || {}
      } else {
        return await dispatch('getPower', { noToast: true })
      }
    },
    async getPower({ commit }, payload = {}) {
      try {
        const { error_code: code, data = {} } = await ajaxUserPower(payload)
        if (code === 0) {
          commit('changePower', data || {})
        }
      } catch (error) {}
    },
    async getSelectedEntInfoCached({ dispatch, state = {} }) {
      if (Object.keys(state.mySelectEntInfo)?.length) {
        return state.mySelectEntInfo || {}
      } else {
        return await dispatch('getMySelectEntInfo')
      }
    },
    async getMySelectEntInfo({ commit }) {
      try {
        const { error_code: code, data = {} } = await getMySelectEntInfo()
        if (code === 0) {
          commit('changeMySelectEntInfo', data || {})
        }
      } catch (error) {}
    },
    async getEntPower({ commit }) {
      try {
        const { error_code: code, data = {} } = await ajaxUserEntPower()
        if (code === 0) {
          commit('changeEntPower', data || {})
        }
      } catch (error) {}
    },
    // 获取用户身份列表数据
    async getUserIdentityList({ commit }) {
      try {
        const { error_code: code, data = [] } = await getUserIdentity()
        if (code === 0) {
          commit('userIdentityInfo', data)
        }
      } catch (error) {}
    },
    // 切换用户身份
    async changeUserIdentity({ dispatch, commit }, token) {
      try {
        const { error_code: code, data } = await changeUserIdentity({ token })
        if (code === 0) {
          // dispatch('getUserIdentityList')
          // // 切换用户身份后 要重刷isAdd、entnicheNew/whetherbuy用户身份权限
          // dispatch('getPower')
          // dispatch('getEntPower')

          // 优化接口请求慢的情况，三个请求同时发送，全部回来之后才往下进行
          await Promise.all([
            dispatch('getUserIdentityList'),
            dispatch('getPower'),
            dispatch('getEntPower')
          ])
          return { code, data }
        }
      } catch (error) {
        return {}
      }
    },
    // 获取用户公司信息
    async getUserCompanyInfo({ commit }) {
      try {
        const { error_code: code, data = {} } = await getMineCompany()
        if (code === 0) {
          commit('updateCompanyInfo', data)
          return data
        }
      } catch (error) {}
    },
    async getWhiteListInfo({ commit }) {
      try {
        const { error_code: code, data = {} } = await getIsWhiteList()
        if (code === 0) {
          commit('updateIsWhiteList', data?.onTheWhitelist)
          return data
        }
      } catch (error) {}
    }
  },
  getters: {
    // 用户当前身份
    vSwitch({ vSwitch }) {
      // f免费用户(默认)
      // v超级订阅
      // m大会员
      // s商机管理
      return vSwitch || 'f'
    },
    bigMemberPower(state) {
      const { power } = state
      if (power && power.power) {
        return power.power
      } else {
        return []
      }
    },
    // 是否是大会员
    isMember(state) {
      return state.power?.memberStatus > 0
    },
    // 是否是企业版大会员
    isEntMember(state) {
      // memberPowerType: 0, // int 大会员权益类型 0无 1个人 2企业
      return state.power?.memberPowerType === 2
    },
    // 是否是老超级订阅
    isOldSuper(_, getters) {
      return getters.isSuper && !getters.isNewSuper
    },
    // 是否新超级订阅
    isNewSuper(state) {
      return state.power?.viper || false
    },
    isSuper(state) {
      return state.power?.vipStatus > 0 || false
    },
    // 是否是企业版超级订阅
    isEntSuper(state) {
      // vipPowerType: 0, // int 超级订阅权益类型 0无 1个人 2企业
      return state.power?.vipPowerType === 2
    },
    // 是否是子账号
    isSubCount(state) {
      return state.power?.isSubCount || false
    },
    // 用户所加入的企业中任意一个有商机管理即为true
    isBusiness(state, getters) {
      return getters.isNewBusiness || state.power?.entniche || false
    },
    // 用户当前企业是否购买了商机管理。
    selectedEntIsBusiness(state) {
      const { mySelectEntInfo } = state
      return (
        mySelectEntInfo.ent_status === 1 &&
        mySelectEntInfo.power_source === 0 &&
        mySelectEntInfo.user_power === 1
      )
    },
    isFree(state, getters) {
      const isFree =
        !getters.isMember &&
        !getters.isSuper &&
        !getters.isBusiness &&
        state.power?.isFree
      if (isFree === undefined) {
        return true
      } else {
        return isFree
      }
    },
    isHasFreeKey(state) {
      return state.power?.freeHasKey || false
    },
    // 是否新商机管理用户
    isNewBusiness(state) {
      return state.power?.entIsNew || false
    },
    // 是否有商机管理服务的权限
    isEntService(state) {
      return state.power?.isEntService || false
    },
    isLogin(_, getters) {
      return typeof getters.getUserId === 'string' && getters.getUserId.length
    },
    noLoginOrFree(_, getters) {
      if (getters.isLogin) {
        return getters.isFree
      } else {
        return true
      }
    },
    // 可以用来判断超级订阅是否有超前项目权限
    vipBefore202209(state, getters) {
      const vipBefore202209 = getters.isSuper && state.power?.vipBefore202209
      return vipBefore202209 || false
    },
    // 用户是否有超前项目权限
    // 2022009之后开通的超级订阅无超前项目权限
    hasAdvancedProjectPower(_, getters) {
      return getters.vipBefore202209 || getters.isMember || getters.isBusiness
    },
    // 用户拥有的vSwitch权限
    vSwitchList(state, getters) {
      const { memberPowerType, vipPowerType } = state.power
      const { member_power: memberPower, vip_power: vipPower } =
        state.mySelectEntInfo
      const list = []
      if (getters.isFree) {
        list.push('f')
      } else {
        // 企业版超级订阅，并且分配了权限
        const vipFromEnt = vipPowerType === 2 && vipPower === 1
        // vipPowerType === 0 非超级订阅
        // vipPowerType === 1 表示个人版超级订阅
        // vipPowerType === 2 表示企业版超级订阅
        if (getters.isSuper || vipPowerType === 1 || vipFromEnt) {
          list.push('v')
        }
        // 企业版大会员，并且分配了权限
        // vipPowerType === 0 非大会员
        // vipPowerType === 1 表示个人大会员
        // vipPowerType === 2 表示企业大会员
        const memberFromEnt = memberPowerType === 2 && memberPower === 1
        if (getters.isMember || memberPowerType === 1 || memberFromEnt) {
          list.push('m')
        }
        if (getters.selectedEntIsBusiness) {
          list.push('s')
        }
      }
      return list
    },
    // 根据vSwitch计算
    restfulApiUserTypeWitchVSwitch(_, getters) {
      if (getters.noLoginOrFree) {
        return vSwitch2restfulApiUserTypeMap.f
      } else {
        if (getters.vSwitch) {
          return vSwitch2restfulApiUserTypeMap[getters.vSwitch]
        } else {
          return 'pType'
        }
      }
    },
    restfulApiUserTypeDefault(_, getters) {
      if (getters.noLoginOrFree) {
        return 'fType'
      } else {
        if (getters.isMember) {
          return 'mType'
        } else if (getters.isBusiness || getters.isNewBusiness) {
          return 'eType'
        } else if (getters.isSuper) {
          return 'vType'
        } else {
          return 'pType'
        }
      }
    },
    getUserId(state) {
      return state.userSimpleInfo?.userId || false
    },
    userCurrentIdentity(state) {
      return state.userCurrentIdentity
    },
    userIdentityList(state) {
      return state.userIdentityList || []
    },
    // 切换到个人还是企业
    userIdentityType(state) {
      // positionType 0：个人； 1：企业
      return state.userCurrentIdentity?.positionType
    },
    // 用户公司信息
    userCompanyInfo(state) {
      return state.userCompanyInfo || {}
    },
    // 是否是白名单用户
    isWhiteList(state) {
      return state.whiteListStatus || false
    }
  }
}
