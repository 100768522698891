export default [
  {
    path: '/page',
    name: 'content',
    component: () => import('@/views/equitycenter/index.vue'),
    meta: {
      header: true,
      title: '兑换中心'
    }
  }
]
