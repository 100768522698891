<template>
  <div
    class="jy-checkbox"
    :class="{
      checked: value,
      'after-icon': checkedIcon,
      'b-mark': marked
    }"
    @click="onChange"
  >
    <slot />
    <AppIcon v-if="value && checkedIcon" class="after-icon" name="jiaobiao" />
  </div>
</template>

<script>
import { AppIcon } from '@/ui'

export default {
  name: 'CheckBox',
  components: {
    [AppIcon.name]: AppIcon
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * 是否为选中状态
     */
    value: {
      type: Boolean,
      default: false
    },
    /**
     * 是否展示选中状态右下角图标
     */
    checkedIcon: {
      type: Boolean,
      default: true
    },
    marked: {
      type: Boolean,
      default: false
    },
    /**
     * change 前置钩子函数，用于自定义校验
     */
    beforeChangeAction: {
      type: Function,
      default: (v) => v
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.beforeChangeAction(!this.value))
    }
  }
}
</script>

<style lang="scss" scoped>
.jy-checkbox {
  position: relative;
  display: inline-block;
  background: #f5f6f7;
  border-radius: 4px 4px 4px 4px;
  font-size: 13px;
  font-weight: 500;
  color: #5f5e64;
  line-height: 20px;
  padding: 6px 12px 6px 10px;
  .after-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 14px;
    color: #2abed1;
  }
  &.checked {
    background: #e8fafd;
    color: $color_main;
    &.b-mark {
      // background: $color_main_background;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        background: url(@/assets/image/icon/icon-button-mark.png);
        width: 14px;
        height: 14px;
        background-size: 100% 100%;
      }
    }
  }

  &.after-icon {
    padding-right: 16px;
    padding-left: 16px;
  }
}
</style>
