import { replaceKeyword } from '@jy/util'

class ThirdPartyVerifyModel {
  constructor() {
    this.getKeywordsList()
  }

  // 将要匹配的列表
  keywordsList = []

  certificate = {
    // 证书名称
    name: [
      '质量管理体系认证',
      '工程建设施工质量管理体系认证',
      '环境管理体系认证',
      '职业健康安全管理体系认证',
      '危害分析和关键控制点',
      '食品安全管理体系认证',
      '信息技术服务管理体系认证',
      '信息安全管理体系认证',
      '五星售后服务认证 ',
      '五星品牌认证',
      '批发零售服务',
      '物业服务认证',
    ],
    // 证书编号
    number: [
      /(ISO\s{0,1}9001)/g,
      /(GB\/T\s{0,1}50430)/g,
      /(ISO\s{0,1}14001)/g,
      /(ISO\s{0,1}45001)/g,
      'HACCP',
      /(ISO\s{0,1}2[027]000)/g, // ISO20000、ISO22000、ISO27000、
    ],
    // AAA关键词
    key: [
      '信用等级',
      '重合同守信用企业',
      '质量服务诚信单位',
      '重质量守信用单位',
      '重服务守信用',
      '资信等级',
      '诚信经营示范单位',
      '诚信企业家',
      '诚信经理人',
      '诚信供应商',
    ]
  }

  // 将certificate中的内容整合成一个数组
  getKeywordsList() {
    let arr = []
    const map = this.certificate
    for (const key in map) {
      const item = map[key]
      if (Array.isArray(item)) {
        arr = arr.concat(item)
      }
    }
    this.keywordsList = arr
    return arr
  }

  replaceKeysAndInsertMark(content) {
    // 字符串替换
    content = this.replaceKeys(content)
    try {
      // 将字符串转成dom
      this.contentDOM = this.createDomInMemory(content)
      // 插入button
      this.checkHighlightInsert(this.contentDOM)
    } catch (error) {
      console.error(error)
    }
    // 插入完成后在将dom转成字符串
    return this.contentDOM.innerHTML
  }

  // dom数组去重
  unique(array) {
    return array.filter(function (value, index, self) {
      return self.indexOf(value) === index
    })
  }

  // certificate相关 关键词替换
  replaceKeys(content) {
    // 1.先将关键词替换
    var replaced = replaceKeyword(content, this.keywordsList, "<span class='highlight-text third-party-verify-highlight'>$1</span>");
    // 2.替换关键词后面最近的一个中文句号（用来插入button）
    replaced = replaced.replace(/third-party-verify-highlight(.*?)(。|;|；)/g, function (match, p1, p2) {
      return match.replace(p2, `<span class='paragraph-last'>${p2}</span>`)
    })
    return replaced
  }

  getInsertButton() {
    // third-party-popover标记点击弹窗
    const htmlString = '<span class="third-party-popover third-party-verify-button"><span class="icon icon-third-party-verify-logo third-party-popover"></span><span class="third-party-popover third-party-button-text">剑鱼认证服务</span></span>'
    const div = document.createElement('div')
    div.innerHTML = htmlString
    const dom = div.firstChild
    return dom
  }

  createDomInMemory(html) {
    var div = document.createElement('div')
    div.classList.add('com-detail')
    div.innerHTML = html
    return div
  }

  // 获取当前元素下一个指定元素，同jquery的.next()
  getNextElement(element, tagName) {
    let nextSibling = element.nextElementSibling;

    if (!tagName) {
      return nextSibling
    }
    
    while (nextSibling) {
      if (nextSibling.tagName === tagName.toUpperCase()) {
        return nextSibling;
      }
      
      nextSibling = nextSibling.nextElementSibling;
    }
    
    return null;
  }


  // 检查并插入button
  checkHighlightInsert(dom) {
    var container = dom;
    var verifyIconButton = container.querySelectorAll('.third-party-verify-button');
    if (verifyIconButton.length) return;
    var waitingInsertList = [];

    // 1. 先找highlights同级next的.paragraph-last
    waitingInsertList = Array.from(container.querySelectorAll('.paragraph-last'));
    waitingInsertList = this.unique(waitingInsertList);
    if (waitingInsertList.length) {
      // 找到了.paragraph-last在其后插入button
      waitingInsertList.forEach(function (dom) {
        dom.insertAdjacentElement('afterend', this.getInsertButton());
      }.bind(this));
    } else {
      // 2. 找不到.paragraph-last。找同级next的br
      waitingInsertList = this.findNextMark(dom, function (element) {
        const nextBr = this.getNextElement(element, 'br')
        return nextBr
      }.bind(this));
      if (waitingInsertList.length) {
        // 找到了br，则在其前面插入
        waitingInsertList.forEach(function (dom) {
          dom.insertAdjacentElement('beforebegin', this.getInsertButton());
        }.bind(this));
      } else {
        // 3. 找不到br，找到其父级标签，并在其末尾插入
        waitingInsertList = this.findNextMark(dom, function (element) {
          return element.parentNode;
        });
        if (waitingInsertList.length) {
          // 找到了br，则在其前面插入
          waitingInsertList.forEach(function (dom) {
            dom.appendChild(this.getInsertButton());
          }.bind(this));
        }
      }
    }
    return dom
  }

  findNextMark (dom, callback) {
    var waitingInsertList = [] // 待插入节点dom对象
    var container = dom
    var highlights = container.querySelectorAll('.third-party-verify-highlight')
    highlights.forEach(function (item) {
      var mark = callback(item)
      if (mark) {
        waitingInsertList.push(mark)
      }
    })
    // 去重
    return this.unique(waitingInsertList)
  }
}

function useThirdPartyVerifyModel() {
  return new ThirdPartyVerifyModel()
}

export default useThirdPartyVerifyModel
