import { ajaxGetDocMemberUserInfo } from '@/api/modules'

export default {
  namespaced: true,
  state: () => ({
    docMemberInfo: {}
  }),
  mutations: {
    setDocMemberInfo(state, data) {
      state.docMemberInfo = data
    }
  },
  actions: {
    /**
     * 获取用户剑鱼文库会员信息
     * @param commit
     * @returns {Promise<void>}
     */
    async getDocMemberInfo({ commit }) {
      try {
        const { error_code: code, data = {} } = await ajaxGetDocMemberUserInfo()
        if (code === 0 && data) {
          commit('setDocMemberInfo', data)
        }
        return data
      } catch (error) {
        console.warn(error)
        return error
      }
    }
  },
  getters: {
    /**
     * 是否是剑鱼文库会员
     * @param state
     * @returns {boolean}
     */
    isDocMember: (state) => {
      const { docMemberStatus } = state.docMemberInfo
      return docMemberStatus
    },
    /**
     * 是否留过资、留过资是否下载过
     * @param state
     * @returns {Number}
     */
    freeDownloadStatus: (state) => {
      // 0 未留资 1-已使用 2-未使用
      const { free_download } = state.docMemberInfo
      return free_download
    }
  }
}
