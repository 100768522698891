// 静态路由
export default [
  {
    path: '/keep',
    name: 'keep',
    component: () => import('@/views/identity/indentityKeep.vue'),
    meta: {
      header: true,
      title: '身份信息维护'
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/identity/company.vue'),
    meta: {
      header: true,
      title: '公司信息'
    }
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('@/views/identity/business.vue'),
    meta: {
      header: true,
      title: '公司业务类型'
    }
  }
]
