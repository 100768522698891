import qs from 'qs'
import request from '@/api'
import { envs } from '@/utils/prototype/modules/platform'

export function userVipSwitchState(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/subscribe/vipSwitch',
    method: 'post',
    noToast: true,
    data
  })
}

// 获取免费订阅信息
export function getFreeSubscribeInfo() {
  return request({
    url: '/publicapply/free/subscribe',
    method: 'post'
  })
}

// 获取数据导出信息
export function getDataExportInfo(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/dataexport/payPageParams',
    method: 'post',
    data
  })
}

// 广告获取
export function ajaxGetAD(data) {
  return request({
    url: '/publicapply/free/getJyAdList',
    method: 'post',
    data
  })
}

// 新用户广告获取
export function ajaxGetNewUserAD(data) {
  return request({
    url: '/publicapply/free/newUserGroup/getDialog',
    method: 'get',
    params: data
  })
}

// 标讯收藏数据导出
export function ajaxCollectionExport(data) {
  return request({
    url: '/publicapply/dataexpoet/bycollection',
    method: 'post',
    params: data
  })
}

// 新用户广告已读上报接口 只显示一次
export function ajaxSetNewUserADRead(data) {
  return request({
    url: '/publicapply/free/newUserGroup/readDialog',
    method: 'get',
    params: data
  })
}

/**
 * 获取最新标讯，用于首页展示
 */
export function ajaxGetIndexList(data) {
  return request({
    url: '/jyapi/jybx/base/newest',
    method: 'post',
    data
  })
}

/**
 * 获取参标状态
 */
export function ajaxCanBiaoStatus(data) {
  return request({
    url: '/jyapi/jybx/core/participate/show',
    method: 'post',
    noToast: true,
    data
  })
}

/**
 * 参标--终止参标
 * action：
 *  in:参标-当前用户参标
 *  out:终止参标-1、员工终止当前自己对此项目参标的状态；2：部门管理员终止当前部门下对此项目参标的状态；3：企业管理员终止当前企业下对此项目的参标状态
 *  transfer:划转-1、部门管理员当前部门下参标此项目的对象；2、企业管理员企业下~~~~
 *  参标|终止参标：bidIds和projectIds 必传一个
 */
export function ajaxCanBiaoAction(action, data) {
  return request({
    url: `/jyapi/jybx/core/participate/${action}/info`,
    method: 'post',
    data
  })
}

// 更新投标状态
export function updateBidStatus(data) {
  return request({
    url: '/jyapi/jybx/core/participate/updateBidStatus',
    method: 'post',
    data
  })
}

// 获取投标项目阶段选项
export function setUpInfo(data) {
  return request({
    url: '/jyapi/jybx/core/participate/setUpInfo',
    method: 'post',
    data
  })
}

// 获取参标信息内容
export function getCanBiaoContent(data) {
  return request({
    url: '/jyapi/jybx/core/participate/content',
    method: 'post',
    data
  })
}

/**
 * 企业搜索，用于企业搜索单元格列表
 */
export function getEntCellList(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/enterpriseSearch/doQuery',
    method: 'post',
    data
  })
}

/**
 * 添加订阅关键词
 */
export function setUserSubInfo(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/subscribe/setUserInfo',
    method: 'post',
    data
  })
}

/**
 * 是否留资
 */
export function getLeaveInfoFlag(data) {
  data = qs.stringify(data)
  return request({
    url: '/salesLeads/retainedCapital',
    method: 'post',
    data
  })
}

/**
 * 是否留资
 */
export function setLeaveInfo(data) {
  return request({
    url: '/salesLeads/collectInfo',
    method: 'post',
    data
  })
}

/**
 * 获取用户信息、获取用户是否是(老用户专享权限-搜索范围中的中标企业搜索权限)
 */
export function getBidColPower() {
  return request({
    url: '/publicapply/bidcoll/power',
    method: 'post',
    noToast: true
  })
}

// 获取用户留资消息。
export function ajaxGetIsNewUerSales(data) {
  return request({
    url: '/salesLeads/appIsNewUerSales',
    method: 'post',
    data
  })
}

// 跳过 提交新用户留资。
export function ajaxGetappNewUerSales(data) {
  return request({
    url: '/salesLeads/appNewUerSales',
    method: 'post',
    data
  })
}

/**
 * 订阅页面省份订阅包提示
 */
export function getSubscribePageAreaPackTip() {
  return request({
    url: '/publicapply/free/areapack/tip',
    method: 'post'
  })
}

/**
 * 订阅页面省份订阅包提示关闭
 */
export function setSubscribePageAreaPackTipClose() {
  return request({
    url: '/publicapply/free/areapack/update',
    method: 'post'
  })
}

/**
 * 是否关注公众号接口
 */
export function getSubscribeStatus() {
  return request({
    url: '/publicapply/detail/checkSubscribe',
    noToast: true,
    method: 'get'
  })
}

/**
 * 百度广告曝光接口
 */
export function adLeagueExposure(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/adLeague/exposure',
    method: 'post',
    data
  })
}

/**
 * 百度广告点击接口
 */
export function adLeagueClick(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/adLeague/click',
    method: 'post',
    data
  })
}

// 获取公司联想
export function getCompanyAssociation(data) {
  data = qs.stringify(data)
  return request({
    url: '/jypay/user/company/association',
    method: 'post',
    data
  })
}

// 获取活动配置信息
export function getActivityConfig(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/activity/day/info',
    method: 'post',
    data
  })
}

// 标讯收藏
// data = {
//   bids: 'id1,id2,id3', // 标讯id, 多个需要用逗号隔开
//   label: 'info' // 如果收藏了, 查询并返回当前收藏的标签
// }
export function checkArticleIsCollect(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/bidcoll/isColl',
    method: 'post',
    data
  })
}

// nps
export function getNpsData() {
  return request({
    url: '/publicapply/nps/getNpsData',
    method: 'get'
  })
}
export function getSeeNps(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/nps/seeNps',
    method: 'post',
    data
  })
}
export function collectionNps(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/nps/collectNps',
    method: 'post',
    data
  })
}

export function getCollectList(data) {
  // data = qs.stringify(data)
  return request({
    url: '/publicapply/bidcoll/list',
    method: 'post',
    data
  })
}

export function getZhiMaFilterData() {
  return request({
    url: '/publicapply/enterpriseSearch/zhiMaFilter',
    noToast: true,
    method: 'get'
  })
}

// 是否是白名单用户
export function getIsWhiteList() {
  return request({
    url: '/publicapply/userbase/whitelist',
    method: 'get'
  })
}

// 一键登录/注册/绑定
export function getPhoneByToken(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/oneClick/getPhoneByToken',
    method: 'POST',
    data
  })
}

// 一键登录 获取 H5 认证授权 Token
export function getAuthToken(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/oneClick/getAuthToken',
    method: 'POST',
    data
  })
}

// 获取公众号置顶教程入口显示时间
export function getConfiguration(data) {
  return request({
    url: '/publicapply/configuration/get',
    method: 'post',
    noToast: true,
    data
  })
}

// 是否订阅查询
export function getIsSub(data) {
  return request({
    url: '/publicapply/subscribe/isSub',
    method: 'post',
    noToast: true,
    data
  })
}

// 相似订阅推荐
export function getRecommend(data) {
  data = qs.stringify(data)
  return request({
    url: envs.inWX ? '/member/getRecomKWs' : '/jyapp/member/getRecomKWs',
    method: 'post',
    data
  })
}
