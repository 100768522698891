import { appStorage } from '@/utils/storage'
import { isEqual, uniqWith } from 'lodash'
import { ajaxGetSearchHotKeyList } from '@/api/modules/'

const CACHE_HISTORY_KEY = 'AllSearchHistory'
const MAX_HISTORY_LENGTH = 10
export default {
  namespaced: true,
  state: () => ({
    /**
     * 搜索热词
     */
    hotKeyList: [],
    /**
     * 搜索历史
     */
    searchHistory: appStorage.get(
      CACHE_HISTORY_KEY,
      {
        bidding: [],
        buyer: [],
        company: [],
        supplier: [],
        docs: []
      },
      { login: true }
    )
  }),
  mutations: {
    setHotKeyList(state, list) {
      state.hotKeyList = list
    },
    saveHistory(state) {
      appStorage.set(CACHE_HISTORY_KEY, state.searchHistory, {
        login: true
      })
    },
    addHistory(state, { type, item }) {
      let payloadList = [item]
      if (Array.isArray(item)) {
        payloadList = [].concat(item)
      }
      const repeatList = payloadList.concat(state.searchHistory[type] || [])
      state.searchHistory[type] = uniqWith(repeatList, isEqual).slice(
        0,
        MAX_HISTORY_LENGTH
      )
    },
    resetHistory(state, { type }) {
      state.searchHistory[type] = []
    }
  },
  actions: {
    async getHotKeyList({ dispatch, state }, payload) {
      const list = state.hotKeyList.map((r) => r.label)
      if (payload && payload.refresh) {
        const params = {
          refresh: 1,
          word: list.join(',')
        }
        await dispatch('getHotKeyListReq', params)
      } else {
        if (list.length > 0) {
          return list
        } else {
          await dispatch('getHotKeyListReq')
        }
      }
      return list
    },
    async getHotKeyListReq({ commit }, payload) {
      const { data, error_code: code } = await ajaxGetSearchHotKeyList(payload)
      if (code === 0 && Array.isArray(data)) {
        const list = data.map((d) => {
          return {
            label: d.keyword
          }
        })
        commit('setHotKeyList', list)
      }
    },
    setHistory({ commit }, payload) {
      commit('addHistory', payload)
      commit('saveHistory')
    },
    removeHistory({ commit }, payload) {
      commit('resetHistory', payload)
      commit('saveHistory')
    }
  },
  getters: {
    biddingSearchHistory(state) {
      return state.searchHistory.bidding
    },
    buyerSearchHistory(state) {
      return state.searchHistory.buyer
    },
    companySearchHistory(state) {
      return state.searchHistory.company
    },
    supplierSearchHistory(state) {
      return state.searchHistory.supplier
    },
    docsSearchHistory(state) {
      return state.searchHistory.docs
    }
  }
}
