// 静态路由
export default [
  {
    path: '/myCoupon',
    name: 'myCoupon',
    alias: [
      '/jyapp/frontPage/coupon/free/myCoupon',
      '/weixin/frontPage/coupon/free/myCoupon'
    ],
    component: () => import('@/views/coupon/MyCoupon.vue'),
    meta: {
      header: true,
      title: '我的优惠'
    }
  }
]
