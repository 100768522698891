import { getPointsInfo, getPointTask } from '@/api/modules/jyPoints'

export default {
  namespaced: true,
  state: {
    pointsInfo: {}, // 签到信息、剑鱼币汇总过期信息等
    pointsDetailInfo: {}, // 剑鱼币明细列表
    pointsTaskInfo: {}, // 剑鱼币任务
    taskList: [], // 赚剑鱼币任务列表
    // 不同任务类型的剑鱼币信息
    taskTypeObj: {}
  },
  mutations: {
    changePointInfo(state, data) {
      const { points, detailed } = data
      state.pointsDetailList = detailed
      state.pointsInfo = points
    },
    updateTaskAInfo(state, data) {
      const { points, detailed } = data
      state.pointsTaskInfo = data
      let taskTypeObj = {}
      let taskList = []
      if (data.taskType === 2) {
        taskList = data.newbieTask || []
      } else {
        taskList = data.limitedTask || []
      }
      state.taskList = taskList
      // 获取不同类型的剑鱼币信息
      for (let item of taskList) {
        if (!taskTypeObj[item.type]) {
          taskTypeObj[item.type] = item
        }
      }
      state.taskTypeObj = taskTypeObj
    }
  },
  actions: {
    async getPoints(
      { state, commit, dispatch },
      params = {
        B: true,
        L: true,
        page: 0,
        pageSize: 10
      }
    ) {
      try {
        const { data = {}, error_code: code } = await getPointsInfo(params)
        if (code === 0 && data) {
          commit('changePointInfo', data)
          return data
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取剑鱼币任务
    async getPointsTask({ state, commit }) {
      try {
        const res = await getPointTask()
        if (res.error_code === 0 && res.data) {
          commit('updateTaskAInfo', res.data)
        }
        return res
      } catch (error) {}
    }
  },
  getters: {
    pointsInfo(state) {
      return state.pointsInfo || {}
    },
    pointsDetailList(state) {
      return state.pointsDetailInfo || {}
    },
    pointsTaskList(state) {
      return state.taskList || []
    },
    pointsTaskInfo(state) {
      return state.pointsTaskInfo || {}
    },
    taskTypeObj(state) {
      return state.taskTypeObj || {}
    }
  }
}
