import request from '@/api'

export function participateRecords(data) {
  return request({
    url: '/jyapi/jybx/core/participate/records',
    method: 'post',
    data
  })
}

/**
 * 获取企业人员接口，扁平2级，不是递归层级
 * @param {*} data: { ProjectId: '' } 非必须
 * @returns
 */
export function getEntPersonsForParticipate(data) {
  return request({
    url: '/jyapi/jybx/core/participate/persons',
    method: 'post',
    data
  })
}

/**
 * 参标列表（个人和企业）
 * @param action 我的：mine；企业：ent
 * @param data
 * @returns {*}
 */
export function getParticipateList(action = 'mine', data) {
  return request({
    url: `/jyapi/jybx/core/participate/${action}/list`,
    method: 'post',
    data
  })
}

/**
 * 用户参标、终止参标及划转接口
 * @param action
 * in:参标-当前用户参标
 * out:终止参标-1、员工终止当前自己对此项目参标的状态；2：部门管理员终止当前部门下对此项目参标的状态；3：企业管理员终止当前企业下对此项目的参标状态
 * transfer:划转-1、部门管理员当前部门下参标此项目的对象；2、企业管理员企业下~~~~
 * @param data
 * @returns {*}
 */
export function projectParticipate(action = 'in', data) {
  return request({
    url: `/jyapi/jybx/core/participate/${action}/info`,
    method: 'post',
    data
  })
}

// (管理员)基础管理-设置企业基本配置
export function setEntBaseConfig(data) {
  return request({
    url: '/jyapi/jybx/core/participate/setUpInfo',
    method: 'post',
    data
  })
}
// 订阅推送列表参标统计
export function pushStatistics(data) {
  return request({
    url: '/jyapi/jybx/core/statistics/pushStatistics',
    method: 'post',
    data
  })
}
// 参标项目汇总统计
export function projectStatistics(data) {
  return request({
    url: '/jyapi/jybx/core/statistics/projectStatistics',
    method: 'post',
    data
  })
}

// 参标项目明细统计
export function projectDetailStatistics(data) {
  return request({
    url: '/jyapi/jybx/core/statistics/projectDetails',
    method: 'post',
    data
  })
}

/**
 * 获取项目详情页参标数据
 * @param data{sid}
 * 返回参数
 *
 bool showParticipate = 1;// 是否显示参标按钮  true-显示  false-不显示
 bool showStopParticipate = 2;// 是否显示终止参标参标按钮  true-显示  false-不显示
 bool showTransfer = 3;// 转给同事按钮是否展示：true-展示 false-不展示
 string userName = 4;//参标人姓名 逗号分割
 string projectId = 5;// 项目id
 int64 bidEndTime = 6 ;// 投标截止时间
 int64 currentTime = 7 ;// 服务器当前时间
 */
export function getDetailBidIsJoin(data) {
  return request({
    url: '/jyapi/jybx/core/participate/info',
    method: 'post',
    noToast: true,
    data
  })
}
