import { FilterNumberRegExp } from '@/utils/constant'
export * from './modules/filter-history-formatter'
export * from './modules/date'
export * from './modules/ad-formatter'
export * from './modules/money'
export * from './modules/info-type-transform'
export * from './modules/area-map'

/**
 * 用于从文本中提取数字
 * @param text
 * @returns {number}
 */
export function getPureNumber(text) {
  const result = Number(String(text).replace(FilterNumberRegExp, ''))
  if (isNaN(result)) {
    return 0
  }
  return result
}
