import qs from 'qs'
import request from '@/api'

export function screenList() {
  return request({
    url: '/subscribepay/dataExportPack/screenList',
    method: 'get'
  })
}

export function screenDelete(data) {
  return request({
    url: '/subscribepay/dataExportPack/screenDelete',
    method: 'POST',
    data
  })
}
// 获取超级订阅价格表
export function getSVIPGoodsPrice() {
  return request({
    url: '/subscribepay/vipsubscribe/getPrice',
    method: 'POST'
  })
}

// 获取超级订阅价格
export function getSVIPSelectPrice(data) {
  data = qs.stringify(data)
  return request({
    url: '/subscribepay/vipsubscribe/getSelectPrice',
    method: 'POST',
    data
  })
}

// 获取用户超级订阅购买信息
export function getSVIPBuyInfo() {
  return request({
    url: '/subscribepay/vipsubscribe/getSubBuyMsg',
    method: 'POST'
  })
}

export function getDataExportPrice() {
  return request({
    url: '/jypay/dataexport/getPrice',
    method: 'post'
  })
}

// 获取用户手机号邮箱等信息
export function getAccountInfo() {
  return request({
    url: '/jypay/user/getAccountInfo',
    method: 'post',
    timeout: 5000
  })
}

// 获取用户手机号邮箱等信息
export function getUserID() {
  return request({
    url: '/jypay/user/getSimpleData',
    noToast: true,
    method: 'post'
  })
}

// 获取省份订阅包价格信息
export function getAreaPackGoodsPrice() {
  return request({
    url: '/jypay/areaPack/price',
    method: 'post'
  })
}

// 获取数据流量包价格信息
export function getDataPackGoodsPrice() {
  return request({
    url: '/subscribepay/dataExportPack/goodsList',
    method: 'post'
  })
}

// 获取资源包价格信息
export function getSourcePackGoodsPrice(data) {
  data = qs.stringify(data)
  return request({
    url: '/jypay/resourcePack/price',
    method: 'post',
    data
  })
}

// 获取大会员价格信息
export function getBigMemberGoodsPrice(data) {
  data = qs.stringify(data)
  return request({
    url: '/jypay/bigmember/price',
    method: 'post',
    data
  })
}

// 获取数据流量包剩余信息
export function getDataPackUsage() {
  return request({
    url: '/subscribepay/dataExportPack/account',
    method: 'post'
  })
}

// 线上充值明细
export function getDataExportRechargeList(data) {
  return request({
    url: '/subscribepay/dataExportPack/rechargeList',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 数据导出记录
export function getDataExportRecordList(data) {
  return request({
    url: '/subscribepay/dataExportPack/recordList',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 数据导出记录-下载-发送邮箱
export function sendDataExportFileEmail(data) {
  return request({
    url: '/subscribepay/dataExportPack/sendMail',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取数据导出筛选条数
export function getDataExportFilterInfo(data) {
  data = qs.stringify(data)
  return request({
    url: '/subscribepay/dataExportPack/statistics',
    method: 'post',
    data
  })
}

// 创建订单接口
export function createOrderApi(data) {
  return request({
    url: '/jypay/common/createorder',
    method: 'POST',
    data
  })
}

// 数据流量包扣除
// https://showdoc.jydev.jianyu360.com/web/#/49?page_id=743
export function packPayApi(data) {
  data = qs.stringify(data)
  return request({
    url: '/subscribepay/dataExportPack/packPay',
    method: 'POST',
    data
  })
}

/**
 * 附件下载资源包扣除
 */
export function getConsumePack(data) {
  data = qs.stringify(data)
  return request({
    url: '/jypay/resourcePack/consumePack',
    method: 'post',
    data
  })
}

/**
 * 查询周报月报未读条数以及是否需要弹窗提示
 */
export function checkDataReportTip(data) {
  data = qs.stringify(data)
  return request({
    url: '/subscribepay/report/tip',
    method: 'POST',
    data,
    params: {
      t: Date.now()
    }
  })
}

/**
 * 查询是否有周报月报数据
 */
export function checkHasReportData(params) {
  return request({
    url: '/subscribepay/report/starttime',
    method: 'POST',
    params
  })
}

/**
 * 查询是否有周报月报弹窗关闭前请求
 */
export function dataReportTipClose(data) {
  data = qs.stringify(data)
  return request({
    url: '/subscribepay/report/tipover',
    method: 'POST',
    data,
    params: {
      t: Date.now()
    }
  })
}

/**
 * 微信js-sdk注册
 */
export function getWxSdkSign(data) {
  data = qs.stringify(data)
  return request({
    url: '/jypay/wx/getwxSdkSign',
    method: 'post',
    noToast: 1,
    data
  })
}

/**
 * 获取商品详情信息（超级订阅、数据流量包）
 * @param data
 */
export function getCommodityDetail(data) {
  return request({
    url: '/jypay/commodity/detail',
    method: 'post',
    data
  })
}

/**
 * 实时计算当前下单价格
 * @param data
 */
export function getOrderPrice(data) {
  return request({
    url: '/jypay/commodity/price',
    method: 'post',
    data
  })
}

/**
 * 获取当前规格产品下的活动以及优惠信息
 * @param data
 */
export function getCommodityCouponInfo(data) {
  return request({
    url: '/jypay/commodity/couponInfo',
    method: 'post',
    data
  })
}

// 超级订阅购买获取续费周期
// https://yapi.jydev.jianyu360.com/project/63/interface/api/1911
export function getEffectiveTime(data) {
  data = qs.stringify(data)
  return request({
    url: '/subscribepay/vipsubscribe/effectiveTime',
    method: 'post',
    data
  })
}
// 剑鱼文库会员获取续费周期
export function getDocMemberEffectiveTime(data) {
  data = qs.stringify(data)
  return request({
    url: '/subscribepay/docMember/effectiveTime',
    method: 'post',
    data
  })
}

// 获取域名配置
export function getAppsDomain() {
  return request({
    url: '/jypay/getDomain',
    method: 'post'
  })
}

// 获取各种资源包余额信息
export function getResourcePackAccount(data) {
  return request({
    url: '/jypay/resourcePack/account',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 资源包兑换接口
export function useResourcePack(data) {
  return request({
    url: '/jypay/resourcePack/consumePack',
    method: 'post',
    noToast: true,
    data: qs.stringify(data)
  })
}

// 资源包兑换接口
export function sendProjectPdfFile(data) {
  return request({
    url: '/jypay/sendMailNote/projectPdfFile',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function vipRenewReminder(data) {
  return request({
    url: '/subscribepay/vipsubscribe/vipRenewReminder',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取图形验证码
export function getPhoneCaptcha() {
  return request({
    url: `/jypay/user/phone/imgCaptcha?t=${Date.now()}`,
    method: 'GET'
  })
}

export function setPhoneBind(data, type) {
  data = qs.stringify(data)
  return request({
    url: `/jypay/user/phone/${type}`,
    method: 'POST',
    data
  })
}

// 赠送人超级订阅活动时间配置
export function vipGiftActivityConfigAjax() {
  return request({
    url: `/subscribepay/vip/gift/configuration?t=${Date.now()}`,
    method: 'POST'
  })
}

// 赠送人超级订阅可赠资源查询
export function getSubResourcesAjax() {
  return request({
    url: `/subscribepay/vip/gift/getSubDuration?t=${Date.now()}`,
    method: 'POST'
  })
}

// 根据手机号获取可赠信息
export function getInfoByPhoneAjax(data) {
  data = qs.stringify(data)
  return request({
    url: '/subscribepay/vip/gift/getInfoByPhone',
    method: 'POST',
    data
  })
}

// 赠送超级订阅提交表单
export function givingSuperVipAjax(data) {
  data = qs.stringify(data)
  return request({
    url: '/subscribepay/vip/gift/transferSubDuration',
    method: 'POST',
    data
  })
}

// 超级订阅赠送记录
export function vipGiftRecordAjax(data) {
  data = qs.stringify(data)
  return request({
    url: '/subscribepay/vip/gift/list',
    method: 'POST',
    data
  })
}

// 超级订阅赠送记录详情
export function vipGiftDetailAjax(data) {
  data = qs.stringify(data)
  return request({
    url: '/subscribepay/vip/gift/informInfo',
    method: 'POST',
    data
  })
}
