import qs from 'qs'
import { useRequest } from '../../../api'

export function ajaxFollowEntInfo(data) {
  data = qs.stringify(data)
  return useRequest({
    url: '/bigmember/follow/ent/followCheck',
    method: 'post',
    noToast: true,
    data
  })
}

export function ajaxFollowEntAdd(data) {
  data = qs.stringify(data)
  return useRequest({
    url: '/bigmember/follow/ent/addFollow',
    method: 'post',
    data
  })
}

export function ajaxFollowEntCancel(data) {
  data = qs.stringify(data)
  return useRequest({
    url: '/bigmember/follow/ent/delFollow',
    method: 'post',
    data
  })
}

// 企业监控分组（获取、新增、编辑、删除），包含达到监控上限相关
export function ajaxMonitorGroup(data) {
  data = qs.stringify(data)
  return useRequest({
    url: '/bigmember/follow/ent/labelGroup',
    method: 'post',
    data
  })
}

// 企业监控更改分组
export function ajaxChangeEntGroup(data) {
  data = qs.stringify(data)
  return useRequest({
    url: '/bigmember/follow/ent/changeGroup',
    method: 'post',
    data
  })
}

// 企业监控分组（仅获取，场景：企业情报监控筛选项、编辑分组、企业画像、标讯详情页更改分组）
export function ajaxGetGroupList(data) {
  data = qs.stringify(data)
  return useRequest({
    url: '/bigmember/follow/ent/labelGroupList',
    method: 'post',
    data
  })
}
