// 静态路由
export default [
  {
    path: '/index',
    name: 'collection',
    component: () => import('@/views/collection/index.vue'),
    meta: {
      header: true,
      title: '标讯收藏'
    }
  }
]
