/**
 * 查询父级节点是否包含指定 ClassName
 * @param event
 * @param hasClass
 * @param maxDepth - 最大查询层级
 */

export function checkAncestorClass(event, hasClass, maxDepth = 3) {
  let target = event.target
  let depth = 0 // 添加一个深度计数器

  while (target && target.nodeType === Node.ELEMENT_NODE && depth < maxDepth) {
    if (target.className.indexOf(hasClass) !== -1) {
      return {
        status: true,
        target
      }
    }
    target = target.parentNode
    depth++ // 每次循环增加深度计数
  }

  return {
    status: false,
    target
  }
}
