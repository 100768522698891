import { infoTypeMapExp } from '@/data'

/**
 * 标讯搜索后端接收数据结构和前端组件需要的数据结构转换
 */
export class InfoTypeTransform {
  /**
   * 输入参数格式: 一级分类全选则为空，不全选则不为空。没有选则map中没有该项
    {
      招标结果: ['中标'],
      招标预告: [],
      拟建项目: []
    }

    输出选择器需要的格式
    参数输出格式: ['中标', '预告', '预审', '预审结果', '论证意见', '需求公示', '拟建']
   */
  static mapToList(map) {
    let list = []
    if (!map) return list
    for (const key in map) {
      if (!Array.isArray(map[key])) {
        continue
      } else {
        // 拟建单独处理
        if (key.includes('拟建')) {
          list.push('拟建')
        } else {
          if (map[key].length === 0) {
            // 子项长度为0表示全选，需要把子项push到list中
            infoTypeMapExp[key].forEach((item) => {
              list.push(item)
            })
          } else {
            list = list.concat(map[key])
          }
        }
      }
    }
    return list
  }

  /**
   * 输入参数同mapToList
   *
   * 输出格式：一级分类为空数组则只显示一级分类，不为空则只显示二级分类
   * ['中标', '招标预告', '拟建项目']
   */
  static formatMapToList(map) {
    let mapArr = []
    if (!map) return mapArr
    for (const key in map) {
      if (Array.isArray(map[key]) && map[key].length) {
        mapArr = mapArr.concat(map[key])
      } else {
        mapArr.push(key)
      }
    }
    return mapArr
  }

  /**
   * 参数是formatMapToList的返回值
   * 比如：['中标', '招标预告', '拟建项目']
   * @returns 返回值是mapToList的输入参数
   */
  static formatListToMap(strArr) {
    const map = {}
    if (!Array.isArray(strArr)) return map
    for (const key in infoTypeMapExp) {
      if (strArr.includes(key)) {
        map[key] = infoTypeMapExp[key]
      } else {
        const arr = []
        infoTypeMapExp[key].forEach((item) => {
          if (strArr.includes(item)) {
            arr.push(item)
          }
        })
        if (arr.length) {
          map[key] = arr
        }
      }
    }
    return map
  }

  /**
   * 与mapToList的转换相反
   * 输入参数：mapToList的输出参数 object
   * 输出参数：mapToList的输入参数 ['中标', '预告', '预审', '预审结果', '论证意见', '需求公示', '拟建']
   */
  static listToMap(list) {
    const map = {}
    if (!Array.isArray(list)) return map
    for (const key in infoTypeMapExp) {
      if (key.includes('拟建')) {
        // 拟建项单独处理
        if (list.includes('拟建')) {
          map[key] = []
        }
        continue
      }
      const children = infoTypeMapExp[key]
      const selectedArr = []
      children.forEach((item) => {
        if (list.includes(item)) {
          selectedArr.push(item)
        }
      })
      if (selectedArr.length > 0) {
        if (selectedArr.length >= children.length) {
          map[key] = []
        } else {
          map[key] = selectedArr
        }
      }
    }
    return map
  }
}
