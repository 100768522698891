<template>
  <div>
    <van-datetime-picker
      class="date-time-picker highlight-active"
      v-model="selectTime"
      :show-toolbar="showToolBar"
      :swipe-duration="swipeDuration"
      :type="type"
      :min-date="min"
      :max-date="max"
      :formatter="formatter"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { DatetimePicker } from 'vant'
import dayjs from 'dayjs'
export default {
  name: 'DatetimePicker',
  components: {
    [DatetimePicker.name]: DatetimePicker
  },
  props: {
    active: {
      type: Date,
      default: () => new Date()
    },
    min: {
      type: Date,
      default: () => new Date(2000, 0, 1)
    },
    max: {
      type: Date,
      default: () => new Date()
    },
    format: {
      type: String,
      default: 'YYYY/MM/DD' // 此处传值为了兼容ios，应该用 / 分割
    }
  },
  data() {
    return {
      selectTime: this.active,
      type: 'date',
      swipeDuration: 300,
      showToolBar: false
    }
  },
  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    getStartTime(date) {
      return dayjs(dayjs(date).startOf('day').valueOf()).toDate()
    },
    getState() {
      if (this.format) {
        return dayjs(this.selectTime).format(this.format)
      }
      return this.getStartTime(this.selectTime)
    },
    setState(date) {
      this.selectTime = this.getStartTime(date)
    }
  }
}
</script>

<style scoped></style>
