import request from '@/api'
import qs from 'qs'

// 兑换剑鱼文库热门榜
export function getDocHotList(data) {
  data = qs.stringify(data)
  return request({
    url: '/jydocs/activityList',
    method: 'post',
    data
  })
}

/**
 * 获取剑鱼币明细、签到信息、积分汇总信息等
 * api地址https://showdoc.jydev.jianyu360.com/web/#/34?page_id=630
 * @param data
 * @returns {*}
 */
export function getPointsInfo(data) {
  data = qs.stringify(data)
  return request({
    url: '/jyintegral/getList',
    method: 'post',
    data
  })
}

// 附件下载-文件兑换、购买明细
export function fileExchangeDetail(data) {
  data = qs.stringify(data)
  return request({
    url: '/jypay/resourcePack/exchangeCancelList',
    method: 'post',
    data
  })
}

// 积分查询
export function getProductPoints(data) {
  data = qs.stringify(data)
  return request({
    url: '/jyintegral/productPoints/introduction',
    method: 'post',
    data
  })
}
// 附件下载-积分兑换产品
export function exchangePointsProduct(data) {
  data = qs.stringify(data)
  return request({
    url: '/jyintegral/exchange',
    method: 'post',
    data
  })
}
// 获取剑鱼币任务
export function getPointTask() {
  return request({
    url: '/jyActivity/task',
    method: 'post'
  })
}
// 确认挑战
export function confirmChallenge(data) {
  return request({
    url: '/jyActivity/confirmChallenge',
    method: 'post',
    data
  })
}
