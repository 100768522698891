function createRouterNamedComponents(r) {
  const defaultComponents = {
    header: () => import('@/views/create-order/components/common/Header'),
    default: null,
    activity: () => import('@/views/create-order/components/common/Activity'),
    form: () => import('@/views/create-order/components/common/Form'),
    desc: null,
    adsense: null,
    footerNotice: null,
    footer: () => import('@/views/create-order/components/common/Footer')
  }
  return Object.assign(defaultComponents, r)
}

// 公共路由
export default [
  {
    path: '/create',
    component: () => import('@/views/create-order/CreateOrderLayout.vue'),
    children: [
      {
        path: 'svip',
        name: 'createSvipOrder',
        meta: {
          header: true,
          productId: 101,
          title: '超级订阅'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/vipsubscribe/Default'),
          adsense: () =>
            import('@/views/create-order/components/vipsubscribe/Adsense'),
          desc: () =>
            import('@/views/order/components/vipsubscribe/Introduction'),
          footerNotice: () =>
            import('@/views/order/components/vipsubscribe/FooterNoticeBar')
        })
      },
      {
        path: 'dataexport',
        name: 'creatDataExportOrder',
        meta: {
          header: true,
          productId: 102,
          title: '数据导出'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/dataexport/Default'),
          desc: () => import('@/views/create-order/components/dataexport/Desc'),
          form: () => import('@/views/create-order/components/dataexport/Form'),
          footer: () =>
            import('@/views/create-order/components/dataexport/Footer')
        })
      },
      {
        path: 'areapack',
        name: 'createAreaPackOrder',
        meta: {
          header: true,
          productId: 113,
          title: '省份订阅包'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/areapack/Default'),
          footerNotice: () =>
            import('@/views/create-order/components/areapack/FooterNoticeBar'),
          desc: () => import('@/views/create-order/components/areapack/Desc')
        })
      },
      {
        path: 'filepack',
        name: 'createFilePackOrder',
        meta: {
          header: true,
          productId: 114,
          title: '附件下载包'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/sourcepacks/Default'),
          desc: () =>
            import('@/views/order/components/sourcepacks/FilePackDesc')
        })
      },
      {
        path: 'buyerpack',
        name: 'createBuyerPackOrder',
        meta: {
          header: true,
          productId: 115,
          title: '采购单位画像包'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/sourcepacks/Default'),
          desc: () =>
            import('@/views/order/components/sourcepacks/FilePackDesc')
        })
      },
      {
        path: 'bigmember',
        name: 'createBigmemberOrder',
        meta: {
          header: true,
          productId: 104,
          title: '大会员'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/bigmember/Default'),
          desc: () => import('@/views/order/components/bigmember/Introduction')
        })
      },
      {
        path: 'datareport',
        name: 'createDataReportOrder',
        meta: {
          header: true,
          productId: 117,
          title: '数据报告'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/datareport/Default'),
          desc: () => import('@/views/order/components/datareport/Introduction')
        })
      },
      {
        path: 'course',
        name: 'createCourseOrder',
        meta: {
          header: true,
          productId: 111,
          title: '中标必听课'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/course/ProductionCard'),
          desc: () => import('@/views/order/components/course/Introduction')
        })
      },
      {
        path: 'datapack',
        name: 'createDataPackOrder',
        meta: {
          header: true,
          productId: 112,
          title: '数据流量包'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/datapack/Default'),
          desc: () => import('@/views/order/components/datapack/Introduction')
        })
      },
      {
        path: 'docsvip',
        name: 'createDocsvipOrder',
        meta: {
          header: true,
          productId: 118,
          title: '剑鱼文库会员'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/docsvip/Default'),
          adsense: () =>
            import('@/views/create-order/components/docsvip/Adsense'),
          desc: () => import('@/views/order/components/docsvip/Introduction')
        })
      },
      {
        path: 'marketanalysis',
        name: 'createMarketanalysisOrder',
        meta: {
          header: true,
          productId: 203,
          title: '市场分析定制报告下载包充值'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/reportanalysis/Default'),
          desc: () =>
            import(
              '@/views/create-order/components/reportanalysis/Introduction'
            )
        })
      },
      {
        path: 'enterpriseanalysis',
        name: 'createEnterpriseanalysisOrder',
        meta: {
          header: true,
          productId: 201,
          title: '企业中标分析报告下载包充值'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/reportanalysis/Default'),
          desc: () =>
            import(
              '@/views/create-order/components/reportanalysis/Introduction'
            )
        })
      },
      {
        path: 'owneranalysis',
        name: 'createOwneranalysisOrder',
        meta: {
          header: true,
          productId: 202,
          title: '业主采购分析报告下载包充值'
        },
        components: createRouterNamedComponents({
          default: () =>
            import('@/views/create-order/components/reportanalysis/Default'),
          desc: () =>
            import(
              '@/views/create-order/components/reportanalysis/Introduction'
            )
        })
      }
    ]
  }
]
