import Vue from 'vue'
import VueRouter from 'vue-router'

let routes = [
  {
    path: '/',
    name: 'home',
    redirect: 'tabbar'
  },
  {
    path: '/informationFlow',
    component: () => import('@/components/ad/InformationFlow.vue')
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: () => import('@/views/refresh.vue'),
    meta: {
      header: true,
      title: ''
    }
  },
  {
    path: '/uersales/newuser',
    name: 'uersales-newuser',
    component: () => import('@/views/uersales/newuser/index.vue'),
    meta: {
      header: false,
      title: '设置兴趣偏好',
      keepAlive: false
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      header: true,
      title: '404'
    }
  }
]

// 测试路由
const testRoute = [
  {
    path: '/debug',
    name: 'debug',
    component: () => import('@/views/Test.vue'),
    meta: {
      header: true,
      title: '页面导航'
    }
  }
]

const files = import.meta.globEager('./modules/*.js')
for (const path in files) {
  const moduleName = path.replace(/.*\/(\w+?)\.\w+$/, '$1')
  if (path.startsWith('./modules/index')) continue
  const module = files[path]
  module.default.forEach((v) => {
    v.path = `/${moduleName}${v.path}`
    if (v.name) {
      v.name = `${moduleName}-${v.name}`
    }
  })
  routes = routes.concat(module.default || module)
}

const tempObj = {
  path: '*',
  redirect: '/404'
}
routes = routes.concat(testRoute)
routes = routes.concat(tempObj)

if (import.meta.env.DEV) {
  Vue.use(VueRouter)
}

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: import.meta.env.VITE_APP_BASE_URL,
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes
  })

const router = createRouter()
export default router
