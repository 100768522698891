<template>
  <svg
    v-if="svg"
    class="svg-icon"
    :class="getIcon"
    aria-hidden="true"
    :style="getStyle"
    v-on="$listeners"
  >
    <use :xlink:href="'#' + getIcon"></use>
  </svg>
  <i
    v-else
    class="iconfont"
    :class="getIcon"
    :style="getStyle"
    v-on="$listeners"
  ></i>
</template>

<script>
import { px2px } from '@/utils'

export default {
  name: 'AppIcon',
  props: {
    svg: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'inherit'
    },
    size: {
      type: [String, Number],
      default: 'inherit'
    },
    classPrefix: {
      type: String,
      default: 'icon-'
    }
  },
  computed: {
    getIcon() {
      return this.classPrefix + this.name
    },
    getSizeUnit() {
      return /^\d*\.?\d+$/.test(this.size) ? 'px' : ''
    },
    getFontSize() {
      let result = ''
      if (this.size !== 'inherit') {
        if (this.getSizeUnit === 'px') {
          result = px2px(this.size)
        } else {
          result = this.size
        }
      }
      return result
    },
    getStyle() {
      return {
        color: this.color !== 'inherit' ? this.color : '',
        'font-size': this.getFontSize
      }
    }
  }
}
</script>

<style scoped>
.iconfont {
  line-height: unset;
}
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
