<template>
  <div class="empty-container">
    <div class="empty-content-position">
      <div class="image" :class="stateClass"></div>
      <div class="empty-main tip-text">
        <slot name="default"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppEmpty',
  components: {},
  props: {
    /**
     * 图标名称 back [back cry chagrin sleep smile box]
     */
    state: {
      type: String,
      default: 'back'
    }
  },
  computed: {
    stateClass() {
      const imgType = this.typeSrc[this.state]
      const classArr = [imgType]
      if (!this.$envs.inH5) {
        classArr.push('fixed-position')
      }
      return classArr
    }
  },
  data: () => ({
    typeSrc: {
      back: 'empty-back',
      cry: 'empty-cry',
      chagrin: 'empty-chagrin',
      sleep: 'empty-sleep',
      smile: 'empty-smile',
      box: 'empty-box',
      ai: 'empty-ai-empty',
      'ai-logo': 'empty-ai-logo'
    }
  })
}
</script>

<style lang="scss" scoped>
.empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 100%;
  padding: 16px;
  box-sizing: border-box;

  .empty-content-position {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .empty-main {
    @extend .empty-content-position;
    text-align: center;
  }

  .max-width70 {
    width: 70%;
  }

  .max-width80 {
    width: 80%;
  }

  .tip-text {
    color: #5f5e64;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  .tip-sub-text {
    color: #9b9ca3;
    font-size: 13px;
    line-height: 20px;
    margin-top: 6px;
    text-align: center;
  }

  .image {
    width: 200px;
    height: 200px;
    &.empty-box {
      margin-top: unset;
      width: 160px;
      height: 160px;
    }
  }

  .fixed-position {
    margin-top: -50px;
  }

  $empty-types: (back, cry, chagrin, sleep, smile, box, 'ai-empty', 'ai-logo');

  @each $empty in $empty-types {
    .empty-#{$empty} {
      background: url(@/assets/image/public/jy-#{$empty}.png) no-repeat;
      background-size: contain;
    }
  }
}
</style>
