// 数据导出路由
export default [
  {
    path: '/nothing',
    name: 'nothing',
    component: () => import('@/views/dataexport/Nothing.vue'),
    meta: {
      header: true,
      title: '数据导出'
    }
  },
  {
    path: '/preview/:id',
    name: 'preview',
    component: () => import('@/views/dataexport/PreviewData.vue'),
    meta: {
      header: true,
      title: '预览数据'
    }
  },
  {
    path: '/limitpreview/:id',
    name: 'limitpreview',
    component: () => import('@/views/dataexport/LimitPreviewData.vue'),
    meta: {
      header: true,
      title: '数据导出'
    }
  }
]
