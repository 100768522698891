// 静态路由
export default [
  {
    path: '/guide',
    name: 'guide',
    component: () => import('@/views/static/Guide.vue'),
    meta: {
      title: '引导页'
    }
  },
  {
    path: '/follow_guide',
    name: 'follow_guide',
    component: () => import('@/views/static/FollowGuide.vue'),
    meta: {
      header: true,
      title: '关注公众号'
    }
  },
  {
    path: '/open_in_browser',
    name: 'open_in_browser',
    component: () => import('@/views/static/OpenInBrowser.vue'),
    meta: {
      title: '在浏览器中打开'
    }
  },
  {
    path: '/online_terms',
    name: 'online_terms',
    component: () => import('@/views/static/OnlineTerms.vue'),
    meta: {
      header: true,
      title: '剑鱼标讯线上购买与服务条款'
    }
  },
  {
    path: '/use_terms',
    name: 'use_terms',
    component: () => import('@/views/static/UseTerms.vue'),
    meta: {
      header: true,
      title: '剑鱼币充值及使用须知'
    }
  },
  {
    path: '/deduct_terms',
    name: 'deduct_terms',
    component: () => import('@/views/static/DeductTerms.vue'),
    meta: {
      header: true,
      title: '剑鱼币消费扣除规则'
    }
  },
  {
    path: '/big_contrast',
    name: 'big_contrast',
    component: () => import('@/views/static/BigContrast.vue'),
    meta: {
      header: true,
      title: '剑鱼标讯大会员'
    }
  },
  {
    path: '/terms/activity/qq_live',
    name: 'qq_live_terms',
    component: () => import('@/views/static/ActivityQQLiveTerms.vue'),
    meta: {
      header: true,
      title: '赠送须知'
    }
  },
  // 通用赠送须知。
  {
    path: '/terms/activity',
    name: 'activity_terms',
    component: () => import('@/views/static/ActivityTerms.vue'),
    meta: {
      header: true,
      title: '赠送须知'
    }
  },
  {
    path: '/subscribe_transfer',
    name: 'subscribe_transfer',
    component: () => import('@/views/static/SubscribeTransfer.vue'),
    meta: {
      header: false,
      title: '超级订阅中转页'
    }
  },
  {
    path: '/svip/gift/notice',
    name: 'subscribe_transfer',
    component: () => import('@/views/static/SVipGiftNotice.vue'),
    meta: {
      header: true,
      title: '送好友超级订阅”产品须知'
    }
  }
]
