export default [
  {
    path: '',
    component: () => import('@/views/select/layout.vue'),
    children: [
      {
        path: 'invite',
        name: 'invite',
        component: () => import('@/views/select/workmate.vue'),
        meta: {
          header: true,
          title: '选择部门'
        }
      },
      {
        path: 'invite/:id',
        name: 'invite-id',
        component: () => import('@/views/select/workmate.vue'),
        meta: {
          header: true,
          title: '选择部门'
        }
      }
    ]
  }
]
