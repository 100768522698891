import service from './service'
import { Toast } from 'vant'
import { iOSVersionLt14 } from '@/utils'

const debug = import.meta.env.DEV
// ios13以及ios13以下版本，会被缓存Post请求。此处判断添加时间戳
const needAppendTime = iOSVersionLt14()

if (debug) {
  console.log('[debug]当前环境:', import.meta.env.MODE)
}

service.interceptors.request.use(
  (config) => {
    if (config?.formData) {
      config.headers.content = 'multipart/form-data'
    }
    if (config?.data?.noToast) {
      delete config?.data.noToast
      config.noToast = true
    }
    // 添加时间戳禁用缓存
    const isBeforeApi = config.url.indexOf('/jyapi/') !== -1
    if (needAppendTime && !isBeforeApi) {
      if (!config.params) {
        config.params = {}
      }
      config.params.t = Date.now()
    }

    return config
  },
  (error) => {
    if (debug) {
      console.log('[debug]请求错误:', error)
    }
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (response.status === 200) {
      // 发送请求时配置 noToast 则不弹出 toast 提示
      if (res && !response.config.noToast) {
        // 判断是否需要登录
        if (res.error_msg === '需要登录' || response.data.error_code === 1001) {
          Toast('需要登录')
        } else if (res.error_msg) {
          Toast(res.error_msg)
        }
      }
    } else {
      console.warn(res)
      return Promise.reject(new Error('Error'))
    }
    return res
  },
  (error) => {
    if (debug) {
      console.log('[debug]返回数据错误:', error)
    }
    return Promise.reject(error)
  }
)

export default service
