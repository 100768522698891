export default [
  {
    path: '',
    component: () => import('@/views/tabbar/Layout.vue'),
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        path: 'home',
        name: 'tabbar-search',
        alias: [
          '/jyapp/jylab/mainSearch',
          '/jylab/mainSearch',
          // p414渠道用户访问，id可选
          '/channel/cooperate/:id?'
        ],
        component: () => import('@/views/tabbar/Home.vue'),
        meta: {
          title: '首页',
          keepAlive: true,
          tabbar: 'search'
        }
      },
      {
        path: 'subscribe',
        name: 'tabbar-subscribe',
        alias: ['/jyapp/swordfish/historypush', '/swordfish/newhistorypush'],
        component: () => import('@/views/tabbar/Subscribe.vue'),
        meta: {
          title: '订阅',
          tabbar: 'subscribe'
        }
      },
      {
        path: 'box',
        name: 'tabbar-box',
        alias: ['/jyapp/jylab/index', '/page_treasurebox/index.html'],
        component: () => import('@/views/tabbar/Box.vue'),
        meta: {
          title: '工作台',
          keepAlive: true,
          tabbar: 'box'
        }
      },
      {
        path: 'me',
        name: 'tabbar-me',
        alias: ['/tabbar/mine', '/jyapp/free/me', '/front/wxMyOrder/myMenu'],
        component: () => import('@/views/tabbar/Mine.vue'),
        meta: {
          title: '我的',
          keepAlive: true,
          tabbar: 'me'
        }
      },
      {
        path: 'message',
        name: 'tabbar-message',
        alias: [
          '/jyapp/frontPage/messageCenter/sess/index',
          '/weixin/frontPage/messageCenter/sess/index'
        ],
        component: () => import('@/views/tabbar/Message.vue'),
        meta: {
          title: '消息中心',
          tabbar: 'message'
        }
      }
      // {
      //   path: 'todoList',
      //   name: 'tabbar-todoList',
      //   component: () => import('@/views/message/TodoList.vue'),
      //   meta: {
      //     header: true,
      //     title: '待办',
      //     tabbar: 'message'
      //   }
      // }
    ]
  },
  {
    path: '/recommendedlist',
    name: 'tabbar-recommendedlist',
    component: () => import('@/views/tabbar/RecommendedBannerlist.vue'),
    meta: {
      header: true,
      title: '推荐标讯列表'
      // tabbar: 'subscribe'
    }
  }
]
