import request from '@/api'
import qs from 'qs'

/**
 * 获取用户标讯收藏自定义标签
 */
export function ajaxGetKeepLabels() {
  return request({
    url: '/publicapply/bidcoll/getLabel',
    method: 'post'
  })
}

/**
 * 用户收藏标讯
 */
export function ajaxSetUserKeep(data) {
  return request({
    url: '/publicapply/bidcoll/action',
    method: 'post',
    data
  })
}

/**
 * 收藏标讯后添加标签
 */
export function ajaxSetDiyLabel(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/bidcoll/addLabel',
    method: 'post',
    data
  })
}

/**
 * 收藏标讯后添加标签
 */
export function ajaxSetUserKeepLabels(data) {
  return request({
    url: '/publicapply/bidcoll/label',
    method: 'post',
    data
  })
}
