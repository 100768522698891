import qs from 'qs'
import request from '@/api'

/**
 * 根据 entId 获取 企业信息
 */
export function getEntInfo(data) {
  return request({
    url: '/entbase/ent/info',
    method: 'post',
    data
  })
}
/**
 * 查询用户当前企业信息
 * https://showdoc.jydev.jianyu360.com/web/#/16?page_id=289
 */
export function getMySelectEntInfo() {
  return request({
    url: '/entbase/ent/entinfo',
    noToast: true,
    method: 'post'
  })
}

export function selectEnt() {
  return request({
    url: '/entbase/ent/mySelectent',
    method: 'get',
    params: {
      t: Date.now()
    }
  })
}

/**
 * 查询部门下的人员信息
 */
export function getDeptPerson(data) {
  data = qs.stringify(data)
  return request({
    url: '/entbase/person/manager',
    method: 'post',
    data
  })
}
// 根据当前权限获取部门人员数据
export function getMypersondept(data) {
  return request({
    url: '/entbase/person/dept',
    method: 'get',
    params: data
  })
}

// 获取用户权限
export function getUserRule() {
  return request({
    url: '/entnicheNew/distribute/userRule',
    method: 'GET'
  })
}
