import { GUIDE_CACHE_KEY } from '@/utils/constant'
import Cookies from 'js-cookie'

/**
 * 判断是否存在引导页访问记录
 * @returns {boolean}
 */
export function checkHasGuideRecord() {
  return (
    sessionStorage.getItem(GUIDE_CACHE_KEY) ||
    localStorage.getItem(GUIDE_CACHE_KEY) ||
    Cookies.get(GUIDE_CACHE_KEY) ||
    false
  )
}
