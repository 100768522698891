
import request from '@/api'
import qs from 'qs'
export function getPdfEmail(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/screenCondition/getPdfEmail',
    method: 'post',
    data
  })
}
export function pdfSendEmail(data) {
  return request({
    url: '/jypay/pdfExportPack/download',
    method: 'post',
    data
  })
}
export function getPdfList(data) { //下载记录列表
  data = qs.stringify(data)
  return request({
    url: '/bigmember/analysisReport/getPdfList',
    method: 'post',
    data
  })
}
export function PdfrecordList(data) { //下载记录明细
  return request({
    url: '/jypay/pdfExportPack/recordList',
    method: 'get',
    params: data
  })
}
export function pdfaccount(data) {
  data = qs.stringify(data)
  return request({
    url: '/jypay/pdfExportPack/account',
    method: 'post',
    data
  })
}
