import service from './service'
import { registerAntiInterceptors } from '@jy/vue-anti'
import { openLinkOfOther } from '@/utils'
import { LINKS } from '@/data'

const loginButtonClick = () => {
  openLinkOfOther(LINKS.APP登录页.app, {
    query: {
      to: 'back'
    }
  })
}

registerAntiInterceptors(service, { loginButtonClick })

export default service
