import { dateFormatter, formatMoney, getQueryParam } from '@jy/util'
import BaseModel from '../../../core/base'

class CommonContentModel extends BaseModel {
  createModel() {
    const contentModel = {
      id: '',
      title: '',
      // 项目信息
      projectName: '',
      projectCode: '',
      // 高亮词组
      highlightKeys: [],
      tags: [],
      titleHighlighted: '',
      contentHighlighted: '',
      time: '',
      // 计算信息
      isSelfSite: false,
      // 正文
      content: '',
      // 是否需要展示原文链接
      originalShow: false,
      // 附件列表
      attachments: [],
      // 是否需要展示供应商报名
      recommendedService: false,

      // 拟建，采购意向
      isNiJian: false,
      isCaigouyixiang: false,
      isCanRead: false,

      // TDK
      tdk: {
        title: '',
        keywords: '',
        description: ''
      }
    }
    return contentModel
  }

  formatModel(data) {
    const result = this.createModel()
    const { baseInfo, detailInfo, abstract } = data
    const fromSunList = getQueryParam('fromlist') === 'sun'
    result.id = baseInfo.id ? decodeURIComponent(baseInfo.id) : ''
    result.title = baseInfo.title
    result.projectName = baseInfo?.projectName
    result.projectCode = baseInfo?.projectCode
    result.isSelfSite = baseInfo?.site === '剑鱼信息发布平台'
    result.time = baseInfo?.publishTime
      ? dateFormatter(baseInfo.publishTime * 1000, 'yyyy-MM-dd')
      : ''
    result.highlightKeys = this.splitUrlKeys()
    result.content = detailInfo.detail || ''
    // 附件列表
    result.attachments = detailInfo.attachments || []
    // 原文链接是否显示
    result.originalShow = detailInfo.originalShow || false
    result.originalUrl = detailInfo.originalHref || ''

    // 是否显示供应商报名
    result.recommendedService = baseInfo?.recommendedService === 1

    // 拟建，采购意向
    result.isNiJian = this.isNiJianCheck(baseInfo?.subType)
    result.isCaigouyixiang = this.isCaigouyixiangCheck(baseInfo?.subType)
    result.isCanRead = data?.canRead || false
    result.IsCustomTopNet = data?.topnet || false
    result.publicType = baseInfo.publicType
    // 是否有阳光直采权限
    result.hasPowerToReadSunPublishContent = data?.purchase // 阳光直采-采购信息
    // 是否阳光直采相关的标讯
    result.IsSunPublishContent = baseInfo.infoAttribute === 'zc_cgxx' // 阳光直采-采购信息
    // 是否运营商专版详情页
    result.yyszbContent = location.pathname.includes('/yyszb')
    result.bId = baseInfo.bId || ''

    // 阳光直采过来的小程序分享标题处理
    if (fromSunList) {
      result.listShareTitle = this.getSunListTitle([baseInfo.id, result.id])
    }
    if (result.IsSunPublishContent) {
      // 处理拆条信息
      const changedTitle = this.changeChaiTitle(abstract, result.listShareTitle)
      if (changedTitle) {
        result.title = changedTitle
        result.changedTitle = changedTitle
      }
    }

    // TDK
    result.tdk.title = result.title
    result.tdk.description = baseInfo?.description
    result.tdk.keywords = baseInfo?.keywords
    // Tags
    result.tags = this.tranTags(data, result)

    result._ob = baseInfo
    result._od = detailInfo
    return result
  }

  isNiJianCheck(type) {
    return type === '拟建'
  }

  isCaigouyixiangCheck(type) {
    return type === '采购意向'
  }

  tranTags(data, result) {
    const { IsSunPublishContent, yyszbContent } = result
    const { baseInfo, abstract } = data
    const defaultURL = 'javascript:void(0);'

    const area = [baseInfo?.area, baseInfo?.city, baseInfo?.district]
      .filter(v => v)
      .join('-')

    const typeItem = {
      label: baseInfo?.topType,
      link: defaultURL
    }
    if (baseInfo?.subType) {
      typeItem.label = baseInfo.subType
      typeItem.link = baseInfo?.subTypeUrl || defaultURL
    }

    const amountItem = {
      label: formatMoney(baseInfo?.bidAmount || baseInfo?.budget || ''),
      link: defaultURL
    }

    let originTags = [
      // 地区
      {
        label: area,
        link: baseInfo?.areaUrl || defaultURL
      },
      // type
      IsSunPublishContent ? {} : typeItem,
      // buyer_class
      {
        label: baseInfo?.buyerClass,
        link: defaultURL
      },
      // amount
      amountItem
    ]

    if (yyszbContent) {
      originTags = [
        {
          label: area,
          link: baseInfo?.areaUrl || defaultURL
        },
        {
          label: baseInfo?.subType || baseInfo?.topType,
          link: defaultURL
        },
        {
          label: baseInfo?.industry || '',
          link: defaultURL
        }
      ]
    }

    if (IsSunPublishContent) {
      // 取时间
      const signEndTime = abstract?.default?.signEndTime
      if (signEndTime) {
        const now = Date.now()
        const signupEnd = signEndTime * 1000 < now
        if (signupEnd) {
          const jiezhi = {
            label: '报名已截止',
            link: defaultURL
          }
          originTags.push(jiezhi)
        }
        else {
          const jiezhi = {
            label: '报名未截止',
            className: 'tag-orange border orange-deep',
            link: defaultURL
          }
          originTags.push(jiezhi)
        }
      }
    }

    const tags = originTags.filter(v => v.label)
    return tags.map((t) => {
      return {
        ...t,
        // 是否有正确格式的链接
        hasLink: t.link !== defaultURL
      }
    })
  }

  // 获取url高亮字符, 并截取
  splitUrlKeys() {
    const kds = getQueryParam('kds')
    const keywords = getQueryParam('keywords')
    const s_words = kds || keywords
    let keysList = []

    // 移动端订阅预览等跳转过来传的词
    const subKeyReg = /^subkey_/
    if (subKeyReg.test(s_words)) {
      keysList = s_words.replace(subKeyReg, '').split('_')
      let keyListArr = []
      keysList.forEach((v) => {
        const vArr = v.split('+')
        keyListArr = keyListArr.concat(vArr)
      })
    }
    else {
      const reg = /[_+\s]/ // 匹配 _+空格
      if (reg.test(s_words)) {
        keysList = s_words.split(reg).filter(w => !!w)
      }
      else {
        keysList.push(s_words)
      }
    }
    return keysList
  }

  // 处理拆条信息：详情页中需要判断purchasingList长度>1，就使用列表页标题+‘等’。
  changeChaiTitle(abstract, title) {
    const purchasingList = abstract?.default?.purchasingList
    if (Array.isArray(purchasingList) && purchasingList.length > 0) {
      if (title) {
        return title
      }
    }
    return ''
  }

  getSunListTitle(idArr = []) {
    const key = 'sun_list_info_clicked'
    const saved = sessionStorage.getItem(key)
    if (saved) {
      const list = JSON.parse(saved)
      if (Array.isArray(list)) {
        const r = list.find(
          item => idArr.includes(item.id)
        )
        if (r && r.title) {
          return r.title
        }
      }
    }
    return ''
  }
}

function useCommonContentModel() {
  return new CommonContentModel()
}

export default useCommonContentModel
