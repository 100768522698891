import request from '@/api'
import qs from 'qs'

export function ajaxGetPromptTypes() {
  return request({
    baseURL: '/aiChat',
    url: '/aiSearch/problem/configuration',
    method: 'post',
    noToast: true
  })
}

export function ajaxNewTask() {
  return request({
    baseURL: '/aiChat',
    url: '/aiSearch/session/newCreate',
    method: 'post',
    noToast: true
  })
}

export function ajaxGetMoreList(data) {
  return request({
    baseURL: '/aiChat',
    url: '/aiSearch/bidding/List',
    method: 'post',
    noToast: true,
    data
  })
}

export function ajaxActionLike(data) {
  return request({
    baseURL: '/aiChat',
    url: '/aiSearch/answer/like',
    method: 'post',
    noToast: true,
    data
  })
}

export function ajaxGetHistoryList(data) {
  return request({
    baseURL: '/aiChat',
    url: '/aiSearch/session/history/list',
    method: 'post',
    noToast: true,
    data
  })
}

export function ajaxGetHistoryDetail(data) {
  return request({
    baseURL: '/aiChat',
    url: '/aiSearch/session/detail',
    method: 'post',
    noToast: true,
    params: data
  })
}

export function ajaxSetMessage(data) {
  return request({
    baseURL: '/aiChat',
    url: '/aiSearch/chat',
    method: 'post',
    noToast: true,
    data
  })
}
export function ajaxDelTask(data) {
  return request({
    baseURL: '/aiChat',
    url: '/aiSearch/session/delete',
    method: 'post',
    noToast: true,
    data
  })
}
