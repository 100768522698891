import request from '@/api'

// 获取数据超市列表信息
export function getDataSmtList(data) {
  return request({
    url: '/jyapi/publicService/List',
    method: 'post',
    data
  })
}

// 获取数据超市--产品详情页
export function getDataSmtDetail(data) {
  return request({
    url: '/jyapi/publicService/Detail',
    method: 'post',
    data
  })
}
