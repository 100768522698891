import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import search from './modules/search'
import treasureBox from './modules/treasureBox'
import createOrder from './modules/createOrder'
import message from './modules/message'
import ent from './modules/ent'
import points from './modules/points'
import article from './modules/article'
import docs from './modules/docs'
import group from './modules/group'

if (import.meta.env.DEV) {
  Vue.use(Vuex)
}

const store = new Vuex.Store({
  strict: true,
  state: {
    direction: 'reload', // 页面切换方向 (reload / back / forward)
    // keepAlive 缓存的组件 组件的name属性数组，在 App.vue 中被初始化
    // 规定：被缓存的组件的路由名和组件名要相等
    // 初始化，所有 meta: keepAlive: true 的都会被添加进 keepAlive
    keepAliveList: []
  },
  mutations: {
    // 更新页面切换方向
    updateDirection(state, direction) {
      state.direction = direction
    },
    // 对指定组件进行动态缓存 -- 组件调用该方法时，判断该组件是否存在于该缓存数组，无则添加
    keepAlive(state, component) {
      !state.keepAliveList.includes(component)
      && state.keepAliveList.push(component)
    },
    // 对指定组件进行动态取消缓存-- 组件调用该方法时，从缓存数组中删除对应的组件元素
    unKeepAlive(state, component) {
      const index = state.keepAliveList.indexOf(component)
      index > -1 && state.keepAliveList.splice(index, 1)
    }
  },
  getters: {},
  modules: {
    user,
    search,
    treasureBox,
    message,
    createOrder,
    ent,
    article,
    points,
    docs,
    group
  }
})
export default store
export const useStore = () => store
