// 搜索接口参数表
export const biddingSearchListType = [
  'fType', // 免费用户
  'pType', // 付费用户
  'vType', // 超级订阅用户
  'mType', // 大会员用户
  'eType' // 商机管理用户
]

// 招标搜索范围
export const biddingSearchScope = [
  {
    label: '标题',
    key: 'title'
  },
  {
    label: '正文',
    key: 'content'
  },
  {
    label: '附件',
    key: 'file'
  },
  {
    label: '项目名称/标的物',
    key: 'ppa',
    needPower: true
  },
  {
    label: '采购单位',
    key: 'buyer',
    needPower: true
  },
  {
    label: '中标企业',
    key: 'winner',
    needPower: true
  },
  {
    label: '招标代理机构',
    key: 'agency',
    needPower: true
  }
]

export const biddingSearchTime = [
  {
    label: '最近7天',
    key: 'lately-7'
  },
  {
    label: '最近30天',
    key: 'lately-30'
  },
  {
    label: '最近1年',
    key: 'thisyear'
  },
  {
    label: '最近3年',
    key: 'threeyear',
    needPower: true
  },
  {
    label: '最近5年',
    key: 'fiveyear',
    needPower: true
  },
  {
    label: '自定义',
    key: 'exact',
    needPower: true
  }
]

// 收藏页面-收藏日期
export const collectionSearchTime = [
  {
    label: '全部',
    key: 'all'
  },
  {
    label: '今天',
    key: 'today'
  },
  {
    label: '昨天',
    key: 'yesterday'
  },
  {
    label: '最近7天',
    key: 'lately-7'
  },
  {
    label: '最近30天',
    key: 'lately-30'
  },
  {
    label: '自定义',
    key: 'exact',
    needPower: true
  }
]

// 是否有联系方式
export const biddingSearchConcat = [
  {
    label: '不限',
    key: ''
  },
  {
    label: '有联系方式',
    key: 'y'
  }
]

// 附件
export const biddingSearchFileExists = [
  {
    label: '全部',
    key: '0'
  },
  {
    label: '有附件',
    key: '1'
  },
  {
    label: '无附件',
    key: '-1'
  }
]

// 查看状态
export const biddingSearchViewStatus = [
  {
    label: '全部',
    key: ''
  },
  {
    label: '未查看',
    key: '0'
  },
  {
    label: '已查看',
    key: '1'
  }
]

// 信息来源
export const biddingSearchInfoSource = [
  {
    label: '全部',
    key: '0'
  },
  {
    label: '企业手动分发',
    key: '3'
  },
  {
    label: '企业自动分发',
    key: '2'
  },
  {
    label: '个人订阅',
    key: '1'
  }
]

export const biddingCustomPriceOptions = [
  {
    label: '0-10万',
    value: '0_10'
  },
  {
    label: '10-20万',
    value: '10_20'
  },
  {
    label: '20-50万',
    value: '20_50'
  },
  {
    label: '50-100万',
    value: '50_100'
  },
  {
    label: '100-200万',
    value: '100_200'
  },
  {
    label: '200-500万',
    value: '200_500'
  },
  {
    label: '500-1000万',
    value: '500_1000'
  },
  {
    label: '1000万以上',
    value: '1000_'
  }
]
