import router from '../router'
import store from '@/store'
import { checkCacheValidity } from '@/utils/storage'

const maxCacheTime = 10 * 1000
let lastTime = 0
function checkUseCacheState() {
  return new Promise((resolve, reject) => {
    const nowTime = Date.now()
    const result = nowTime - maxCacheTime > lastTime
    if (result) {
      lastTime = nowTime
      resolve()
    } else {
      reject(new Error('Cache not expired'))
    }
  })
}

function toggleAppLoading(type) {
  document.body.classList[type ? 'add' : 'remove']('app-loading')
}

/**
 * 用于获取用户是否登录
 *  -> 检查缓存数据是否可用 对比缓存SESSIONID与当前SESSIONID
 *    -> 可用 跳过
 *      -> 已存在 userId 跳过
 *      -> 不存在 获取 userId
 */
router.beforeEach(async (to, from, next) => {
  const appStorage = checkCacheValidity()
  const UserID = store.getters['user/getUserId']
  if (!UserID) {
    await checkUseCacheState()
      .then(async () => {
        // toggleAppLoading(true)
        await store.dispatch('user/getUserSimpleInfo', {
          noToast: true
        })
        // toggleAppLoading(false)
      })
      .catch((e) => {
        console.warn(e)
      })
    appStorage.success()
  }
  next()
})

router.beforeEach(async (to, from, next) => {
  const isLogin = store.getters['user/isLogin']
  if (isLogin) {
    // 已登录用户获取权企业信息和权限信息
    try {
      // toggleAppLoading(true)
      await Promise.all([
        store.dispatch('user/getSelectedEntInfoCached'),
        store.dispatch('user/checkPower')
      ])
      // toggleAppLoading(false)
      next()
    } catch (error) {
      console.log(error)
      next()
    }
  } else {
    next()
  }
})
