import request from '@/api'
import qs from 'qs'

// 查询课程详情数据
export function getCourseDetail(data) {
  data = qs.stringify(data)
  return request({
    url: '/OnlineCourses/course/curriculumDetails',
    method: 'post',
    data
  })
}
