import request from '@/api'
import qs from 'qs'
// 获取企业列表
export function getEntList(data) {
  // data = qs.stringify(data)
  return request({
    url: '/publicapply/identity/list',
    method: 'post',
    noToast: true,
    data
  })
}
// 获取公司架构信息
export function getEntDataInfo(data) {
  return request({
    url: '/jyapi/social/entAddressBook',
    method: 'post',
    noToast: true,
    data
  })
}
// 获取自己企业下名称
export function ajaxGetUserInfo(data) {
  return request({
    url: '/jyapi/userCenter/ent/userInfo',
    method: 'post',
    noToast: true,
    data
  })
}

// 获取用户最高权限身份
export function getUserHighestPowerIdentity(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/identity/newProduct',
    method: 'post',
    data
  })
}
