// 全局正则表达式常量

// 手机号校验正则
export const phoneRegExp = /^[1][3-9][0-9]{9}$/

// 邮箱校验
export const emailRegExp = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/

// 图片校验
export const pictureRegExp = /\.(jpeg|jpg|png)$/

// 过滤非数字
export const FilterNumberRegExp = /[^0-9|.]/g

// URL路径是否包含前缀校验
export const NotURLPrefixRegExp = /^\//

// ip正则
export const ipRegExp =
  /((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}/g
