// 静态路由
export default [
  {
    path: '/guide',
    name: 'guide',
    component: () => import('@/views/subscribe/Guide.vue'),
    meta: {
      header: true,
      title: '订阅向导'
    }
  }
]
