<template>
  <div class="j-notice-bar" :class="[theme]" v-on="$listeners">
    <div class="notice-bar-left">
      <slot name="left">
        <AppIcon :name="leftIcon" @click="onClickLeft" />
      </slot>
    </div>
    <div class="notice-bar-center">
      <slot name="default"></slot>
    </div>
    <div class="notice-bar-right">
      <slot name="right">
        <button class="notice-bar-button" @click="onClickRight">
          {{ rightText }}
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
import Icon from '../icon'
export default {
  name: 'NoticeBar',
  components: {
    [Icon.name]: Icon
  },
  props: {
    /**
     * 内置主题色main/red
     */
    theme: {
      type: String,
      default: 'main'
    },
    /**
     * 左侧图标icon name
     */
    leftIcon: {
      type: String,
      default: 'close_touming'
    },
    rightText: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClickLeft() {
      this.$emit('clickLeft')
    },
    onClickRight() {
      this.$emit('clickRight')
    }
  }
}
</script>

<style lang="scss" scoped>
.j-notice-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  &.main {
    color: $white;
    background-color: $main;
    .notice-bar-button {
      border-color: $white;
    }
  }
  &.red {
    color: $red_deep;
    background-color: #fee9e8;
    .notice-bar-button {
      border-color: $red_deep;
    }
  }
  .notice-bar {
    &-left {
      ::v-deep {
        .iconfont {
          font-size: 20px;
        }
      }
    }
    &-center {
      margin: 0 12px;
      flex: 1;
      font-size: 12px;
      line-height: 20px;
    }
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      font-size: 13px;
      border-radius: 8px;
      background-color: transparent;
      border: 1px solid;
    }
  }
}
</style>
