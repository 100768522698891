/**
 * 模型转换-推荐服务
 */
function tranServiceItem(data) {
  const result = {
    title: data?.title,
    content: data?.subtitle || '',
    buttons: data?.buttons || []
  }
  if (data?.tip) {
    const link = data?.labelUrl ? data.labelUrl || '' : ''
    result.header = {
      tip: data?.tip,
      more: {
        label: data?.labelName || '',
        url: link
      }
    }
  }
  return result
}

/**
 * 模型转换-推荐服务列表
 * @param list
 */
function tranServices(list) {
  return list.map((item) => tranServiceItem(item))
}

export default tranServices
