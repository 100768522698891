import qs from 'qs'
import request from '@/api'
// 获取数据超市列表信息
export function getGroupList(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/follow/ent/labelGroup',
    method: 'post',
    data
  })
}
