import request from '@/api'
/**
 * 轻量化营销服务
 */

// 获取服务端当前时间戳
export function getServerInitTime() {
  return request({
    url: '/jyapi/marketing/time/now?t=' + Date.now(),
    noToast: true,
    method: 'post'
  })
}

// 预约
export function appointmentAdd(data) {
  return request({
    url: '/jyapi/marketing/appointment/add',
    method: 'post',
    data
  })
}

// 是否已预约
export function getIsAppointment(data) {
  return request({
    url: '/jyapi/marketing/appointment/isAppointment',
    method: 'post',
    data
  })
}

// 获取当前活动信息
export function getActivityInfo(data) {
  return request({
    url: '/jyapi/marketing/activity/activityInfo',
    method: 'post',
    data
  })
}
