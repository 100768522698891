export default [
  {
    path: '/Invoicing',
    name: 'Invoicing',
    component: () => import('@/views/invoice/Invoicing.vue'),
    meta: {
      title: '开发票',
      header:true
    }
  },
  {
    path: '/viewInvoice',
    name: 'viewInvoice',
    component: () => import('@/views/invoice/viewInvoice.vue'),
    meta: {
      title: '查看发票',
      header:true
    }
  }
]
