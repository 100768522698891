export default [
  {
    path: '/detail',
    name: 'message-detail',
    alias: [
      '/jyapp/frontPage/messageCenter/sess/detail',
      '/weixin/frontPage/messageCenter/sess/detail'
    ],
    component: () => import('@/views/message/Detail.vue'),
    meta: {
      header: true,
      title: '消息中心'
    }
  },
  {
    path: '/todoList',
    name: 'todoList',
    component: () => import('@/views/message/TodoList.vue'),
    meta: {
      header: true,
      title: '待办'
    }
  },
  {
    path: '/msgDetail',
    name: 'msg-detail',
    component: () => import('@/views/message/MsgDetail.vue'),
    meta: {
      header: true,
      title: '消息详情'
    }
  },
  {
    path: '/msgGuide',
    name: 'msg-guide',
    component: () => import('@/views/message/MsgGuide.vue'),
    meta: {
      header: true,
      title: '功能引导说明'
    }
  }
]
