// 广告位集合
export const AdCode = {
  首页: {
    // 弹窗广告位
    full: {
      app: 'jyapp-wxsearch-index',
      h5: 'jyapp-wxsearch-index',
      wx: 'jy-wxsearch-index-tap'
    },
    // 头部搜索框下背景图
    header: {
      app: 'app-home-top-bg',
      h5: 'app-home-top-bg',
      wx: ''
    },
    // 轮播广告位
    swipe: {
      app: 'jy-appsearch-home-top',
      h5: 'jy-appsearch-home-top',
      wx: 'jy-wxsearch-home-top'
    },
    // 金刚区广告位
    floor: {
      app: 'app-home-floors',
      h5: 'app-home-floors',
      wx: 'wx-home-floors'
    },
    // 侧边广告位图标
    side: {
      app: 'app-home-side-icon',
      h5: 'app-home-side-icon',
      wx: 'wx-home-side-icon'
    }
  },
  订阅: {
    top: {
      app: 'app-subscribe-top',
      h5: 'app-subscribe-top',
      wx: 'wx-subscribe-top'
    },
    middle: {
      app: 'jyapp-wxpush-middle',
      h5: 'jyapp-wxpush-middle',
      wx: 'jy-wxpush-middle'
    }
  },
  工作台: {
    top: {
      app: 'app-workmenu-top',
      h5: 'app-workmenu-top',
      wx: 'wx-workmenu-top'
    }
  }
}
