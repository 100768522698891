/**
 * 取中位数
 * @param {number} num
 * @param {number} min
 * @param {number} max
 * @returns number
 */
export function range(num, min, max) {
  return Math.min(Math.max(num, min), max)
}

/**
 * 移除多余的字符
 * @param {string} value
 * @param {string} char
 * @param {RegExp} regExp
 * @returns string
 */
function trimExtraChar(value, char, regExp) {
  const index = value.indexOf(char)
  let prefix = ''

  if (index === -1) {
    return value
  }

  if (char === '-' && index !== 0) {
    return value.slice(0, index)
  }

  if (char === '.' && value.match(/^(\.|-\.)/)) {
    prefix = index ? '-0' : '0'
  }

  return (
    prefix + value.slice(0, index + 1) + value.slice(index).replace(regExp, '')
  )
}

/**
 * 格式化数字字符串
 * @param {string} value
 * @param {Boolean} allowDot
 * @param {Boolean} allowMinus
 * @returns string
 */
export function formatNumber(value, allowDot = true, allowMinus = true) {
  if (allowDot) {
    value = trimExtraChar(value, '.', /\./g)
  } else {
    value = value.split('.')[0]
  }

  if (allowMinus) {
    value = trimExtraChar(value, '-', /-/g)
  } else {
    value = value.replace(/-/, '')
  }

  const regExp = allowDot ? /[^-0-9.]/g : /[^-0-9]/g

  return value.replace(regExp, '')
}

/**
 * add num and avoid float number
 * @param {number} num1
 * @param {number} num2
 * @returns number
 */
export function addNumber(num1, num2) {
  const cardinal = 10 ** 10
  return Math.round((num1 + num2) * cardinal) / cardinal
}

export function equal(value1, value2) {
  return String(value1) === String(value2)
}
