import qs from 'qs'
import request from '@/api'
import { envs } from '@/utils/prototype/modules/platform'

// 获取用户订阅信息
// https://yapi.jydev.jianyu360.com/project/63/interface/api/191
export function getUserSubscribeList(type = 'fType', data) {
  // fType免费用户  vType超级订阅用户  mType大会员用户  eType商机管理用户
  return request({
    url: `/jyapi/jybx/subscribe/${type}/list`,
    method: 'post',
    noToast: true,
    data
  })
}
export function getRecList(data) {
  // 订阅推荐列表
  return request({
    url: '/jyapi/jybx/subscribe/getRecList',
    method: 'post',
    noToast: true,
    data
  })
}
// 获取用户订阅信息其他额外的信息
// https://yapi.jydev.jianyu360.com/project/63/interface/api/203
export function getUserSubscribeSomeInfo(type = 'fType') {
  return request({
    url: `/jyapi/jybx/subscribe/${type}/someInfo`,
    method: 'post',
    noToast: true
  })
}

// 获取用户订阅筛选关键词
// 返回内容中，合并了企业订阅的关键词
export function getUserSubscribeKeywords(type = 'fType', data) {
  data = qs.stringify(data)
  return request({
    url: `/jyapi/jybx/subscribe/${type}/getKey`,
    method: 'post',
    noToast: true,
    data
  })
}

// 推送数据浏览状态修改
export function setUserSubscribeListVisited(type = 'fType', data) {
  data = qs.stringify(data)
  return request({
    url: `/jyapi/jybx/subscribe/${type}/setRead`,
    method: 'post',
    noToast: true,
    data
  })
}

// 免费用户设置订阅关键词
export function freeSubscribeKeys(data) {
  data = qs.stringify(data)
  return request({
    url: envs.inWX
      ? '/member/swordfish/ajaxReq'
      : '/jyapp/member/swordfish/ajaxReq',
    method: 'post',
    data
  })
}

// 企业订阅接收人员获取
export function getDistributorPerson(type = 'fType', data) {
  // data = qs.stringify(data)
  return request({
    url: `/jyapi/jybx/subscribe/${type}/distributor`,
    method: 'post',
    noToast: true,
    data
  })
}

// 订阅页面数据导出接口
export function getPushListDataExportId(type = 'fType', data) {
  return request({
    url: `/jyapi/jybx/subscribe/${type}/byPushHistory`,
    method: 'post',
    data
  })
}

// 订阅设置更新接口

export function getSubScribeUpdateApi(type = 'fType', data) {
  return request({
    url: `/jyapi/jybx/subscribe/${type}/update`,
    method: 'post',
    data
  })
}

// 推送设置接口
// 用户信息获取
export function getUser(data) {
  return request({
    url: '/jyapi//jybx/subscribe/getUser',
    method: 'post',
    data
  })
}

// 用户信息设置
export function setUser(data) {
  return request({
    url: '/jyapi//jybx/subscribe/setUser',
    method: 'post',
    data
  })
}

// 推送设置获取
export function getPushSet(data) {
  return request({
    url: '/jyapi/jybx/subscribe/getPushSet',
    method: 'post',
    data
  })
}

// 推送设置修改
export function setPushSet(data) {
  return request({
    url: '/jyapi/jybx/subscribe/setPushSet',
    method: 'post',
    data
  })
}

// 保存订阅向导设置
export function saveSubscribeGuide(type = 'fType', data) {
  return request({
    url: `/jyapi/jybx/subscribe/${type}/saveTSGuide`,
    method: 'post',
    data
  })
}
