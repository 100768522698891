import { biddingSearchListType } from './bidding'

// api的${mType} 集合
// const biddingSearchListType = [
//   'fType', // 免费用户
//   'pType', // 付费用户
//   'vType', // 超级订阅用户
//   'mType', // 大会员用户
//   'eType' // 商机管理用户
// ]
export const restfulApiUserTypeList = biddingSearchListType

// vSwitch对restfulApiUserType的映射
export const vSwitch2restfulApiUserTypeMap = {
  f: 'fType', // 免费用户
  v: 'vType', // 超级订阅用户
  m: 'mType', // 大会员用户
  s: 'eType' // 商机管理用户
}

// isAdd字段以及推送url对应vSwitch对照
export const vtMap = {
  free: 'f',
  entniche: 's',
  ent: 's',
  svip: 'v',
  vip: 'v',
  bigmember: 'm',
  member: 'm'
}
