<template>
  <AntiPopup
    :value="value"
    @input="onInput"
    class="use-anti-popup-container"
    overlay-class="verify-points-overlay"
    content-class="verify-points-popup"
    transition-appear>
    <VerifyPoints
      ref="verifyPoints"
      @refresh="onRefresh"
      @confirm="onConfirm"
      :showLoginButton="showLoginButton"
      :loginButtonClick="loginButtonClick"
      :imgBase64="imgBase64"
      :textVerify="textVerify" />
  </AntiPopup>
</template>

<script>
import VerifyPoints from './VerifyPoints'
import AntiPopup from './AntiPopup'

export default {
  name: 'VerifyPointsPopup',
  components: {
    AntiPopup,
    VerifyPoints
  },
  props: {
    value: {
      value: Boolean,
      default: false
    },
    /**
     * 验证码图片base64
     */
    imgBase64: {
      type: String,
      default: ''
    },
    /**
     * 验证文字
     */
    textVerify: {
      type: String,
      default: ''
    },
    loginButtonClick: Function,
    showLoginButton: {
      type: Boolean,
      default: false
    },
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  methods: {
    onInput (e) {
      this.$emit('input', e)
    },
    onReset () {
      this.$refs.verifyPoints.onReset()
    },
    onRefresh () {
      this.$emit('refresh')
    },
    onConfirm (payload) {
      this.$emit('confirm', payload)
    }
  }
}
</script>
<style lang="scss" scoped>
// 卡片适配不同屏幕的网页
// 屏幕大于 1024px 或小于 1440px 时应用该样式
::v-deep {
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    .verify-points {
      width: 320Px;
    }
  }
  // 屏幕大于 1440px
  @media screen and (min-width: 1440px) {
    .verify-points {
      width: 340Px;
    }
  }
}
</style>
