export function threeObjToSingle(obj, split = ',') {
  const map = {
    area: '',
    city: '',
    district: ''
  }
  if (!obj)
    return map
  const area = []
  const city = []
  let district = []
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (Object.keys(obj[key]).length === 0) {
        area.push(key)
      }
      else {
        // 城市项
        const cityItem = obj[key]
        for (const cKey in cityItem) {
          // 区县项
          const districtItem = cityItem[cKey]
          if (Array.isArray(districtItem)) {
            if (districtItem.length === 0) {
              city.push(cKey)
            }
            else {
              const resetArr = districtItem.map((temp) => {
                return temp
              })
              district = district.concat(resetArr)
            }
          }
        }
      }
    }
  }
  return {
    first: area.join(split),
    second: city.join(split),
    third: district.join(split)
  }
}
