// 公共路由
export default [
  {
    path: '/order/create/:type',
    name: 'create-order',
    component: () => import('@/views/order/Create.vue'),
    redirect: (to) => {
      const { type } = to.params
      return `/order/create/${type}`
    },
    meta: {
      header: true,
      keepAlive: true,
      title: '创建订单'
    }
  },
  {
    path: '/order/pay/:state',
    name: 'order-pay-state',
    component: () => import('@/views/order/PayAfter.vue'),
    meta: {
      header: true,
      title: '支付完成'
    }
  },
  {
    path: '/selector/set_area',
    name: 'set-area',
    component: () => import('@/views/selector/AreaSelector.vue'),
    meta: {
      header: true,
      title: '区域'
    }
  },
  //  
  {
    path: '/vipsubscribeRights',
    name: 'vipsubscribeRights',
    component: () => import('@/views/common/vipsubscribeRights.vue'),
    meta: {
      header: true,
      title: '会员服务列表'
    }
  }
]
