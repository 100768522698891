import { ajaxMessageList } from '@/api/modules/message'
import { appSendMsgCount, appCallReloadTab } from '@/utils/callFn/appFn'
import { envs } from '@/utils/prototype/modules/platform'
export default {
  namespaced: true,
  state: () => ({
    timestamp: 0, // 时间戳，组件中监听这个变量，用来做轮询
    msgCount: 0 // 私信数量
    // letterCount: 0 // 私信数量
  }),
  mutations: {
    changeTimestamp(state, t = 0) {
      state.timestamp = t
    },
    changeMsgCount(state, unread = 0) {
      state.msgCount = unread
      if (envs.inApp) {
        // 改变app原生底部栏角标数
        appSendMsgCount(unread)
      }
    }
    // changeLetterCount (state, data = 0) {
    //   state.letterCount = data
    // }
  },
  actions: {
    // 这个action的默认传参返回的数据，只用来更新tabbar中message角标的数量
    async getMsgCountAction(
      { state, commit, dispatch },
      payload = {
        isColumn: false,
        isColumnNewMsg: false,
        isMsgList: false,
        isContainLetter: false,
        isRead: 0,
        offset: 1
      }
    ) {
      let { unread } = await ajaxMessageList(payload)
      if (!unread) {
        unread = 0
      }
      commit('changeMsgCount', unread)
    },
    // async getLetterCountAction ({ commit }, payload = { userType: 2 }) {
    //   const { error_code: code, count } = await ajaxGetPrivateLetter(payload)
    //   if (code === 0) {
    //     commit('changeLetterCount', count)
    //   }
    // },
    keepRequestAction({ commit, dispatch }) {
      const { inAndroid, inIOS } = envs
      let timer = null
      if (inAndroid || inIOS) {
        clearInterval(timer)
        timer = setInterval(() => {
          dispatch('getMsgCountAction')
          commit('changeTimestamp', Date.now())
        }, 300000)
      }
    }
  },
  getters: {
    // 获取消息数量
    getterMsgCount(state) {
      return state.msgCount || 0
    },
    // 获取私信数量
    getterLetterCount(state) {
      return state.letterCount || 0
    },
    // 获取底部导航角标总数：系统消息+私信
    allMsgCount(state) {
      return state.msgCount
    }
  }
}
