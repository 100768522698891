// 搜索接口参数表
export const leaveInfoMap = {
  article_original: {
    desc: '查看原文留资',
    autoLeave: true,
    fields: [
      {
        key: 'name',
        required: true
      },
      {
        key: 'phone',
        required: true
      },
      {
        key: 'company',
        required: true
      },
      {
        key: 'companyType',
        required: true
      },
      {
        key: 'branch',
        required: true
      },
      {
        // 职位和部门的选择是一个整体，此处只显示职位
        key: 'position',
        required: true
      }
    ]
  },
  article_collection: {
    desc: '标讯收藏留资',
    autoLeave: false,
    fields: [
      {
        key: 'name',
        required: true
      },
      {
        key: 'phone',
        required: true
      },
      {
        key: 'company',
        required: true
      },
      {
        key: 'companyType',
        required: true
      },
      {
        // 职位和部门的选择是一个整体，此处只显示职位
        key: 'position',
        required: true
      }
    ]
  },
  jypush_see10_plus_app: {
    desc: '订阅页面留资',
    autoLeave: true,
    fields: [
      {
        key: 'name',
        required: true
      },
      {
        key: 'phone',
        required: true
      },
      {
        key: 'company',
        required: true
      },
      {
        key: 'companyType',
        required: true
      },
      {
        // 职位和部门的选择是一个整体，此处只显示职位
        key: 'position',
        required: true
      }
    ]
  }
}
