import { copyText } from '@/utils'

/**
 * 客户端拨打电话
 * @param phone
 */
export function appCallPhone(phone) {
  try {
    JyObj.callPhone(phone)
  }
  catch (e) {
    console.warn('error: app call phone', e)
  }
}

export function appCallCopyText(text) {
  try {
    JyObj.wirteRight(text)
  }
  catch (e) {
    console.warn('error: app call copy text', e)
    copyText(text)
  }
}

/**
 * 客户端打开新窗口
 * @param link 目标链接
 * @param title 窗口标题
 */
export function appCallOpenWindow(link, title = '剑鱼标讯') {
  try {
    JyObj.openExternalLink(link, title)
  }
  catch (e) {
    console.warn('error: app call openExternalLink', e)
  }
}

/**
 * 切换底部导航
 * @param name 需要切换到的TabName, 对应关系 search：搜索 subscribe：订阅 box：百宝箱 me：我的 message：消息
 */
export function appCallChangeTab(name) {
  try {
    JyObj.skipAppointTab(name)
  }
  catch (e) {
    console.warn('error: app call skipAppointTab', e)
  }
}

/**
 * 刷新对应导航页面
 * @param name 需要切换到的TabName, 对应关系 search：搜索 subscribe：订阅 box：百宝箱 me：我的 message：消息
 */
export function appCallReloadTab(name, status = 1) {
  try {
    JyObj.refreshAppointTab(name, status)
  }
  catch (e) {
    console.warn('error: app call refreshAppointTab', e)
  }
}

/**
 * 返回对应导航页面
 * @param name 需要切换返回到的TabName
 *              3.0.5以及以前版本：H 首页， '' 当前 webview 顶级
 *              3.0.6以及以后版本：H 首页， '' 无操作
 */
export function appCallBackTab(name) {
  try {
    JyObj.backUrl(name)
  }
  catch (e) {
    console.warn('error: app call backUrl', e)
  }
}

/**
 * 隐藏显示底部菜单栏，3.0.6版本移除
 * @param type 0：隐藏；1：显示
 */
export function appCallHideTab(type) {
  try {
    JyObj.hiddenBottom(type)
  }
  catch (e) {
    console.warn('error: app call hiddenBottom', e)
  }
}

/**
 * app获取用户token方法
 */
export function appCallGetToken() {
  let result
  try {
    result = JyObj.getUserToken()
  }
  catch (e) {
    console.warn('error: app call getUserToken', e)
  }
  return result
}

/**
 * 保存图片到本地相册
 */

export function savePic(
  imgbase64,
  tip = '剑鱼标讯需要您的存储权限、电话权限，将用于帮助您下载、保存图片到本地，将内容成功分享到社交平台。'
) {
  try {
    window.__compatibleAppFn(JyObj.savePic, imgbase64, tip)
  }
  catch (e) {
    console.warn('error: app call savePic', e)
  }
}

// 查看开关状态 是否接受消息
export function checkNoticePermission() {
  let status
  try {
    status = JyObj.IosCall('checkNoticePermission')
  }
  catch (e) {
    console.warn('error: app call checkNoticePermission', e)
  }
  return status
}

// 打开接受消息开关
export function openSystemNotification() {
  try {
    JyObj.openSystemNotification()
  }
  catch (e) {
    console.warn('error: app call openSystemNotification', e)
  }
}

/**
 * 客户端版本号获取
 */
export function getAppVersion() {
  let version = ''
  try {
    version = JyObj.getVersion()
  }
  catch (e) {
    console.warn('error: get app version failed', e)
  }
  return version || ''
}

/**
 * APP独有 ios附件下载 、Android客户端暂无！
 * doc 、docx、excel 、xls 、 xlsxppt 、 pptx、 pdf、 txt、png 、PNG、jpg 、JPG 暂定这些为常见类型~支持在线预览+下载+转存
 * 其他类型仅支持下载+转存
 * @param filename 文件名称不带后缀
 * @param filetype 文件类型：doc word excel 等等
 * @param fileurl 文件链接
 * @param filesize 文件大小
 */
export function appDownLoadFile(filename, filetype, fileurl, filesize) {
  try {
    JyObj.downLoadFile(filename, filetype, fileurl, filesize)
  }
  catch (e) {
    console.warn('error: app ios download file failed', e)
  }
}

/**
 * 隐藏小红点，3.0.6版本移除
 * @param {string} menu [搜索:search 订阅:subscribe 百宝箱:box 我的:me 消息:message]
 */
export function appHideRedSpotOnMenu(menu) {
  if (menu === 'me') {
    menu = 'my'
  }
  try {
    JyObj.hideRedSpotOnMenu(menu)
  }
  catch (e) {
    console.warn('error: app hideRedSpotOnMenu failed', e)
  }
}

/**
 * 底部栏消息角标数量，3.0.6版本移除
 * @param {string} num
 */
export function appSendMsgCount(num) {
  try {
    JyObj.sendMsgCount(num)
  }
  catch (e) {
    console.warn('error: app sendMsgCount failed', e)
  }
}

/**
 * 直接打开微信里的扫码功能，3.0.6版本新增
 */
export function appOpenWeChartScan() {
  try {
    JyObj.openWeChartScan()
  }
  catch (e) {
    console.warn('error: app openWeChartScan failed', e)
  }
}

/**
 * 用来清除webview浏览历史记录，3.0.6版本新增
 * 此方法在ios的单页面程序中使用会清除当前页面的历史，造成前进后无法后退。
 * 所以ios谨慎使用此方法。可使用以下方案替代：(sideslipClose+不提供返回按钮)
 */
export function appClearHistory() {
  try {
    JyObj.clearHistory()
  }
  catch (e) {
    console.warn('error: app clearHistory failed', e)
  }
}

/**
 * ios开启侧滑(ios专用，安卓调用无效)，3.0.6版本新增
 */
export function appSideslipOpen() {
  try {
    JyObj.sideslipOpen()
  }
  catch (e) {
    console.warn('error: app sideslipOpen failed', e)
  }
}
/**
 * ios关闭侧滑(ios专用，安卓调用无效)，3.0.6版本新增
 */
export function appSideslipClose() {
  try {
    JyObj.sideslipClose()
  }
  catch (e) {
    console.warn('error: app sideslipClose failed', e)
  }
}

/**
 * app分享
 * shareType: 1:微信 2:QQ 3:朋友圈
 * title: 分享标题
 * content: 分享内容
 * link: 分享链接
 * authTip: 授权提示文案
 */
export function appShare(
  shareType,
  title,
  content,
  link,
  authTip = '剑鱼标讯需申请存储权限、电话权限，以便您能顺利参与分享有礼活动，将招标信息、文库内容成功分享到社交平台。'
) {
  try {
    if (
      window.__checkAppVersionCanRunTips
      && window.__checkAppVersionCanRunTips()
    ) {
      JyObj.share(shareType, title, content, link, authTip)
    }
    else {
      JyObj.share(shareType, title, content, link)
    }
  }
  catch (e) {
    console.warn('error: app call share', e)
  }
}

// 一键绑定、登录
export function appGetPhoneBind() {
  try {
    JyObj.getPhoneBind()
  }
  catch (e) {
    console.warn('error: app call getPhoneBind', e)
  }
}

// 获取极光推送id
export function appGetPushRid() {
  try {
    JyObj.getPushRid()
  }
  catch (e) {
    console.warn('error: app call getPushRid', e)
  }
}

// 获取推送id
export function appGetOtherPushId() {
  try {
    JyObj.getOtherPushId()
  }
  catch (e) {
    console.warn('error: app call getOtherPushId', e)
  }
}

// 获取手机型号
export function appGetPhoneType() {
  try {
    JyObj.getPhoneType()
  }
  catch (e) {
    console.warn('error: app call getPhoneType', e)
  }
}

// 渠道
export function appGetChannel() {
  try {
    JyObj.getChannel()
  }
  catch (e) {
    console.warn('error: app call getChannel', e)
  }
}

// 设备id
export function appGetDeviceId() {
  try {
    JyObj.getDeviceId()
  }
  catch (e) {
    console.warn('error: app call getDeviceId', e)
  }
}
