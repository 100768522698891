/**
 * 中转搜索页路由配置
 */
const MiddleSearch = {
  path: 'middle',
  component: () => import('@/views/search/middle/layout.vue'),
  children: [
    {
      path: '',
      redirect: 'bidding'
    },
    {
      path: 'bidding',
      name: 'search-middle-bidding',
      alias: ['/jylab/supsearch/index.html'],
      component: () => import('@/views/search/middle/bidding/index.vue'),
      meta: {
        title: '招标采购搜索'
      }
    },
    // 运营商定制搜索
    {
      path: 'bidding-custom',
      name: 'search-middle-bidding-custom',
      component: () => import('@/views/search/middle/bidding-custom/index.vue'),
      meta: {
        title: '招标采购搜索'
      }
    },
    {
      path: 'company',
      name: 'search-middle-company',
      alias: ['/jylab/entSearch/index.html'],
      component: () => import('@/views/search/middle/company/index.vue'),
      meta: {
        title: '企业搜索'
      }
    },
    {
      path: 'buyer',
      name: 'search-middle-buyer',
      alias: ['/jylab/purSearch/index.html'],
      component: () => import('@/views/search/middle/buyer/index.vue'),
      meta: {
        title: '采购单位搜索'
      }
    },
    {
      path: 'sun',
      name: 'search-middle-sun',
      component: () => import('@/views/search/middle/sun/index.vue'),
      meta: {
        title: '阳光直采'
      }
    },
    {
      path: 'supplier',
      name: 'search-middle-supplier',
      alias: ['/swordfish/page_big_pc/search/supply'],
      component: () => import('@/views/search/middle/supplier/index.vue'),
      meta: {
        title: '供应搜索'
      }
    },
    {
      path: 'docs',
      name: 'search-middle-docs',
      alias: ['/swordfish/docs/search'],
      component: () => import('@/views/search/middle/docs/index.vue'),
      meta: {
        title: '文档搜索'
      }
    }
  ]
}

/**
 * 结果搜索页路由配置
 */
const ResultSearch = {
  path: 'result',
  component: () => import('@/views/search/result/layout.vue'),
  children: [
    {
      path: '',
      redirect: 'bidding'
    },
    {
      path: 'bidding',
      name: 'search-bidding',
      component: () => import('@/views/search/result/bidding/index.vue'),
      meta: {
        title: '招标采购搜索'
      }
    },
    /**
     * 山川应用嵌入搜索页
     */
    {
      path: 'bi-bidding',
      name: 'search-bi-bidding',
      component: () => import('@/views/search/result/bidding/index.vue'),
      meta: {
        title: '招标采购搜索'
      }
    },
    // 运营商定制搜索
    {
      path: 'bidding-custom',
      name: 'search-bidding-custom',
      component: () => import('@/views/search/result/bidding-custom/index.vue'),
      meta: {
        title: '招标采购搜索'
      }
    },
    {
      path: 'sun',
      name: 'search-sun',
      component: () => import('@/views/search/result/sun/index.vue'),
      meta: {
        title: '阳光直采'
      }
    },
    {
      path: 'company',
      name: 'search-company',
      component: () => import('@/views/search/result/company/index.vue'),
      meta: {
        title: '企业搜索'
      }
    },
    {
      path: 'buyer',
      name: 'search-buyer',
      component: () => import('@/views/search/result/buyer/index.vue'),
      meta: {
        title: '采购单位搜索'
      }
    },
    {
      path: 'supplier',
      name: 'search-supplier',
      component: () => import('@/views/search/result/supplier/index.vue'),
      meta: {
        title: '供应搜索'
      }
    },
    {
      path: 'winner',
      name: 'search-winner',
      component: () => import('@/views/search/result/winner/index.vue'),
      meta: {
        title: '中标企业搜索'
      }
    }
  ]
}

/**
 * 搜索主路由配置
 */
export default [
  {
    path: '',
    component: () => import('@/views/search/layout.vue'),
    children: [
      {
        path: '',
        redirect: 'middle'
      },
      MiddleSearch,
      ResultSearch
    ]
  },
  {
    path: '/filter-history/bidding',
    name: 'filter-history',
    component: () => import('@/views/search/filter-history/bidding.vue'),
    meta: {
      header: true,
      title: '已存筛选条件'
    }
  }
]
