// 静态路由
export default [
  {
    path: '/rankingDetail',
    name: 'rankingDetail',
    component: () => import('@/views/reportAnalysis/rankingDetail.vue'),
    meta: {
      header: true,
      title: '市场分析定制报告'
    }
  },
  {
    path: '/reportDownload',
    name: 'reportDownload',
    component: () => import('@/views/reportAnalysis/reportDownload.vue'),
    meta: {
      header: true,
      title: '报告下载记录'
    }
  },
  {
    path: '/reportdownloadDetails',
    name: 'reportdownloadDetails',
    component: () => import('@/views/reportAnalysis/reportdownloadDetails.vue'),
    meta: {
      header: true,
      title: '分析定制报告下载余额明细'
    }
  }
]
