import { useRequest } from '../../../api'
export function ajaxFollowClientInfo(data) {
  return useRequest({
    url: '/publicapply/customer/check',
    method: 'post',
    data: data
  })
}

export function ajaxFollowClientChange(data) {
  return useRequest({
    url: '/publicapply/customer/attention',
    method: 'post',
    data
  })
}

