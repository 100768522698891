import dayjs from 'dayjs'
import { InfoTypeTransform } from './info-type-transform'
import chinaMapJSON from '@/assets/js/china_area'
import {
  biddingSearchConcat,
  biddingSearchFileExists,
  biddingSearchScope,
  biddingSearchTime,
  buyerClassListMapExp,
  industryListMapExp,
  infoTypeAdvancedList,
  infoTypeListExp,
  infoTypeNotAdvancedList,
  searchModeList,
  wordsModeList
} from '@/data'
import { dateFormatter } from '@/utils'

/**
 * 整理已存筛选数据，去除空数据
 * 需要和 FilterHistoryAjaxModel2ViewModel.formatAll 配合使用
 */
export function filterHistoryNotEmptyFormat(formatted) {
  const {
    searchGroup,
    scopeText,
    industryText,
    AreaCityText,
    priceText,
    publishTimeText,
    buyerClassText,
    winnerConcatText,
    buyerConcatText,
    fileExists,
    notKey,
    buyerList,
    winnerList,
    agencyList
  } = formatted
  let { infoTypeText } = formatted

  const formattedList = []
  if (Array.isArray(scopeText) && scopeText.length) {
    formattedList.push({
      label: '搜索范围：',
      text: scopeText.join('/')
    })
  }
  if (Array.isArray(industryText) && industryText.length) {
    formattedList.push({
      label: '行业：',
      text: industryText.join('/')
    })
  }
  if (Array.isArray(AreaCityText) && AreaCityText.length) {
    formattedList.push({
      label: '地区：',
      text: AreaCityText.join('/')
    })
  }
  if (priceText) {
    formattedList.push({
      label: '金额：',
      text: priceText
    })
  }
  if (publishTimeText) {
    formattedList.push({
      label: '时间：',
      text: publishTimeText
    })
  }

  /**
   * searchGroup === '1' 表示为非超前项目
   * searchGroup === '2' 表示为超前项目
   * 如果此时信息类型为空。则显示其所有类型的文字，而非"全部"两个字
   */
  const infoTypeAll
    = !infoTypeText || (Array.isArray(infoTypeText) && infoTypeText.length === 0)
  if (infoTypeAll) {
    if (searchGroup === '1') {
      // 非超前项目
      infoTypeText = infoTypeNotAdvancedList
        .map(i => i.value)
        .filter(i => !!i)
    }
    else if (searchGroup === '2') {
      // 超前项目
      infoTypeText = infoTypeAdvancedList
        .map((i) => {
          if (i && i.value && i.value.includes('拟建')) {
            return i.name
          }
          else {
            return i.value
          }
        })
        .filter(i => !!i)
    }
  }
  if (Array.isArray(infoTypeText) && infoTypeText.length) {
    formattedList.push({
      label: '信息类型：',
      text: infoTypeText.join('/')
    })
  }
  if (fileExists && Array.isArray(fileExists)) {
    // 附件选择全部时候，则不显示
    const fileState = fileExists.join('')
    if (fileState !== '0') {
      const textInfo = biddingSearchFileExists.find((item) => {
        return fileState === item.key
      })
      if (textInfo) {
        formattedList.push({
          label: '附件：',
          text: textInfo.label
        })
      }
    }
  }
  if (buyerClassText) {
    formattedList.push({
      label: '采购单位类型：',
      text: buyerClassText.join('/')
    })
  }
  if (winnerConcatText) {
    formattedList.push({
      label: '中标企业联系方式：',
      text: winnerConcatText.join('/')
    })
  }
  if (buyerConcatText) {
    formattedList.push({
      label: '采购单位联系方式：',
      text: buyerConcatText.join('/')
    })
  }
  if (notKey) {
    formattedList.push({
      label: '排除词：',
      text: notKey.split(',').join(' ')
    })
  }
  if (buyerList) {
    formattedList.push({
      label: '采购单位：',
      text: buyerList.split(',').join(' ')
    })
  }
  if (winnerList) {
    formattedList.push({
      label: '中标企业：',
      text: winnerList.split(',').join(' ')
    })
  }
  if (agencyList) {
    formattedList.push({
      label: '代理机构：',
      text: agencyList.split(',').join(' ')
    })
  }
  return formattedList
}

/**
 * 接口中的数据转前端标准数据
 */
export class FilterHistoryAjaxModel2ViewModel {
  static formatAll(map) {
    // wordsMode
    const { wordsMode, wordsModeText } = this.formatWordsMode(map.wordsMode)
    // 精准匹配/模糊匹配
    const { searchMode, searchModeText } = this.formatSearchMode(map.searchMode)
    // 搜索范围整理
    const { scope, scopeText } = this.formatScope(map.scope)
    // 行业整理
    const { industry, industryText } = this.formatIndustry(map.industry)
    // 地区整理
    const { area, AreaCityText } = this.formatAreaCity(
      map.area,
      map.city,
      map.district,
      map.regionMap
    )
    // 金额筛选整理
    const price = this.formatPrice(map.price)
    // 时间筛选整理
    const { publishTime, publishTimeText } = this.formatTime(map.publishtime)
    // 信息类型
    const { infoType, infoTypeText } = this.formatInfoType(map.infotype)
    // 采购单位
    const { buyerClass, buyerClassText } = this.formatBuyerClass(map.buyerclass)
    // 联系方式
    const { basicData: buyerConcat, basicDataText: buyerConcatText }
      = this.formatContact(map.buyertel)
    const { basicData: winnerConcat, basicDataText: winnerConcatText }
      = this.formatContact(map.winnertel)

    const formatted = {
      keywords: map.searchvalue ? map.searchvalue.split(',') : [],
      additionalWords: map.additionalWords
        ? map.additionalWords.split(',')
        : [],
      wordsMode,
      wordsModeText,
      searchMode,
      searchModeText,
      searchGroup:
        map.searchGroup !== undefined ? String(map.searchGroup) : '0',
      scope,
      scopeText,
      industry,
      industryText,
      area,
      AreaCityText,
      price,
      dateTime: publishTime, // 标讯搜索恢复数据可能要用到?
      publishTime,
      publishTimeText,
      infoType,
      infoTypeText,
      buyerClass,
      buyerClassText,
      buyerConcat,
      buyerConcatText,
      winnerConcat,
      winnerConcatText
    }

    // 删去undefined/null的项
    for (const key in formatted) {
      if (!formatted[key]) {
        delete formatted[key]
      }
    }
    return formatted
  }

  static mapToList(map) {
    let list = []
    if (!map)
      return list
    for (const key in map) {
      if (Array.isArray(map[key])) {
        list = list.concat(map[key])
      }
    }
    return list
  }

  /**
   * 关键词匹配方式wordsModeList整理
   * (输出包含文字描述和选择器使用的数据结构)
   * @param String m '0'/'1'
   * @returns Object
   */
  static formatWordsMode(m) {
    const result = {
      wordsMode: undefined,
      wordsModeText: undefined
    }
    if (!m) {
      m = '0'
    }
    m = String(m)
    const target = wordsModeList.find(item => m === item.key)
    if (target) {
      result.wordsMode = [target.key]
      result.wordsModeText = target.label
    }
    return result
  }

  /**
   * 搜索方式整理 精准匹配/模糊匹配
   * @param String m '0'/'1'
   * @returns Object
   */
  static formatSearchMode(m) {
    const result = {
      searchMode: undefined,
      searchModeText: undefined
    }
    // m为空默认为0
    if (!m) {
      m = '0'
    }
    m = String(m)
    const target = searchModeList.find(item => m === item.key)
    if (target) {
      result.searchMode = [target.key]
      result.searchModeText = target.label
    }
    return result
  }

  /**
   * 搜索范围整理
   * @param String val
   * @returns Object
   *
   * 参数val示例：'content,title,ppa'
   */
  static formatScope(val) {
    const result = {
      scope: undefined,
      scopeText: undefined
    }
    if (!val)
      return result
    const map = {}
    biddingSearchScope.forEach((item) => {
      map[item.key] = item.label
    })
    // 将字符转换为中文
    const selectKeyArr = val.split(',')
    const selectKeyTextArr = selectKeyArr
      .map((key) => {
        return map[key]
      })
      .filter(key => !!key)

    result.scope = selectKeyArr
    result.scopeText = selectKeyTextArr
    return result
  }

  /**
   * 行业整理
   * @param String val
   * @returns Object
   *
   * 参数val示例：'机械设备_工程机械,机械设备_车辆,机械设备_其他机械设备,行政办公_通用办公设备,行政办公_办公家具'
   */
  static formatIndustry(val) {
    const result = {
      industry: undefined,
      industryText: undefined
    }
    if (!val || val === '全部')
      return result

    const industry = {}
    let industryText = []

    // 整理行业
    val.split(',').forEach((v) => {
      const vSplit = v.split('_')
      const industryChildren = industry[vSplit[0]]
      if (Array.isArray(industryChildren)) {
        industryChildren.push(vSplit[1])
      }
      else {
        industry[vSplit[0]] = vSplit[1] ? [vSplit[1]] : [vSplit[0]]
      }
    })
    if (Object.keys(industry).length) {
      result.industry = industry
    }

    // 整理行业text
    // 统计完整行业数量
    const calcChildrenCount = {}
    for (const key in industryListMapExp) {
      calcChildrenCount[key] = industryListMapExp[key].length
    }
    // 如果行业数据为全部，则只显示一级行业，否则需要显示二级行业
    for (const key in industry) {
      if (industry[key].length === calcChildrenCount[key]) {
        industryText.push(key)
      }
      else {
        // P510延申需求：因数据源二级分类都加上的“其他”，为了做区分，如果二级分类中有“其他” 显示的时候要拼上一级分类, 没有“其他”的还按原来需求只显示二级分类
        const industryArr = industry[key].map((item) => {
          if (key === '其他') {
            return key
          }
          if (item === '其他') {
            return `${key}_${item}`
          }
          else {
            return item
          }
        })
        industryText = [...industryText, ...industryArr]
      }
    }
    if (industryText.length) {
      result.industryText = industryText
    }
    return result
  }

  /**
   * 三级地区整理
   * @param String area
   * @param String city
   * @param String district
   *
   * @returns Object
   *
   * 参数area示例：'北京,河南'
   * 参数city示例：'福州市,邯郸市,石家庄市,秦皇岛市'
   * 参数district示例：'郑州市_金水区，北京市_朝阳区'
   */
  static formatAreaCity(area, city, district, regionMap) {
    const result = {
      area: undefined,
      AreaCityText: undefined
    }
    if (regionMap && typeof regionMap === 'object') {
      const text = this.getAreaCityText(regionMap)
      result.area = regionMap
      result.AreaCityText = text ? text.split(',') : []
      return result
    }
    // 兼容老的数据整理
    let text = ''
    if (area && city) {
      text = `${area},${city}`
    }
    else {
      text = area || city
    }
    if (district) {
      text += text ? `,${district}` : `${district}`
    }
    let reginArr = []
    if (text) {
      reginArr = text.split(',')
    }
    // 用area、city\district整理出前端组件通用格式的数据结构
    const region = {}
    reginArr.forEach((regItem) => {
      chinaMapJSON.forEach((p) => {
        const pName = p.shortName
        if (pName.includes(regItem)) {
          // 省级区域
          region[regItem] = {}
        }
        else {
          p.city.forEach((c) => {
            const cName = c.name
            // 市级区域 示例：{ 郑州市：[] }
            if (cName.includes(regItem)) {
              if (!region[pName]) {
                region[pName] = {}
              }
              region[pName][regItem] = []
            }
            else {
              // 区县 示例：郑州市:['金水区']
              const districtArr = []
              if (c.area) {
                const district = regItem.includes('_')
                  ? regItem.split('_')[1]
                  : regItem
                c.area.forEach((dis) => {
                  if (dis.includes(district)) {
                    districtArr.push(district)
                  }
                })
              }
              if (districtArr.length > 0) {
                if (!region[pName]) {
                  region[pName] = {}
                }
                const city = regItem.includes('_')
                  ? regItem.split('_')[0]
                  : regItem
                if (!region[pName][city]) {
                  region[pName][city] = []
                }
                region[pName][city] = region[pName][city].concat(districtArr)
              }
            }
          })
        }
      })
    })
    if (Object.keys(region)) {
      result.area = region
    }
    if (reginArr.length) {
      result.AreaCityText = reginArr
    }

    return result
  }

  static getAreaCityText(regionMap) {
    const { area, city, district } = areaObjToSingle(regionMap)
    let text = ''
    if (area && city) {
      text = `${area},${city}`
    }
    else {
      text = area || city
    }
    if (district) {
      const dArr = district.split(',') || []
      const dItem = dArr
        .map((temp) => {
          return temp.includes('_') ? temp.split('_')[1] : temp
        })
        .join('/')
      text += text ? `,${dItem}` : `${dItem}`
    }
    return text
  }

  /**
   * 金额整理1
   * @param String '1111-22222'
   * @returns String
   */
  static formatPrice(price, split = '-') {
    if (!price) {
      return {
        start: '',
        end: ''
      }
    }
    const arr = price.split(split)
    return {
      start: arr[0],
      end: arr[1]
    }
  }

  static formatPriceText(start, end, unit = '万元') {
    let priceText = ''
    if (start && end) {
      priceText = `${start}-${end}${unit}`
    }
    else if (start && !end) {
      priceText = `${start}${unit}以上`
    }
    else if (!start && end) {
      priceText = `${end}${unit}以下`
    }
    return priceText
  }

  /**
   * 时间整理
   * @param String val
   * @returns Object
   *
   * 参数val示例：'1653321600_1654012800'
   * 参数val示例：'fiveyear'
   */
  static formatTime(val) {
    const result = {
      publishTime: undefined,
      publishTimeText: undefined
    }
    if (!val)
      return result
    const map = {}
    biddingSearchTime.forEach((item) => {
      map[item.key] = item.label
    })
    const t = {
      start: 0,
      end: 0,
      exact: 'exact'
    }
    // 如果是精确时间
    if (val.includes('_')) {
      const split = val.split('_')
      const start = split[0] * 1000
      const end = split[1] * 1000
      const textArr = []
      if (start && !Number.isNaN(start)) {
        t.start = start
        textArr[0] = dateFormatter(start, 'yyyy/MM/dd')
      }
      if (end && !Number.isNaN(end)) {
        t.end = end
        textArr[1] = dateFormatter(end, 'yyyy/MM/dd')
      }

      let publishTimeText = ''
      if (textArr[0] && textArr[1]) {
        publishTimeText = textArr.join('-')
      }
      else if (textArr[0] && !textArr[1]) {
        publishTimeText = `${textArr[0]}以后`
      }
      else if (!textArr[0] && textArr[1]) {
        publishTimeText = `${textArr[1]}以前`
      }
      // 计算text
      result.publishTimeText = publishTimeText
    }
    else {
      t.exact = val
      result.publishTimeText = map[val] || ''
    }
    result.publishTime = t
    return result
  }

  /**
   * 信息类型整理
   * @param String val
   * @returns Object
   *
   * 参数val示例：'采购意向,中标,成交,废标,流标'
   */
  static formatInfoType(val) {
    const result = {
      infoType: undefined,
      infoTypeText: undefined
    }
    if (!val || val === '全部')
      return result
    const arr = val.split(',').map((v) => {
      // 把<拟建>替换成<拟建项目>
      if (v === '拟建') {
        return '拟建项目'
      }
      else {
        return v
      }
    })
    const obj = InfoTypeTransform.formatListToMap(arr)
    result.infoType = InfoTypeTransform.mapToList(obj)

    const map = InfoTypeTransform.listToMap(result.infoType)
    result.infoTypeText = InfoTypeTransform.formatMapToList(map)
    return {
      infoType: result.infoType, // 恢复组件的数组内容
      infoTypeText: result.infoTypeText // 文字
    }
  }

  /**
   * 采购单位类型整理
   * @param String val
   * @returns Object
   *
   * 参数val示例：'住建,传媒,建筑业,能源化工,批发零售,信息技术,运输物流,制造业,住宿餐饮'
   */
  static formatBuyerClass(val) {
    const result = {
      buyerClass: undefined,
      buyerClassText: undefined
    }
    if (!val || val === '全部')
      return result
    result.buyerClass = val.split(',')

    // 逻辑和行业类似，如果二级全选，则展示一级分类文字，否则展示二级分类文字
    // 1. 把选中的整理成原始数据结构
    const keyList = []
    const keyListMap = {}
    for (const key in buyerClassListMapExp) {
      keyList.push(key)
      if (val.includes(key)) {
        // 存在
        if (Array.isArray(keyListMap[key])) {
          keyListMap[key] = []
        }
      }
      if (Array.isArray(buyerClassListMapExp[key])) {
        // 循环二级子项
        buyerClassListMapExp[key].forEach((item) => {
          if (val.includes(item)) {
            // 存在
            if (Array.isArray(keyListMap[key])) {
              keyListMap[key].push(item)
            }
            else {
              keyListMap[key] = [item]
            }
          }
        })
      }
    }
    // 判断是否某一项全选了
    let buyerClassText = []
    for (const key in keyListMap) {
      if (keyListMap[key].length === buyerClassListMapExp[key].length) {
        buyerClassText.push(key)
      }
      else {
        buyerClassText = [...buyerClassText, ...keyListMap[key]]
      }
    }
    if (buyerClassText.length) {
      result.buyerClassText = buyerClassText
    }
    return {
      buyerClass: result.buyerClass,
      buyerClassText: result.buyerClassText,
      keyListMap
    }
  }

  static formatContact(val = '') {
    const result = {
      basicData: undefined,
      basicDataText: undefined
    }
    const map = {}
    biddingSearchConcat.forEach((item) => {
      map[item.key] = item.label
    })

    if (val) {
      result.basicData = [val]
      result.basicDataText = [map[val]]
    }
    return result
  }
}

/**
 * 前端标准数据转接口中的数据
 */
export class FilterHistoryViewModel2AjaxModel {
  static formatAll(map) {
    // 搜索范围整理
    const scope = this.formatScope(map.scope)
    // 行业整理
    const industry = this.formatIndustry(map.industry)
    // 地区整理
    const { area, city } = this.formatAreaCity(map.area)
    // 金额筛选整理
    const price = this.formatPrice(map.price)
    // 发布时间筛选整理
    const publishTime = this.formatTime(map.publishTime)
    // 收藏时间筛选整理
    const selectTime = this.formatTime(map.selectTime)
    // 信息类型
    const infoType = this.formatInfoType(map.infotype)
    // 采购单位
    const { buyerClass } = this.formatBuyerClass(map.buyerclass)

    const formatted = {
      scope,
      industry,
      area,
      city,
      price,
      dateTime: publishTime, // 标讯搜索恢复数据可能要用到?
      publishTime,
      selectTime,
      infoType,
      buyerClass
    }

    // 删去undefined/null的项
    for (const key in formatted) {
      if (!formatted[key]) {
        delete formatted[key]
      }
    }

    return formatted
  }

  /**
   * 搜索范围整理
   * @param String val
   * @returns Object
   *
   * 参数val示例：[content, title ,ppa]
   */
  static formatScope(val = [], split = ',') {
    if (!Array.isArray(val))
      return ''
    return val.join(split)
  }

  /**
   * 行业整理
   * @param String val
   * @returns Object
   *
   * 参数val示例：
   * {
   *    机械设备: ['工程机械', '车辆', '其他机械设备', '办公家具']
   * }
   */
  static formatIndustry(val = {}, split = ',') {
    let industry = ''
    if (!val || Object.keys(val).length === 0)
      return industry

    const industryArr = []

    for (const key in val) {
      if (Array.isArray(val[key])) {
        val[key].forEach((item) => {
          // 如果选择一级分类其他，则传入一级分类‘其他’
          if (item === '其他' && key === '其他') {
            industryArr.push(`${key}`)
          }
          else {
            industryArr.push(`${key}_${item}`)
          }
        })
      }
    }

    industry = industryArr.join(split)

    return industry
  }

  /**
   * 处理筛选条件二级类（行业）其他， 查询参数有其他时，提交时需要再增加一个‘其它’
   * '建筑工程,水利水电_其他,信息技术_其他,机械设备_其他设备'
   */
  static formatFilterApiOther(str) {
    if (!str)
      return
    const arr = str.split(',')
    const newArr = arr.map((item) => {
      if (item.includes('_其他')) {
        item = `${item},${item.replace(/_其他(.*)$/, '_其它')}`
      }
      return item
    })
    return newArr.toString()
  }

  /**
   * 地区整理
   * @param String area
   * @param String city
   * @returns Object
   *
   * 参数area示例
   * {
   *    北京: [],
   *    河南: ['郑州', '许昌']
   * }
   */
  // static formatAreaCity (p = {}, split = ',') {
  //   const map = {
  //     area: '',
  //     city: ''
  //   }
  //   if (!p) return map
  //   const area = []
  //   let city = []
  //   for (const key in p) {
  //     if (Array.isArray(p[key])) {
  //       if (p[key].length === 0) {
  //         area.push(key)
  //       } else {
  //         city = city.concat(p[key])
  //       }
  //     }
  //   }
  //   return {
  //     area: area.join(split),
  //     city: city.join(split)
  //   }
  // }

  /**
   * 三级地级市地区整理
   * @param String area
   * @param String city
   * @returns Object
   *
   * 参数area示例
   * {
   *    北京: {
   *      朝阳区: [],
   *      东城区: []
   *    },
   *    河南: {
   *      南阳市: [],
   *      郑州: ['金水区'],
   *      洛阳市: ['栾川县']
   *    },
   *    澳门: {}
   * }
   */
  static formatAreaCity(p = {}, split = ',') {
    return areaObjToSingle(p, split)
  }

  /**
   * 金额整理
   * @returns String
   */
  static formatPrice(price = { start: '', end: '' }, split = '-') {
    const { start, end } = price
    if (start || end) {
      return [start || '', end || ''].join(split)
    }
    else {
      return ''
    }
  }

  /**
   * 时间整理
   * @param String val
   * @returns Object
   *
   * 参数time: 时间选择器选择结果
   * {
   *    start: '',
   *    end: '',
   *    exact: 'exact'
   * }
   *
   * exact: 是否只输出精确结果
   * split: 精确结果的分隔符
   */
  static formatTime(time, exact = false, split = '_') {
    let sortedTime = ''
    if (!time)
      return sortedTime
    const { start, end } = time
    if (exact || time.exact === 'exact') {
      const startVal = start ? dayjs(start).unix() : ''
      const endVal = end ? dayjs(end).unix() : ''
      sortedTime = [startVal, endVal].join(split)
      if (startVal || endVal) {
        return [startVal, endVal].join(split)
      }
      else {
        return [].join(split)
      }
    }
    else {
      sortedTime = time.exact || ''
    }
    return sortedTime
  }

  /**
   * 信息类型整理
   * @param String val
   * @returns Object
   *
   * 参数val示例：{}
   */
  static formatInfoType(infoType, split = ',') {
    let arr = []
    if (!infoType)
      return arr.join(split)
    for (const key in infoType) {
      arr = arr.concat(infoType[key])
    }
    return arr.join(split)
  }

  /**
   * 采购单位类型整理
   * @param String val
   * @returns Object
   *
   * 参数val示例：{}
   */
  static formatBuyerClass(val, split = ',') {
    let buyerClass = ''
    if (!val || Object.keys(val).length === 0)
      return buyerClass

    buyerClass = []

    for (const key in val) {
      if (Array.isArray(val[key])) {
        val[key].forEach((item) => {
          buyerClass.push(item)
        })
      }
    }

    buyerClass = buyerClass.join(split)

    return buyerClass
  }
}

/**
 * 三级地区对象转换成，单个area、city、district
 */
function areaObjToSingle(obj, split = ',') {
  const map = {
    area: '',
    city: '',
    district: ''
  }
  if (!obj)
    return map
  const area = []
  const city = []
  let district = []
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (Object.keys(obj[key]).length === 0) {
        area.push(key)
      }
      else {
        // 城市项
        const cityItem = obj[key]
        for (const cKey in cityItem) {
          // 区县项
          const districtItem = cityItem[cKey]
          if (Array.isArray(districtItem)) {
            if (districtItem.length === 0) {
              city.push(cKey)
            }
            else {
              const resetArr = districtItem.map((temp) => {
                return `${cKey}_${temp}`
              })
              district = district.concat(resetArr)
            }
          }
        }
      }
    }
  }
  return {
    area: area.join(split),
    city: city.join(split),
    district: district.join(split)
  }
}

/**
 * 信息类型比对是否全选
 * selectedStr 选中的信息类型（有一级分类、二级分类） '拟建/招标预告/招标公告/招标结果/招标信用信息'
 * sourceList 信息类型数据源 默认是infoTypeListExp包含所有的信息类型
 * separator 分隔符
 */
export function deepCompareInfoType(
  selectedStr = '',
  sourceList = [],
  separator = '/'
) {
  if (sourceList.length === 0) {
    sourceList = infoTypeListExp
  }
  const excludeAll = sourceList.filter(v => v.name !== '全部')
  const selectedArr = selectedStr.split(separator)

  excludeAll.forEach((v) => {
    v.selected = false

    selectedArr.forEach((s) => {
      if (v.name === s) {
        v.selected = true
      }
    })
  })
  const checkedStateArr = excludeAll.map(v => v.selected)
  const isAllChecked = checkedStateArr.every(v => v)
  return isAllChecked
}
