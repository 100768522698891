// 数据超市路由
export default [
  {
    path: '/index',
    name: 'EntGroupIndex',
    component: () => import('@/views/entgroup/index.vue'),
    meta: {
      header: true,
      title: '企业分组管理'
    }
  },
  {
    path: '/detail',
    name: 'EntGroupDetail',
    component: () => import('@/views/entgroup/detail.vue'),
    meta: {
      header: true,
      title: '编辑分组'
    }
  }
]
