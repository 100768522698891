export default [
  {
    path: '/data-pack/index',
    name: 'dataPackIndex',
    component: () => import('@/views/packs/DataPackIndex.vue'),
    meta: {
      header: true,
      title: '我的数据流量包'
    }
  },
  {
    path: '/data-pack/record',
    name: 'dataPackRecord',
    component: () => import('@/views/packs/DataExportRecord.vue'),
    meta: {
      header: true,
      title: '数据导出记录'
    }
  }
]
