// 其他全局常量

// 客服电话
export const customerServiceTel = '400-108-6670'

// 用于存储的key, 用于判断是否访问过引导页
export const GUIDE_CACHE_KEY = 'READ_GUIDE'

// 标讯搜索-上次搜索筛选项缓存key
export const BIDDING_SEARCH_LAST_FILTERS_CACHE_KEY
  = 'bidding-search-last-filters'
// 标讯搜索-searchGroup筛选项缓存key
export const BIDDING_SEARCH_GROUP_LAST_CACHE_KEY = 'bidding-search-group-last'

// 标讯搜索运营商定制-上次搜索筛选项缓存key
export const BIDDING_SEARCH_CUSTOM_LAST_FILTERS_CACHE_KEY
  = 'bidding-search-custom-last-filters'
// 标讯搜索运营商定制-searchGroup筛选项缓存key
export const BIDDING_SEARCH_CUSTOM_GROUP_LAST_CACHE_KEY
  = 'bidding-search-custom-group-last'

// 标讯搜索-上次搜索筛选项缓存key
export const SUN_SEARCH_LAST_FILTERS_CACHE_KEY = 'sun-search-last-filters'
// 标讯搜索-searchGroup筛选项缓存key
export const SUN_SEARCH_GROUP_LAST_CACHE_KEY = 'sun-search-group-last'
