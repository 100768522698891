import dayjs from 'dayjs'

/**
 * 本地存储扩展工具
 * @param options 参数，所有对外函数均可接受该参数，实现局部覆盖
 * @param options.prefix 全局Key前缀
 * @param options.loginPrefix - [-login-clear-] 全局特殊登录标识Key前缀
 * @param options.login - [false] 是否添加限定登录清除标识
 */
class Storage {
  constructor(options = {}) {
    this.options = options
  }

  _getStorage(options = {}) {
    const { storage = localStorage } = Object.assign({}, this.options, options)
    return storage
  }

  _getPrefixedKey(key, options = {}) {
    const {
      prefix = '',
      loginPrefix = '-login-clear-',
      login = false
    } = Object.assign({}, this.options, options)
    return prefix + (login ? loginPrefix : '') + key
  }

  _isNumber(num) {
    return /^-?\d+$/.test(num)
  }

  _getTimeUnit(unit) {
    let result = -1
    switch (unit) {
      case 'today': {
        result = dayjs().endOf('day').valueOf()
        break
      }
      default: {
        if (this._isNumber(unit)) {
          result = Number(unit)
        }
      }
    }
    return result
  }

  _getExpires({ expires = -1 } = {}) {
    return this._getTimeUnit(expires)
  }

  _checkExpires(expires) {
    if (this._isNumber(expires)) {
      if (Number(expires) === -1) {
        return true
      }
      return dayjs().isBefore(dayjs(expires))
    }
    return false
  }

  set(key, value, options) {
    const Key = this._getPrefixedKey(key, options)
    try {
      const temp = {
        data: value,
        expires: this._getExpires(options)
      }
      this._getStorage(options).setItem(Key, JSON.stringify(temp))
    } catch (e) {
      console.warn(`Storage not save the {${Key}} !`)
    }
  }

  /**
   * 从缓存恢复数据，没有时根据参数提供默认值
   * @param key 缓存Key
   * @param missing 默认值
   * @param options 额外参数
   * @returns {any}
   */
  get(key, missing, options) {
    const Key = this._getPrefixedKey(key, options)
    let result = null
    try {
      result = JSON.parse(this._getStorage(options).getItem(Key))
    } catch (e) {
      if (this._getStorage(options)[Key]) {
        result = {
          data: this._getStorage(options).getItem(Key),
          expires: -1
        }
      }
    }

    if (result && typeof result === 'object') {
      if (typeof result.expires !== 'undefined') {
        if (this._checkExpires(result.expires)) {
          if (typeof result.data !== 'undefined') {
            return result.data
          }
        } else {
          this.rm(key, options)
        }
      }
    }

    return missing
  }

  rm(key, options) {
    const Key = this._getPrefixedKey(key, options)
    this._getStorage(options).removeItem(Key)
  }

  delete(options = {}) {
    this.keys(options).forEach((v) => {
      this.rm(v, options)
    })
  }

  keys(options = {}) {
    const keys = Object.keys(this._getStorage(options))
    const prefix = this._getPrefixedKey('', options)
    if (prefix) {
      return keys
        .filter((v) => v.indexOf(prefix) === 0)
        .map((v) => v.slice(prefix.length))
    }
    return keys
  }

  getAll(options = {}) {
    const result = []
    this.keys(options).forEach((v) => {
      const temp = this.get(v)
      if (typeof temp !== 'undefined') {
        result.push({
          [v]: temp
        })
      }
    })
    return result
  }
}

export default Storage
