import router from '../router'
import store from '../../store'

router.beforeEach((to, from, next) => {
  const { direction } = store.state

  // 返回清除 keepAlive
  // 离开 keepAlive 组件，并且为返回
  if (from.meta && from.meta.keepAlive && direction === 'back') {
    store.commit('unKeepAlive', from.name)
  }
  next()
})

router.afterEach((to, from) => {
  const { meta } = to
  // 进入 keepAlive 组件
  if (meta && meta.keepAlive) {
    // 延迟10-50ms添加
    setTimeout(() => {
      store.commit('keepAlive', to.name)
    }, 50)
  }
})
