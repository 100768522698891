<template>
  <div class="j-anti-popup-container" v-if="value">
    <div class="j-anti-popup-overlay" @touchmove.prevent :class="overlayClass"  :style="`z-index: ${zIndex};`"></div>
    <div class="j-anti-popup j-anti-popup--center" :class="contentClass" :style="`z-index: ${zIndex + 1};`">
      <slot name="default"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AntiPopup',
  props: {
    value: {
      value: Boolean,
      default: false
    },
    overlayClass: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    },
    zIndex: {
      type: Number,
      default: 9999
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  methods: {
    syncInput(f = false) {
      this.$emit('input', f)
    }
  }
}
</script>
<style lang="scss" scoped>
.j-anti-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.7);
}
.j-anti-popup {
  position: fixed;
  max-height: 100%;
  transition: transform .3s;
  -webkit-overflow-scrolling: touch;
}
.j-anti-popup--center {
  top: 45%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
}
</style>
  