import request from '@/api'
import qs from 'qs'

// 三级页前置接口
export function getArticlePreAgentInfo(params) {
  return request({
    url: '/publicapply/detail/preAgent',
    method: 'get',
    noToast: true,
    params
  })
}

// 三级页基本信息接口
export function getArticleBaseInfo(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/detail/baseInfo',
    method: 'post',
    noToast: true,
    data
  })
}

// 三级页进阶信息
export function getArticleAdvancedInfo(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/detail/advancedInfo',
    method: 'post',
    data
  })
}

// 三级页分享信息
export function getArticleShareInfo(data) {
  return request({
    url: '/publicapply/detail/shareInfo',
    method: 'post',
    noToast: true,
    data
  })
}

// 三级页查看原文接口
export function getArticleOriginalText(data) {
  return request({
    url: '/publicapply/userbase/getOriginalText',
    method: 'post',
    data
  })
}


// 三级页分享相关接口
export function getContentShareEncrypt(data) {
  return request({
    url: '/share/encrypt',
    method: 'post',
    data: qs.stringify(data)
  })
}
