/**
 * 数据模型基类
 */
class BaseModel {
  model = null
  transformModel = null

  /**
   * 构造函数，初始化模型和转换函数
   */
  constructor() {
    this.model = this.createModel()
    this.transformModel = this.transform.bind(this)
  }

  /**
   * 数据转换方法，用于格式化和更新模型数据
   * @param {Object} data - 要转换的数据
   * @param {boolean} isInit - 是否为初始化状态，默认为false
   * @returns {Object} 返回格式化后的模型数据
   */
  transform(data, isInit = false) {
    const model = this.formatModel(data, isInit)
    this.model = model
    return model
  }

  /**
   * 预留的格式化模型方法，用于子类覆盖以实现特定的数据格式化逻辑
   * @param {Object} data - 要格式化的数据
   * @param {boolean} isInit - 是否为初始化状态，默认为false
   * @returns {Object} 返回格式化后的数据
   */
  formatModel (data, isInit = false) {
    return data
  }

  /**
   * 预留的格式化创建方法，创建一个空的模型对象，用于子类初始化或重写以提供特定的模型结构
   * @returns {Object} 返回一个空的模型对象
   */
  createModel () {
    return {}
  }
}

export default BaseModel
