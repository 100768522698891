import {
  appCallChangeTab,
  appCallGetToken,
  appCallHideTab,
  appCallOpenWindow,
  appCallPhone,
  appCallReloadTab,
  appDownLoadFile,
  appHideRedSpotOnMenu,
  appShare,
  getAppVersion
} from './appFn'
import routerVm from '@/router'
import { env, envs } from '@/utils/prototype/modules/platform'
import { getFormatURL, resolveUrlQueryParams } from '@/utils'
import { appStorage } from '@/utils/storage'
import { NotURLPrefixRegExp } from '@/utils/constant'

const inWeiXinBrowser = envs.inWX
const inApp = envs.inApp
const inUnifyApp = envs.inUnifyApp
const platformOS = env.platformOS
const platform = env.platform

/**
 * 比较两个版本号的大小
 * @param {string} v1
 * @param {string} v2
 * @returns {0|1|-1} 0 表示v1 = v2，1 表示v1 > v2，-1表示v1 < v2
 * @from https://developers.weixin.qq.com/miniprogram/dev/framework/compatibility.html
 * compareVersion('1.1.0', '1.1.0');      // => 0
 * compareVersion('1.20.0', '1.2.20');    // => 1
 * compareVersion('v2.0.30', 'v1.9.10');  // => 1
 * compareVersion('v1.1.40', 'v1.2.0');   // => -1
 */
export function compareVersion(v1, v2) {
  v1 = v1.split('.')
  v2 = v2.split('.')
  const len = Math.max(v1.length, v2.length)

  while (v1.length < len) {
    v1.push('0')
  }
  while (v2.length < len) {
    v2.push('0')
  }

  for (let i = 0; i < len; i++) {
    const num1 = Number.parseInt(v1[i])
    const num2 = Number.parseInt(v2[i])

    if (num1 > num2) {
      return 1
    }
    else if (num1 < num2) {
      return -1
    }
  }

  return 0
}

// 判断app版本号是否大于3.0.5(即3.0.6以及以上版本)
export function appVersionGt305() {
  const currentVersion = env.appVersion || getAppVersion()
  const targetVersion = '3.0.5' // 3.0.6版本更新移除某些客户端方法。详细描述在common.js中
  if (currentVersion) {
    const c = compareVersion(currentVersion, targetVersion)
    return c === 1
  }
  else {
    return true
  }
}

/**
 * 拨打电话
 * @param phone 电话号码
 */
export function callPhone(phone) {
  if (inApp) {
    appCallPhone(phone)
  }
  else {
    location.href = `tel:${phone}`
  }
}

/**
 * app分享
 * shareType: 1:微信 2:QQ 3:朋友圈
 * title: 分享标题
 * content: 分享内容
 * link: 分享链接
 * authTip: 授权提示文案
 */
export function callAppShare(shareType, title, content, link, authTip) {
  if (!inApp)
    return
  console.log(shareType, title, content, link, authTip)
  appShare(shareType, title, content, link, authTip)
}

/**
 * 打开广告窗口
 * @param config 参数
 * @param config.link 广告链接
 * @param config.type 打开形式 仅APP端支持 abroad:新窗口 within: 当前
 * @param config.title 窗口标题 仅APP端支持
 */
export function openLinkOfAd({
  link,
  type = 'within',
  title = '',
  iosHref
} = {}) {
  const openWindow = type === 'abroad'
  if (openWindow && inApp) {
    appCallOpenWindow(platformOS === 'ios' ? iosHref || link : link, title)
  }
  else {
    openLinkOfOther(platformOS === 'ios' ? iosHref || link : link)
  }
}
/**
 * 打开外部项目地址
 * @param link 链接
 * @param config 参数
 * @param config.query 请求参数
 * @param config.type 打开形式 仅支持 replace、push
 */
export function openLinkOfOther(link, config = {}, router = routerVm) {
  if (!link)
    return
  const { query = {}, type = 'push', match = true } = config
  let prefix = ''
  if (NotURLPrefixRegExp.test(link)) {
    if (inWeiXinBrowser) {
      prefix = import.meta.env.VITE_APP_WX_PROJECT_BASE
    }
    else {
      prefix = import.meta.env.VITE_APP_APP_PROJECT_BASE
    }
  }
  const toLink = getFormatURL(prefix + link, query)

  // 判断是否 app tabbar页面，需调用客户端方法
  if (inApp && !inUnifyApp) {
    const canSkipAppTabbar = checkNowInAppTabbarPage(toLink)
    if (canSkipAppTabbar) {
      if (canSkipAppTabbar === 'subscribe') {
        const { tab } = resolveUrlQueryParams(link)
        if (tab) {
          appStorage.set('subscribeActiveTab', tab, { login: true })
        }
        // 从url中提取参数
        appCallReloadTab(canSkipAppTabbar)
      }
      appCallChangeTab(canSkipAppTabbar)
      return
    }
  }
  const toLInkArr = toLink.split('?')
  const toLinkQuery = toLInkArr[1]
  const r = checkLinkRegistered(toLink, router)
  // console.log(link, router, r)
  if (match && r.path) {
    const pArr = [r.path]
    if (toLinkQuery) {
      pArr.push(toLinkQuery)
    }
    const routeInfo = {
      path: pArr.join('?'),
      query: {
        'inside-jumps': 1
      }
    }
    if (type !== 'push') {
      router.replace(routeInfo)
    }
    else {
      router.push(routeInfo)
    }
  }
  else {
    if (type !== 'push') {
      location.replace(toLink)
    }
    else {
      location.href = toLink
    }
  }
}

/**
 * 检查url是否在当前vue-router中注册过
 * url: '/jyapp/free/set'
 * url: 'https://app-jytest.jydev.jianyu360.com/jyapp/free/set'
 * url: '/jy_mobile/tabbar/home'
 * url: 'https://app-jytest.jydev.jianyu360.com/jy_mobile/tabbar/home'
 *
 * 检测到注册过url，则返回匹配到的路由对象
 * 检测到注册过，则返回url
 *
 */
export function checkLinkRegistered(url, router = routerVm) {
  if (!url)
    return console.error('url为必传参数')
  const routerBase = router.options.base || ''
  let waitingMatchUrl = ''
  const withDomain = url.includes('http://') || url.includes('https://')
  // url中有域名。
  if (withDomain) {
    const u = new URL(url)
    // 域名为剑鱼，并且pathname中有路由前缀(/jy_mobile/)，进行匹配。否则直接进行跳转
    if (u.host.includes('jianyu360')) {
      waitingMatchUrl = u.pathname
    }
    else {
      return {
        url
      }
    }
  }
  else {
    waitingMatchUrl = url
  }

  // pathname中有路由前缀(/jy_mobile/)，进行匹配并替换。否则直接进行跳转
  const hasRouterBaseStart
    = waitingMatchUrl && waitingMatchUrl.match(new RegExp(`^${routerBase}/`))
  if (hasRouterBaseStart) {
    waitingMatchUrl = waitingMatchUrl.replace(new RegExp(`^${routerBase}`), '')
    const r = router.matcher.match(waitingMatchUrl)
    if (r.name === '404') {
      return {
        url
      }
    }
    else {
      return r
    }
  }
  else {
    // 直接返回url进行location.href跳转
    return {
      url
    }
  }
}

/**
 * 根据不同平台，计算得到对应某个平台的url地址
 * @param page 页面对象 { app, wx, h5 }
 * @param page.app app地址
 * @param page.wx wx地址
 * @param page.h5 h5地址
 */
export function getAppOrWxPageLink(page) {
  let link
  if (typeof page === 'string') {
    link = page
  }
  else {
    link = page[platform]
    if (envs.inWxMini && page.mini) {
      link = page.mini
    }
  }
  // 如果没找到link
  if (platform === 'h5' && !link) {
    link = page.app
  }
  return link
}

/**
 * 打开外部项目地址
 * @param page 页面对象 { app, wx, h5 }
 * @param page.app app地址
 * @param page.wx wx地址
 * @param page.h5 h5地址
 * @param config 参数
 * @param config.query 请求参数
 * @param config.type 打开形式 仅支持 replace、push
 */
export function openAppOrWxPage(page, config = {}) {
  const link = getAppOrWxPageLink(page)
  console.log(link)
  openLinkOfOther(link, config)
}

/**
 * 切换底部导航
 * @param name 需要切换到的TabName, 对应关系 search：搜索 subscribe：订阅 box：百宝箱 me：我的 message：消息
 */
export function callChangeTab(name, router = routerVm) {
  /**
   * 特殊业务逻辑
   * WX端，me 页面需要走后端路由拦截，用于验证手机号绑定
   * APP端，search 页面需要走后端路由拦截，用于验证登录状态
   */
  const hasRouter = ['search', 'subscribe', 'box', 'message', 'me']
  if (!hasRouter.includes(name)) {
    return console.error('请检查Name，无法找到对应导航地址!')
  }

  if (inApp && !inUnifyApp) {
    // 旧版app
    return appCallChangeTab(name)
  }
  else if (inWeiXinBrowser && name === 'me') {
    // 微信
    const url
      = `${import.meta.env.VITE_APP_WX_PROJECT_BASE}/front/wxMyOrder/myMenu`
    location.replace(url)
  }
  else {
    router.replace({
      name: `tabbar-${name}`
    })
  }
}

/**
 * APP独有 隐藏底部导航
 * @param type
 */
export function callHideTab(type) {
  if (inApp) {
    appCallHideTab(type)
  }
}

/**
 * APP独有 获取用户 token
 */
export function callGetToken() {
  if (inApp) {
    appCallGetToken()
  }
}

/**
 * APP独有 ios附件下载 、Android客户端暂无！
 * doc 、docx、excel 、xls 、 xlsxppt 、 pptx、 pdf、 txt、png 、PNG、jpg 、JPG 暂定这些为常见类型~支持在线预览+下载+转存
 * 其他类型仅支持下载+转存
 * @param filename 文件名称不带后缀
 * @param filetype 文件类型：doc word excel 等等
 * @param fileurl 文件链接
 * @param filesize 文件大小
 */
export function downLoadFile(filename, filetype, fileurl, filesize) {
  if (inApp) {
    appDownLoadFile(filename, filetype, fileurl, filesize)
  }
  else {
    downLoadFileH5(filename, fileurl)
  }
}

export function downLoadFileH5(name, url) {
  const a = document.createElement('a')
  const filename = name
  a.href = url
  a.target = '_blank'
  if (filename) {
    a.download = filename
  }
  a.click()
}

/**
 * APP独有：隐藏底部栏的小红点
 */
export function callHideRedSpotOnMenu(menu) {
  if (!inApp)
    return
  try {
    appHideRedSpotOnMenu(menu)
  }
  catch (e) {
    console.warn('error: app hideRedSpotOnMenu failed', e)
  }
}

/**
 * 判断是否 APP Tabbar 页面, 有则返回对于 key
 * @param link - 需判断link、默认 location.href
 * @returns {string} - 对应的 key
 */
export function checkNowInAppTabbarPage(link) {
  const appTabbars = {
    search: '/jyapp/jylab/mainSearch',
    subscribe: '/jyapp/swordfish/historypush',
    message: '/jyapp/frontPage/messageCenter/sess/index',
    box: '/jyapp/jylab/index',
    me: '/jyapp/free/me'
  }

  let appTabbarName = ''
  for (const appTabbarsKey in appTabbars) {
    const hasTabbar
      = (link || location.href).includes([appTabbars[appTabbarsKey]])
    if (hasTabbar) {
      appTabbarName = appTabbarsKey
      break
    }
  }
  return appTabbarName
}
