import { getPic } from '@/utils'

/**
 * 广告位响应值格式转换函数
 * @param config 广告位响应值
 * @returns {*}
 */
export function adConfigFormatter(config = {}) {
  config = config || {}
  const oExtend = config.o_extend || {}
  return {
    pic: getPic(config?.s_pic),
    link: config?.s_link,
    name: config?.s_picalt || config?.s_remark,
    type: config?.o_extend?.linktype,
    title: config?.s_remark,
    iosHref: config?.o_extend?.iosHref
      ? `https://${config?.o_extend?.iosHref}`
      : '',
    extend: {
      width: config?.o_extend?.width,
      height: config?.o_extend?.height,
      type: config?.o_extend?.linktype,
      power: oExtend?.power,
      tab: oExtend?.tab
    },
    script: config?.s_script ? JSON.parse(config.s_script) : ''
  }
}
