import request from '@/api'
import qs from 'qs'

/**
 *获取需要合并的账户信息
 * @returns {AxiosPromise}
 */
export function getAccountMergeInfo() {
  return request({
    url: '/jyMerge/userMsg/query',
    method: 'get'
  })
}

/**
 * 获取需要积分信息
 * @returns {AxiosPromise}
 */
export function getPointsInfo(data) {
  data = qs.stringify(data)
  return request({
    url: '/jyintegral/getList',
    method: 'post',
    data
  })
}

/**
 * 获取是否需要展示搬家提醒等
 * @returns {AxiosPromise}
 */
export function ajaxGetTipInfo(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/userbase/tipInfo',
    method: 'post',
    data
  })
}

/**
 * 更新积分和签到天数
 * @returns {AxiosPromise}
 */
export function updatePoints(data) {
  data = qs.stringify(data)
  return request({
    url: '/jyintegral/toUpdate',
    method: 'post',
    data
  })
}

/**
 * 用户是否需要搬家提醒
 * @returns {AxiosPromise}
 */
export function getMoveDialog(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/userbase/tipInfo',
    method: 'post',
    data
  })
}

/**
 * 获取身份列表
 * @returns {AxiosPromise}
 */
export function getUserIdentity() {
  return request({
    url: '/publicapply/identity/list?t=' + Date.now(),
    method: 'post'
  })
}

/**
 * 切换当前用户身份
 * @returns {AxiosPromise}
 */
export function changeUserIdentity(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/identity/switch',
    method: 'post',
    data
  })
}

/**
 * 是否是剑鱼伙伴？？
 * @returns {AxiosPromise}
 */
export function distributionIsPartner() {
  return request({
    url: '/distribution/partner/isPartner',
    method: 'post'
  })
}

/**
 * 获取用户公司信息
 * @returns {AxiosPromise}
 */
export function getMineCompany() {
  return request({
    url: '/publicapply/identity/maintenance/detail',
    method: 'post'
  })
}

/**
 * 编辑用户公司信息
 * @returns {AxiosPromise}
 */
export function editMineCompany(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/identity/maintenance/edit',
    method: 'post',
    data
  })
}
