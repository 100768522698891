/*
 * 时间格式化函数(将时间格式化为，2019年08月12日，2019-08-12，2019/08/12的形式)
 *   pattern参数（想要什么格式的数据就传入什么格式的数据）
 *     · 'yyyy-MM-dd'  ---> 输出如2019-09-20
 *     · 'yyyy-MM-dd HH:mm'  --->  输出如2019-09-20 18:20
 *     · 'yyyy-MM-dd HH:mm:ss'  --->  输出如2019-09-20 06:20:23
 *     · 'yyyy/MM/dd'  --->  输出如2019/09/20
 *     · 'yyyy年MM月dd日'  --->  输出如2019年09月20日
 *     · 'yyyy年MM月dd日 HH时mm分'  --->  输出如2019年09月20日 18时20分
 *     · 'yyyy年MM月dd日 HH时mm分ss秒'  --->  输出如2019年09月20日 18时20分23秒
 *     · 'yyyy年MM月dd日 HH时mm分ss秒 EE'  --->  输出如2019年09月20日 18时20分23秒 周二
 *     · 'yyyy年MM月dd日 HH时mm分ss秒 EEE'  --->  输出如2019年09月20日 18时20分23秒 星期二
 *  参考: https://www.cnblogs.com/mr-wuxiansheng/p/6296646.html
 */
export function dateFormatter(date = '', fmt = 'yyyy-MM-dd HH:mm:ss') {
  // 将传入的date转为时间对象
  if (!date) return ''
  // 处理ios不兼容'2022-6-6'类似的'-'问题
  if (typeof data === 'string') {
    date = date.replace(/-/g, '/')
  }
  date = new Date(date)
  const o = {
    'y+': date.getFullYear(),
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    // 12小时制
    'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时
    // 24小时制
    'H+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
    'E+': date.getDay() // 周
  }
  const week = ['日', '一', '二', '三', '四', '五', '六']

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '星期' : '周') : '') +
      week[date.getDay()]
    )
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return fmt
}

// 时间戳转换 多少秒、多少分、多少小时前、多少天前  超出10天显示年月日
// 传入一个时间戳
export function dateFromNow(originTime, useOld = false) {
  if (!originTime) return
  // 原始时间 - 传入的时间戳
  const originTimeStamp = +new Date(originTime)
  // 当前时间戳
  const nowTimeStamp = +new Date()
  // 时间戳相差多少
  const diffTimeStamp = nowTimeStamp - originTimeStamp

  const postfix = diffTimeStamp > 0 ? '前' : '后'
  // 求绝对值 ms(毫秒)
  const diffTimeStampAbsMs = Math.abs(diffTimeStamp)
  const diffTimeStampAbsS = Math.round(diffTimeStampAbsMs / 1000)

  // 10天的秒数
  const days11 = 11 * 24 * 60 * 60

  const dataMap = {
    zh: ['天', '小时', '分钟', '秒'],
    number: [24 * 60 * 60, 60 * 60, 60, 1]
  }

  let timeString = ''
  // 10天前
  const tenDaysAgo = diffTimeStampAbsS > days11
  // 是否是当天
  const isCurrentDay =
    dateFormatter(originTimeStamp, 'yyyy.MM.dd') ===
    dateFormatter(nowTimeStamp, 'yyyy.MM.dd')

  let condition = !isCurrentDay
  if (useOld) {
    condition = tenDaysAgo
  }

  if (condition) {
    // 不是当天，则使用正常日期显示
    const originDate = new Date(originTimeStamp)
    const nowDate = new Date()
    // 是否同年
    const sameYear = originDate.getFullYear() === nowDate.getFullYear()
    // 如果是当年，则不显示年
    const patternString = sameYear ? 'MM-dd' : 'yyyy-MM-dd'
    timeString = dateFormatter(originDate, patternString)
  } else {
    for (let i = 0; i < dataMap.number.length; i++) {
      const inm = Math.floor(diffTimeStampAbsS / dataMap.number[i])
      if (inm !== 0) {
        timeString = inm + dataMap.zh[i] + postfix
        break
      }
    }
  }
  return timeString
}
