import {
  ajaxGetProductInfo,
  ajaxGetProductOffers,
  ajaxGetProductOrderAmount,
  ajaxSubmitCreatedProductOrder
} from '@/api/modules'

/**
 * 格式化产品、产品规格数据信息
 * @param productList
 * @returns {{productMap: {}, productSpecMap: {}}}
 */
function formatProductInfo(list = []) {
  const productList = [].concat(list)
  const productMap = {}
  const productSpecMap = {}
  let defaultIndex = 0
  productList.forEach((product, productIndex) => {
    product.sku.forEach((spec) => {
      productSpecMap[spec.productId] = formatProductSpecInfo(spec)
      if (spec.choosed) {
        defaultIndex = productIndex
      }
    })
    product._specList = product.sku.map((spec) => spec.productId)
    // 避免直接使用过期数据
    delete product.sku
    productMap[productIndex] = product
  })
  return {
    defaultIndex,
    productMap,
    productSpecMap
  }
}

/**
 * 格式化产品规格、活动、优惠信息
 * @param spec
 * @param lotteryInfo - 优惠信息
 * @returns {*}
 */
function formatProductSpecInfo(s, lotteryInfo = {}) {
  const spec = Object.assign({}, s)
  // 计算商品规格当前对应的优惠、活动信息
  spec._activityInfo = (spec?.activity || []).find((s) => {
    return s.activityId === (lotteryInfo?.activityId || spec?.activityId)
  })
  spec._offers = (spec._activityInfo?.discount || []).find((s) => {
    return s.lotteryId === (lotteryInfo?.lotteryId || spec?.lotteryId)
  })
  spec._format = {
    title: spec?.info,
    money: spec?.originalPrice,
    desc: spec?.desc,
    tag: spec._offers?.tag
  }
  return spec
}

const productNameMap = {
  101: 'VIP订阅',
  102: '历史数据',
  104: '大会员',
  111: '中标必听课',
  112: '数据流量包',
  113: '省份订阅包',
  114: '附件下载包',
  115: '采购单位画像包',
  117: '数据报告',
  118: '剑鱼文库会员',
  203: '市场分析定制报告下载包',
  201: '企业中标分析报告下载包',
  202: '业主采购分析报告下载包'
}

const Hooks = {
  /**
   * 提交订单钩子，可用于自定义提交参数及成功逻辑
   * @param pre 已经准备好的参数
   * @param next - 提交订单函数
   * @returns
   */
  submit: (pre, next) => next(pre)
}

/**
 * UI 信息
 * @type {{submitText: string}}
 */
const UIConfig = {
  // 允许在活动进行中时，调整提交按钮文本
  canChangeSubmitText: true,
  // 提交按钮文本
  submitText: '提交订单'
}
/**
 * UI 信息
 * @type {{submitText: string}}
 */
const LayoutConfig = {
  // 是否暂时禁用全局loading。true为禁用。禁用完成后应及时置为false，以免影响其他页面
  disableLoading: false,
  // 头部模块
  header: true,
  // 活动模块是否显示。默认显示
  activity: true,
  // 底部提示模块，默认不显示
  footerNotice: false
}

const moduleName = 'createOrder'
export default {
  namespaced: true,
  state: () => ({
    hooks: Object.assign({}, Hooks),
    // 产品信息
    product: {
      // 产品信息集合
      maps: {},
      // 当前选中的产品类型索引
      index: 0,
      /**
       * 101 超级订阅
       * 102 历史数据导出
       * 104 大会员
       * 112 数据流量包
       * 113 省份订阅包
       * 114 附件下载包
       * 115 采购单位画像包
       */
      id: -1,
      // 当前产品请求附加参数信息
      extend: {}
    },
    productSpec: {
      // 产品规格集合
      maps: {},
      // 当前选中的产品<规格ID>
      id: -1
    },
    // 优惠信息
    offers: {
      // 当前选中的优惠ID
      id: -1
    },
    // 金额信息
    amount: {
      // 原价 (一般情况下： 原价= 优惠后的金额 + 优惠的金额)
      origin: 0,
      // 优惠的金额
      discount: 0,
      // 优惠后的金额
      pay: 0
    },
    // 表单信息例如手机号、邮箱等
    form: {},
    // 页面信息验证是否通过(value全为true时验证通过)
    canNextMap: {
      // 是否同意购买协议
      read: false
    },
    // 用于控制跨组件UI联动
    ui: Object.assign({}, UIConfig),
    // 控制layout模块是否动态展示
    layout: Object.assign({}, LayoutConfig),
    // 模块数据加载状态
    loading: {
      product: false,
      activity: false,
      offers: false,
      amount: false,
      submit: false
    },
    isShowGiftNotice: false
  }),
  mutations: {
    /**
     * 设置产品列表及更新下属信息
     * @param state
     * @param list
     */
    setProductInfoList(
      state,
      { defaultIndex, productMap = {}, productSpecMap = {} } = {}
    ) {
      state.product.maps = productMap
      state.productSpec.maps = productSpecMap
      state.product.index = defaultIndex
    },
    /**
     * 更新指定的产品规格信息
     * @param state
     * @param spec - 格式化后的产品规格
     */
    updateProductSpecInfo(state, spec) {
      if (spec && spec.productId) {
        state.productSpec.maps[spec.productId] = spec
      }
    },
    /**
     * 更新指定 hooks
     * @param state
     * @param hooks
     */
    updateHooks(state, hooks) {
      state.hooks = Object.assign({}, state.hooks, hooks)
    },
    /**
     * 重置 hooks
     * @param state
     */
    resetHooks(state) {
      state.hooks = Object.assign({}, Hooks)
    },
    /**
     * 更新订单金额信息
     * @param state
     * @param originalPrice - 原价 (一般情况下： 原价= 优惠后的金额 + 优惠的金额)
     * @param discountAmount - 优惠的金额
     * @param discountPrice - 优惠后的金额
     */
    updateOrderAmount(
      state,
      { originalPrice = 0, discountAmount = 0, discountPrice = 0 } = {}
    ) {
      state.amount.origin = originalPrice
      state.amount.discount = discountAmount
      state.amount.pay = discountPrice
    },
    /**
     * 设置产品默认值，如产品类型、ID、默认选中规格等信息
     * @param state
     * @param config
     */
    updateProductInfo(state, { index = 0, id, extend }) {
      this.commit(`${moduleName}/updateProductIndex`, index)
      this.commit(`${moduleName}/updateProductId`, id)
      this.commit(`${moduleName}/updateProductExtend`, extend)
    },
    /**
     * 设置产品类型 index
     * @param state
     * @param index
     */
    updateProductIndex(state, index) {
      state.product.index = index
    },
    /**
     * 设置产品类型 ID
     * @param state
     * @param id
     */
    updateProductId(state, id) {
      // 变更前的id
      const prevId = state.product.id
      const changed = prevId !== id && prevId !== -1
      state.product.id = id
      if (changed) {
        // 重置产品扩展信息
        this.commit(`${moduleName}/updateProductExtend`, {})
        // 重置产品 hooks
        this.commit(`${moduleName}/resetHooks`, {})
        // 重置表单信息
        this.commit(`${moduleName}/resetFormStatus`, {})
        // 重置模块验证信息
        this.commit(`${moduleName}/resetCanNextMap`, {})
        // 重置UI模块信息
        this.commit(`${moduleName}/resetUI`)
        // 重置Layout信息
        this.commit(`${moduleName}/resetLayout`)
        console.warn(
          `因产品类型id改变(${prevId}->${id})，已清空数据: extend, hooks, form, canNext, ui...`
        )
      }
    },
    /**
     * 设置产品附加请求参数
     * @param state
     * @param extend
     */
    updateProductExtend(state, extend = {}) {
      state.product.extend = extend
    },
    /**
     * 设置产品规格 ID
     * @param state
     * @param id
     */
    updateProductSpecId(state, id) {
      state.productSpec.id = id
    },
    /**
     * 设置产品当前选中使用的优惠ID
     * @param state
     * @param id
     */
    updateProductOffersId(state, id) {
      state.offers.id = id
    },
    /**
     * 设置加载状态
     * @param state
     * @param status
     */
    updateLoadingStatus(state, status) {
      state.loading = Object.assign({}, state.loading, status)
    },
    /**
     * 设置表单信息
     * @param state
     * @param status
     */
    updateFormStatus(state, status) {
      state.form = Object.assign({}, state.form, status)
    },
    /**
     * 重置表单信息
     * @param state
     */
    resetFormStatus(state) {
      state.form = {}
    },
    /**
     * 设置页面模块验证是否通过
     * @param state
     * @param map
     */
    updateCanNextMap(state, map = {}) {
      state.canNextMap = Object.assign({}, state.canNextMap, map)
    },
    /**
     * 重置页面模块验证信息
     * @param state
     */
    resetCanNextMap(state) {
      state.canNextMap = {
        read: false
      }
    },
    /**
     * 更新UI信息
     * @param state
     * @param config
     */
    updateUI(state, config) {
      state.ui = Object.assign({}, state.ui, config)
    },
    /**
     * 重置UI信息
     * @param state
     */
    resetUI(state) {
      state.ui = Object.assign({}, UIConfig)
    },
    /**
     * 更新layout
     * @param state
     * @param config
     */
    updateLayout(state, config) {
      state.layout = Object.assign({}, state.layout, config)
    },
    /**
     * 重置UI信息
     * @param state
     */
    resetLayout(state) {
      state.layout = Object.assign({}, LayoutConfig)
    },
    /**
     * 是否展示超级订阅赠送信息
     * @param state
     */
    setShowGiftNotice(state, data) {
      state.isShowGiftNotice = data
    }
  },
  actions: {
    /**
     * 重置当前模块的store
     */
    resetStore({ commit }) {
      commit('setProductInfoList')
      commit('updateOrderAmount')
      commit('resetHooks')
      commit('resetFormStatus')
      commit('resetCanNextMap')
      commit('resetUI')
      commit('resetLayout')
      console.warn('已重置store数据')
    },
    /**
     * 设置产品基础信息并请求接口获取产品详细信息(首次请求使用)
     * @param index - 产品类型索引
     * @param id - 产品类型ID
     * @param extend - 请求参数
     * @returns {Promise<void>}
     */
    async setProductInfo(
      { dispatch, commit, getters },
      { index = 0, id, extend, hooks = {} }
    ) {
      // 更新产品信息
      commit('updateProductInfo', {
        index,
        id,
        extend
      })
      // 更新 hooks
      commit('updateHooks', hooks)
      return await dispatch(
        'getProductInfo',
        Object.assign(
          {
            id,
            productType: getters.productName
          },
          extend
        )
      )
    },
    // 根据商品ID、扩展等参数获取商品类型、规格等详细信息
    async getProductInfo({ commit, dispatch, getters, state }, payload) {
      // 合并一些请求参数
      const params = Object.assign({}, getters.productExtend, payload)
      // 设置加载状态
      commit('updateLoadingStatus', {
        product: true
      })
      const {
        data = [],
        error_msg: msg,
        error_code: code
      } = await ajaxGetProductInfo(params)
      if (code === 0 && data) {
        // 更新产品类型、规格信息
        commit('setProductInfoList', formatProductInfo(data))
        // 设置默认选中规格
        if (getters.productSpecInfo.productId || state.productSpec.id !== -1) {
          // getters.productSpecInfo.productId 这个条件表示已经拿到规格数据并且已选中
          // state.productSpec.id !== -1 这个条件表示未拿到规格数据的情况下修改了选中项(可理解为有已选中)
          // 如果有已选中的规格。就用已选中的规格赋默认值
          await dispatch('setProductSpecInfo', { id: state.productSpec.id })
        } else {
          const spec = getters.productSpecInfoList.find((spec) => spec.choosed)
          if (spec) {
            await dispatch('setProductSpecInfo', { id: spec.productId })
          } else {
            await dispatch('setProductSpecInfo', { index: 0 })
          }
        }
        commit('updateLoadingStatus', {
          product: false
        })
      } else {
        console.warn('Error: 01 商品信息获取失败！')
        if (msg) {
          // 移动端有msg弹窗。由于toast是单例的，msg弹窗会覆盖loading。
          // commit('updateLoadingStatus', {
          //   product: false
          // })
        } else {
          commit('updateLoadingStatus', {
            product: false
          })
        }
      }
    },
    /**
     * 设置产品规格信息并更新优惠、活动相关信息
     * @param index - 产品规格索引
     * @param id  - 产品规格 ID
     * @returns {Promise<void>}
     */
    async setProductSpecInfo(
      { commit, getters, dispatch },
      { index = 0, id, extend }
    ) {
      // 重置当前规格默认选中优惠、活动信息
      dispatch('resetNowProductSpecInfo')
      // 根据<规格id>计算<商品分类索引>并更新产品分类索引
      if (id) {
        const productIndex = await dispatch('calcProductIndexWithSpecId', id)
        if (productIndex !== -1) {
          commit('updateProductIndex', productIndex)
        }
      }
      // 保存对应的产品规格扩展信息
      await dispatch('refreshProductExtend', extend)
      // 判断ID是否存在，不存在则使用索引
      const hasProductSpecId = getters.productInfo._specList.includes(id)
      commit(
        'updateProductSpecId',
        hasProductSpecId ? id : getters.productInfo._specList[index]
      )
      // 设置默认选中优惠ID，用于更新优惠、活动信息
      commit('updateProductOffersId', getters.productSpecInfo?.lotteryId)
      // 设置商品默认金额信息
      commit('updateOrderAmount', getters.productSpecInfo)
    },
    // 重置当前规格默认选中优惠、活动信息
    refreshProductExtend({ commit, getters }, extend) {
      commit(
        'updateProductExtend',
        Object.assign({}, getters.productExtend, extend)
      )
    },
    // 重置当前规格信息
    resetNowProductSpecInfo({ commit, getters }) {
      // 更新产品规格信息
      commit(
        'updateProductSpecInfo',
        formatProductSpecInfo(getters.productSpecInfo)
      )
    },
    // 更改产品选中规格并调用接口更新规格、优惠相关信息
    async changeProductChoiceSpec(
      { dispatch, getters, commit },
      { index, id, extend }
    ) {
      // 请求前-展示默认产品规格选择、活动等信息
      
      // await dispatch('setProductSpecInfo', {
      //   index,
      //   id,
      //   extend
      // })
      // 合并一些请求参数并请求
      await dispatch(
        'getProductOffers',
        Object.assign(
          {
            id: getters.productSpecInfo.productId,
            productType: getters.productName
          },
          extend
        )
      )
      // 请求后-刷新应用远程数据更新产品规格选择、活动等信息
      await dispatch('setProductSpecInfo', {
        index,
        id,
        extend
      })
    },
    // 更新当前规格优惠相关信息，用于优惠倒计时结束刷新
    async updateProductChoiceSpec({ dispatch, getters }) {
      await dispatch('changeProductChoiceSpec', {
        id: getters.productSpecInfo.productId
      })
    },
    // 根据商品ID、规格等参数获取商品优惠信息
    async getProductOffers({ commit, getters }, payload) {
      // 合并一些请求参数
      const params = Object.assign({}, getters.productExtend, payload)
      // 设置加载状态
      commit('updateLoadingStatus', {
        offers: true
      })
      const {
        data,
        error_msg: msg,
        error_code: code
      } = await ajaxGetProductOffers(params)
      if (code === 0 && data) {
        if (Array.isArray(data) && Array.isArray(data[0]?.sku)) {
          const specInfo = data[0].sku[0]
          // 更新产品规格信息
          commit('updateProductSpecInfo', formatProductSpecInfo(specInfo))
        } else {
          console.warn('Error: 02 商品规格优惠信息获取失败！')
        }
        commit('updateLoadingStatus', {
          offers: false
        })
      } else {
        if (msg) {
          // 移动端有msg弹窗。由于toast是单例的，msg弹窗会覆盖loading。
          // commit('updateLoadingStatus', {
          //   offers: false
          // })
        } else {
          commit('updateLoadingStatus', {
            offers: false
          })
        }
      }
    },
    // 根据规格id计算表对应的分类索引
    calcProductIndexWithSpecId({ getters }, specId) {
      return getters.productInfoList.findIndex((product) =>
        product?._specList.includes(specId)
      )
    },
    /**
     * 设置优惠相关信息
     * @param offersId - 优惠ID
     * @returns {Promise<void>}
     */
    async setProductOffersInfo({ state, commit, getters }, { offersId = -1 }) {
      const useOffersId = offersId
      if (state.offers.id !== useOffersId) {
        // 设置默认选中优惠ID，用于更新优惠、活动信息
        commit('updateProductOffersId', useOffersId)
        // 更新当前产品规格优惠、活动信息
        commit(
          'updateProductSpecInfo',
          formatProductSpecInfo(
            getters.productSpecInfo,
            getters.productChoiceOffers
          )
        )
      }
    },
    // 更改产品选中优惠信息
    async changeProductChoiceOffers(
      { dispatch, getters },
      { offersId = -1, extend }
    ) {
      await dispatch('setProductOffersInfo', { offersId })
      // 合并一些请求参数并请求
      await dispatch(
        'getProductOrderAmount',
        Object.assign(
          {
            id: getters.productSpecInfo.productId,
            productId: getters.productSpecInfo.productId,
            product: getters.productName,
            lotteryId: offersId === -1 ? '' : offersId,
            activityType: getters.productChoiceActivityInfo.type
          },
          extend
        )
      )
    },
    // 根据商品ID、规格、优惠等参数获取商品订单金额信息
    async getProductOrderAmount({ state, commit, getters }, payload) {
      // 合并一些请求参数
      const params = Object.assign({}, getters.productExtend, payload)
      // 设置加载状态
      commit('updateLoadingStatus', {
        activity: true,
        amount: true
      })
      const {
        data,
        error_msg: msg,
        error_code: code
      } = await ajaxGetProductOrderAmount(params)
      if (code === 0 && data) {
        // 更新商品金额信息
        commit('updateOrderAmount', data)
        commit('updateLoadingStatus', {
          activity: false,
          amount: false
        })
        // 获得当前价格后更新商品规格信息
        let productobj = JSON.parse(JSON.stringify(getters.productSpecInfo))
        productobj.discountAmount = data?.discountAmount || 0
        productobj.discountPrice = data?.discountPrice || 0
        productobj.originalPrice = data?.originalPrice || 0
        commit(
          'updateProductSpecInfo',
          formatProductSpecInfo(productobj, getters.productChoiceOffers)
        )
      } else {
        if (msg) {
          // 移动端有msg弹窗。由于toast是单例的，msg弹窗会覆盖loading。
          // commit('updateLoadingStatus', {
          //   activity: false,
          //   amount: false
          // })
        } else {
          commit('updateLoadingStatus', {
            activity: false,
            amount: false
          })
        }
      }
    },
    // 提交订单
    async submitCreatedProductOrder({ commit, getters }) {
      commit('updateLoadingStatus', {
        submit: true
      })
      try {
        let activityType = getters.productChoiceActivityInfo?.type
        if (activityType === undefined || activityType === null) {
          activityType = -1
        }
        const preParams = {
          productId: getters.productId,
          productSpecId: getters.productSpecInfo.productId,
          productName: getters.productName,
          activityType,
          offersId: getters.productChoiceOffers.lotteryId,
          pay: getters.productOrderAmount.pay,
          form: {
            ...getters.productFormInfo
          }
        }
        const { error_code: code, error_msg: msg } = await getters.submitHooks(
          preParams,
          (params) => ajaxSubmitCreatedProductOrder(params)
        )
        if (code === 0) {
          commit('updateLoadingStatus', {
            submit: false
          })
        } else {
          if (msg) {
            // 移动端有msg弹窗。由于toast是单例的，msg弹窗会覆盖loading。
            // commit('updateLoadingStatus', {
            //   submit: false
            // })
          } else {
            commit('updateLoadingStatus', {
              submit: false
            })
          }
        }
      } catch (e) {
        console.warn(e)
        commit('updateLoadingStatus', {
          submit: false
        })
      }
    }
  },
  getters: {
    // 用于提交订单的数据
    submitHooks(state) {
      return state.hooks.submit
    },
    canSubmitOrder(state) {
      return !Object.values(state.canNextMap).includes(false)
    },
    // 产品id
    productId(state) {
      return state.product.id
    },
    // 产品名称
    productName(_, getters) {
      return productNameMap[getters.productId]
    },
    // 获取商品请求额外携带的参数
    productExtend(state) {
      return state.product.extend
    },
    // 获取商品类型列表
    productInfoList(state) {
      const productList = []
      for (const index in state.product.maps) {
        productList[index] = state.product.maps[index]
      }
      return productList
    },
    // 获取当前选中的商品类型信息
    productInfo(state) {
      return state.product.maps[state.product.index] || {}
    },
    // 获取当前选中的商品类型下的商品规格列表
    productSpecInfoList(state, getters) {
      return (getters.productInfo._specList || []).map(
        (specId) => state.productSpec.maps[specId]
      )
    },
    // 获取当前选中的商品规格信息
    productSpecInfo(state) {
      return state.productSpec.maps[state.productSpec.id] || {}
    },
    // 所有优惠券列表
    productOffersList(_, getters) {
      return getters.productActivityInfoList
        .map((activity) => activity?.discount || [])
        .flat()
    },
    // 所有优惠券列表(排序后)
    productOffersListSorted(_, getters) {
      const { productAvailableOffersList, productUnavailableOffersList } =
        getters
      return productAvailableOffersList.concat(productUnavailableOffersList)
    },
    // 获取商品可用优惠信息列表
    productAvailableOffersList(_, getters) {
      return getters.productOffersList
        .filter((v) => v?.usable)
        .sort((a, b) => a.discount - b.discount)
    },
    // 获取当前商品选中的优惠信息
    productChoiceOffers(state, getters) {
      let choosed = getters.productAvailableOffersList.find(
        (offers) => offers?.lotteryId === state.offers.id
      ) || {
        activityId: -1,
        lotteryId: -1
      }

      return choosed
    },
    // 获取商品不可用优惠信息列表
    productUnavailableOffersList(_, getters) {
      const now = Date.now() / 1000
      // 不可用优惠优先展示进行中优惠，之后展示预热优惠。两类优惠按照优惠力度从从大到小展示
      const unavailableList = getters.productOffersList.filter(
        (v) => !v?.usable
      )
      // 预热优惠
      const preArr = []
      // 进行中优惠
      const penArr = []
      // 其他
      const otherArr = []
      unavailableList.forEach((item) => {
        if (item.starttime < now) {
          preArr.push(item)
        } else if (item.starttime > now && item.endtime < now) {
          penArr.push(item)
        } else {
          otherArr.push(item)
        }
      })
      const preArrSorted = preArr.sort((a, b) => a.discount - b.discount)
      const penArrSorted = penArr.sort((a, b) => a.discount - b.discount)
      const otherArrSorted = otherArr.sort((a, b) => a.discount - b.discount)
      return [].concat(preArrSorted, penArrSorted, otherArrSorted)
    },
    // 获取商品金额
    productOrderAmount(state) {
      return state.amount
    },
    // 获取当前商品适用的活动信息列表
    productActivityInfoList(_, getters) {
      return getters.productSpecInfo?.activity || []
    },
    // 获取当前应当展示活动信息，用于顶部活动横幅
    productChoiceActivityInfo(_, getters) {
      return (
        getters.productActivityInfoList.find(
          (activity) =>
            activity?.activityId === getters.productChoiceOffers?.activityId
        ) || {}
      )
    },
    // 当前预热活动列表
    productPreActivityList(_, getters) {
      const now = Date.now() / 1000
      return getters.productActivityInfoList
        .filter((item) => {
          return (
            item.preStartTime > 0 &&
            item.preStartTime - now < 0 &&
            item.starttime - now > 0
          )
        })
        .sort((a, b) => {
          return a.starttime - b.starttime
        })
    },
    // 获取数据加载状态
    loadingStatus(state, getters) {
      const { disabledLoading } = getters.pageLayout
      const loading = Object.assign({}, state.loading)
      // 逐级同步状态
      if (loading.product) {
        loading.offers = true
      }
      if (loading.offers) {
        loading.activity = true
        loading.amount = true
      }
      if (loading.amount) {
        loading.submit = true
      }
      if (disabledLoading) {
        loading.some = false
      } else {
        loading.some = Object.values(loading).some((v) => v)
      }
      return loading
    },
    // 获取表单信息
    productFormInfo(state) {
      return state.form
    },
    // 获取UI信息
    productUI(state, getters) {
      // 活动并且存在库存的时候，修改提交按钮文本
      const activeSubmitUI = {}
      // 是否允许活动时替换提交按钮文本
      if (state.ui.canChangeSubmitText) {
        const { stockNumber } = getters.productChoiceOffers
        if (stockNumber > 0) {
          activeSubmitUI.submitText = '立即抢购'
        }
      }

      return Object.assign({}, state.ui, activeSubmitUI)
    },
    pageLayout(state) {
      return state.layout
    }
  }
}
