import qs from 'qs'
import { useRequest } from '../../../api'

export function ajaxFollowProjectInfo(data) {
  data = qs.stringify(data)
  return useRequest({
    url: '/bigmember/follow/project/check',
    method: 'post',
    data
  })
}

export function ajaxFollowProjectAdd(data) {
  data = qs.stringify(data)
  return useRequest({
    url: '/bigmember/follow/project/add',
    method: 'POST',
    data
  })
}

export function ajaxFollowProjectCancel(data) {
  data = qs.stringify(data)
  return useRequest({
    url: '/bigmember/follow/project/cancel',
    method: 'POST',
    data
  })
}
