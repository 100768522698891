/**
 * 该函数用于将Px换算为Vw
 * @param {string | number} px 设计图中元素尺寸
 * @param {string} viewportUnit 转换后单位，默认vw
 * @param {object} config  px2viewport配置项
 * @param {number} config.viewportWidth 设计图尺寸
 * @param {number} config.unitPrecision 转换后保留位数
 * @returns {string} 转换后结果
 */
export function px2viewport(
  px,
  viewportUnit = 'vw',
  config = {
    viewportWidth: 375,
    unitPrecision: 3
  }
) {
  try {
    return (
      ((String(px).replace('px', '') / config.viewportWidth) * 100).toFixed(
        config.unitPrecision
      ) + viewportUnit
    )
  } catch (e) {
    return ''
  }
}

/**
 * 该函数用于将Vw换算为Px
 * @param {string | number} vw 元素vw尺寸
 * @param {string} viewportUnit 转换后单位，默认px
 * @param {object} config  px2viewport配置项
 * @param {number} config.viewportWidth 屏幕尺寸
 * @param {number} config.unitPrecision 转换后保留位数
 * @returns {string} 转换后结果
 */
export function vw2px(
  vw,
  viewportUnit = 'px',
  config = {
    viewportWidth: document.body.clientWidth || 375,
    unitPrecision: 3
  }
) {
  try {
    return (
      ((String(vw).replace('vw', '') * config.viewportWidth) / 100).toFixed(
        config.unitPrecision
      ) + viewportUnit
    )
  } catch (e) {
    return ''
  }
}

/**
 * 该函数用于将设计图Px换算为实际渲染Px
 * @param {string | number} px 设计图px
 * @param {string} viewportUnit 转换后单位，默认px
 * @returns {string} 转换后结果
 */
export function px2px(px, viewportUnit = 'px') {
  try {
    return vw2px(px2viewport(px), viewportUnit)
  } catch (e) {
    return ''
  }
}
