// 静态路由
export default [
  {
    path: '/pushsetting',
    name: 'pushsetting',
    component: () => import('@/views/push/PushSetting.vue'),
    meta: {
      header: true,
      title: '推送设置'
    }
  },
  {
    path: '/setactive',
    name: 'setactive',
    component: () => import('@/views/push/SetActive.vue'),
    meta: {
      header: true,
      title: '开通微信提醒步骤'
    }
  }
]
