import { dateFormatter, formatMoney, splitMoney } from '@jy/util'
import BaseModel from '../../../core/base'

/**
 * 摘要 Item 基础类
 */
class SummaryItem {
  constructor(key, label, value) {
    this.key = key
    this.label = label
    this.value = value || '' // 如果为空，默认值则改为'-'
    this.checkFreeView(key, label, value)
  }

  checkFreeView(key, label, value) {
    const isFreeView = value === 'freeView'
    const value2 = isFreeView ? '点击查看' : value
    this.value = value2 || ''
    if (isFreeView) {
      this.isFreeView = true
    }
  }

  addKey(key, value) {
    this[key] = value
  }
}
// 联系人类
class PersonTelSummaryItem extends SummaryItem {
  constructor(key, label, value, tel, expand) {
    super(key, label, value)
    this.expand = expand
    if (tel) {
      this.tel = tel
      this.type = 'contact'
    }
  }
}

class Unit {
  constructor(name, id) {
    this.name = name || ''
    this.id = id
  }
}
class Buyer extends Unit {
  constructor(name, id, { seoId, link }) {
    super(name, id)
    this.link = link
    this.type = 'buyer'
    this.seoId = seoId || ''
  }
}
class Winner extends Unit {
  constructor(
    name,
    id,
    { seoId, winnerPerson, winnerTel, link, isCandidate, personTel }
  ) {
    super(name, id)

    this.type = 'winner'
    this.seoId = seoId || ''
    this.winnerPerson = winnerPerson || ''
    this.winnerTel = winnerTel || ''
    this.isCandidate = isCandidate
    this.personTel = personTel
    this.link = link
  }
}

class SummaryModel extends BaseModel {
  createModel() {
    return {
      // 是否超前项目
      isProposed: false,
      // 原始数据
      originMap: {},
      list: [],
      // 采购单位
      buyers: [],
      // 中标单位
      winners: []
    }
  }

  formatModel(data) {
    const model = this.getSummaryContentObject(data)
    return model
  }

  getSummaryContentObject(data) {
    const { baseInfo, abstract } = data
    const isProposed = this.isProposedCheck(baseInfo?.subType)

    const model = isProposed
      ? this.tranSummaryOfProposed(abstract.proposed, baseInfo)
      : this.tranSummaryOfDefault(abstract.default, baseInfo)
    return model
  }

  isProposedCheck(type) {
    return type === '拟建'
    // return type === '拟建' || type === '采购意向'
  }

  formatTime(time, fmt = 'yyyy-MM-dd') {
    if (time) {
      return dateFormatter(time * 1000, fmt)
    }
    else {
      return time
    }
  }

  formatMoney(m) {
    return splitMoney(m, -1, true)
  }

  formatTel(name, tel) {
    if (name === 'freeView' || tel === 'freeView') {
      return 'freeView'
    }
    else {
      const arr = [name, tel]
      return arr.filter(item => !!item).join(' / ')
    }
  }

  tranSummaryOfDefault(summary, baseInfo = {}) {
    const result = this.createModel()
    if (!summary) {
      return result
    }

    // 阳光直采
    const IsSunPublishContent = baseInfo.infoAttribute === 'zc_cgxx' // 阳光直采-采购信息

    const list = []

    // 采购单位
    const buyerInfo = new SummaryItem('buyer', '采购单位', summary?.buyer)
    // 是否可进行跳转
    buyerInfo.addKey('link', summary?.buyerPortraitShow)
    list.push(buyerInfo)
    if (summary?.buyer) {
      result.buyers.push(
        new Buyer(summary?.buyer, summary?.buyer, {
          seoId: baseInfo?.buyerSeoId,
          link: summary?.buyerPortraitShow
        })
      )
    }

    // 采购人/联系电话
    const buyerContactInfoValue = this.formatTel(
      summary?.buyerPerson,
      summary?.buyerTel
    )
    const buyerContactInfo = new PersonTelSummaryItem(
      'buyerContactInfo',
      '采购联系人/电话',
      buyerContactInfoValue,
      summary?.buyerTel,
      buyerInfo
    )
    list.push(buyerContactInfo)

    // 招标代理机构
    list.push(new SummaryItem('agency', '招标代理机构', summary?.agency))

    // 代理联系人
    const agencyContactInfoValue = this.formatTel(
      summary?.agencyPerson,
      summary?.agencyTel
    )
    const agencyContactInfo = new PersonTelSummaryItem(
      'agencyContactInfo',
      '代理联系人/电话',
      agencyContactInfoValue,
      summary?.agencyTel
    )
    list.push(agencyContactInfo)

    // 截止日期
    if (IsSunPublishContent) {
      list.push(
        new SummaryItem(
          'signEndTime',
          '报名截止日期',
          this.formatTime(summary?.signEndTime, 'yyyy-MM-dd HH:mm')
        )
      )
    }
    else {
      list.push(
        new SummaryItem(
          'signEndTime',
          '报名截止日期',
          this.formatTime(summary?.signEndTime)
        )
      )
    }
    // 投标截止日期
    if (IsSunPublishContent) {
      // do something...
    }
    else {
      list.push(
        new SummaryItem(
          'bidEndTime',
          '投标截止日期',
          this.formatTime(summary?.bidEndTime)
        )
      )
    }

    // 中标单位
    if (Array.isArray(summary.winnerInfos) && summary.winnerInfos.length > 0) {
      const winnerList = []
      // const winnerSummaryList = []
      // 列表中是否有中标候选人
      let hasWaitWinner = false
      const winnerInfos = summary.winnerInfos
      let circularList = []
      const waitWinnerList = winnerInfos.filter(w => w.isCandidate)
      if (Array.isArray(waitWinnerList) && waitWinnerList.length > 0) {
        // 有中标候选人，则循环中标候选人
        hasWaitWinner = true
        circularList = waitWinnerList.slice(0, 1)
      }
      else {
        // 无中标候选人，则循环原始数据
        circularList = winnerInfos
      }

      for (let i = 0; i < circularList.length; i++) {
        const w = circularList[i]
        const index = i
        // 有中标候选人，则“中标单位”文案修改为”中标候选人“，仅展示排名第1的中标候选人，其他候选人不展示；
        const labelText = hasWaitWinner ? '中标候选人' : '中标单位'

        const summaryWinner = new SummaryItem('winner', labelText, w.winner)
        // 是否可跳转
        summaryWinner.addKey('link', !!w.winnerId)
        summaryWinner.addKey('id', w.winnerId)

        const wContactInfoValue = this.formatTel(w?.winnerPerson, w?.winnerTel)
        const winnerContactInfo = new PersonTelSummaryItem(
          `winnerContactInfo-${index}`,
          '中标联系人/电话',
          wContactInfoValue,
          w?.winnerTel,
          summaryWinner
        )

        list.push(summaryWinner)
        list.push(winnerContactInfo)

        let seoId = ''
        if (summary?.winnerSeoId) {
          seoId = summary?.winnerSeoId[w.winner]
        }
        winnerList.push(
          new Winner(w.winner, w.winnerId, {
            seoId: seoId || '',
            link: !!w.winnerId,
            winnerPerson: w.winnerPerson || '',
            winnerTel: w.winnerTel || '',
            isCandidate: w.isCandidate || false,
            personTel: winnerContactInfo
          })
        )
      }

      result.winners = winnerList
    }

    // 中标金额
    const bidAmountFormat = summary?.bidAmount
      ? this.formatMoney(summary?.bidAmount)
      : ''
    this.formatMoney()
    list.push(new SummaryItem('bidAmount', '中标金额（元）', bidAmountFormat))

    // 项目地区
    const pArea = baseInfo?.area || ''
    const pCity = baseInfo?.city || ''
    const pDistrict = baseInfo?.district || ''
    const projectArea = `${pArea}${pCity}${pDistrict}` || ''
    list.push(new SummaryItem('projectArea', '项目地区', projectArea))

    // 交付地点
    const jArea = summary?.deliverArea || ''
    const jCity = summary?.deliverCity || ''
    const jDistrict = summary?.deliverDistrict || ''
    const jDetail = summary?.deliverDetail || ''
    const jfArea = `${jArea}${jCity}${jDistrict}` || ''
    list.push(new SummaryItem('jfArea', '交付地点', `${jfArea}${jDetail}`))

    result.list = list.filter(s => !!s.value)
    result.originMap = summary

    // 项目清单
    result._s = summary
    result.purchasingList = summary.purchasingList
    if (Array.isArray(result.purchasingList)) {
      result.purchasingList = result.purchasingList.map((item) => {
        const numberUnit = item.number
          ? `${item.number}${item.unitName || ''}`
          : ''
        return {
          ...item,
          numberUnit
        }
      })
    }
    return result
  }

  tranSummaryOfProposed(summary, baseInfo = {}) {
    const result = this.createModel()
    if (!summary) {
      return result
    }

    const list = []

    const summaryMap = {
      projectName: '项目名称',
      area: '省份',
      buyer: '业主单位',
      buyerClass: '业主类型',
      totalInvestment: '总投资',
      projectPeriod: '建设年限',
      address: '建设地点',
      approveDept: '审批机关',
      approveContent: '审批事项',
      approveCode: '审批代码',
      approvalNumber: '批准文号',
      approveTime: '审批时间',
      approveStatus: '审批结果',
      content: '建设内容'
    }

    for (const key in summaryMap) {
      const label = summaryMap[key]
      let s = null
      if (key === 'buyerClass') {
        s = new SummaryItem(
          key,
          label,
          summary?.buyerClass === '其它' ? '' : summary?.buyerClass
        )
      }
      else if (key === 'totalInvestment') {
        s = new SummaryItem(key, label, formatMoney(summary[key]))
      }
      else {
        s = new SummaryItem(key, label, summary[key] || '')
      }

      if (key === 'buyer') {
        s.addKey('link', summary?.buyerPortraitShow) // 是否可进行跳转
        if (summary?.buyer) {
          result.buyers.push(
            new Buyer(summary?.buyer, summary?.buyer, {
              seoId: baseInfo?.buyerSeoId,
              link: summary?.buyerPortraitShow
            })
          )
        }
      }
      else if (key === 'address') {
        s.addKey('row', true)
      }
      else if (key === 'content') {
        s.addKey('row', true)
      }

      if (s) {
        list.push(s)
      }
    }

    result.list = list
    result.originMap = summary

    return result
  }
}

function useSummaryModel() {
  return new SummaryModel()
}

export default useSummaryModel
