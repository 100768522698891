import request from '@/api'
import qs from 'qs'

/**
 * 获取用户商机管理权限状态数据
 */
export function ajaxUserEntPower() {
  return request({
    url: '/entnicheNew/buy/whetherbuy',
    method: 'post'
  })
}

/**
 * 获取用户收藏标签
 */
export function ajaxGetUserKeepLabel() {
  return request({
    url: '/entnicheNew/customer/getLabel',
    method: 'post'
  })
}
/**
 * 添加用户收藏标签
 */
export function ajaxSetUserKeepLabel(data) {
  data = qs.stringify(data)
  return request({
    url: '/publicapply/bidcoll/addLabel',
    method: 'post',
    data
  })
}
/**
 * 采购单位关注、认领
 */
export function ajaxFollowAndClaim(data) {
  return request({
    url: '/entnicheNew/customer/attention',
    method: 'post',
    data
  })
}
/**
 * 历史客户列表
 */
export function ajaxHistoryCustomer(data) {
  return request({
    url: '/entnicheNew/customer/history',
    method: 'post',
    data
  })
}
/**
 * 获取业务范围（关键词）
 */
export function ajaxBusinessScope(data) {
  return request({
    url: '/entnicheNew/subscribe/key/get',
    method: 'post',
    data
  })
}
/**
 * 商机管理认领用户-添加自定义标签
 */
export function ajaxEntNicheKeepLabel(data) {
  data = qs.stringify(data)
  return request({
    url: '/entnicheNew/customer/addLabel',
    method: 'post',
    data
  })
}
/**
 * 商机管理-查看已认领的客户列表
 */
export function ajaxClaimCustomerList(data) {
  // data = qs.stringify(data)
  return request({
    url: '/entnicheNew/customer/claimcheck',
    method: 'post',
    data
  })
}
