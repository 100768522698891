// 企业注册资金
export const entMoneyData = [
  {
    label: '不限',
    key: ''
  },
  {
    label: '100万以内',
    key: '0-100'
  },
  {
    label: '100-500万',
    key: '100-500'
  },
  {
    label: '500-1000万',
    key: '500-1000'
  },
  {
    label: '1000-5000万',
    key: '1000-5000'
  },
  {
    label: '5000万以上',
    key: '5000-'
  },
  {
    label: '手动输入框',
    key: 'diy',
    slot: 'custom'
  },
  {
    label: '手动隐藏，实现无法全选',
    key: 'hidden',
    props: {
      style: {
        display: 'none'
      }
    }
  }
]
// 企业搜索范围
export const entScopeData = [
  {
    label: '企业名称',
    key: 'A'
  },
  {
    label: '法定代表人',
    key: 'B'
  },
  {
    label: '经营范围',
    key: 'F',
    vip: true,
    disabled: true
  },
  {
    label: '中标项目/标的物',
    key: 'E',
    vip: true,
    disabled: true
  }
]
// 企业类型
export const entTypeData = [
  {
    label: '全部',
    key: 'A,B,C,D'
  },
  {
    label: '有限责任公司',
    key: 'A'
  },
  {
    label: '股份有限公司',
    key: 'B'
  },
  {
    label: '有限合伙',
    key: 'C'
  },
  {
    label: '普通合伙',
    key: 'D'
  }
]

// 企业状态
export const entStatusData = [
  {
    label: '全部',
    key: 'A,B,C,D'
  },
  {
    label: '存续（在营、开业、在业）',
    key: 'A'
  },
  {
    label: '吊销',
    key: 'B'
  },
  {
    label: '注销',
    key: 'C'
  },
  {
    label: '撤销',
    key: 'D'
  }
]

// 企业成立时间
export const entFoundTimeData = [
  {
    label: '全部',
    key: ''
  },
  {
    label: '近1年内',
    key: '-1y'
  },
  {
    label: '1-3年',
    key: '1y-3y'
  },
  {
    label: '3-5年',
    key: '3y-5y'
  },
  {
    label: '5-10年',
    key: '5y-10y'
  },
  {
    label: '10年以上',
    key: '10y-'
  },
  {
    label: '自定义',
    key: 'exact'
  }
]

// 单位类型
export const unitTypeData = [
  {
    label: '不限',
    key: ''
  },
  {
    label: '采购单位',
    key: '1'
  },
  {
    label: '投标企业',
    key: '2'
  },
  {
    label: '招标代理机构',
    key: '3'
  },
  {
    label: '厂商',
    key: '4'
  }
]

// 联系方式
export const entContactData = [
  {
    label: '不限',
    key: ''
  },
  {
    label: '固定电话',
    key: '1'
  },
  {
    label: '手机号',
    key: '2'
  }
]
