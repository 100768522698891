import request from '@/api'
import qs from 'qs'

export function ajaxSetLogin(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}

/**
 * 获取客服二维码
 * @param data { type: "kf" } 只获取客服的二维码，
 * 不传参根据用户权限判断是否返回客成二维码
 * @returns {*}
 */
export function getCustomInfo (data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/use/getCustom',
    method: 'POST',
    data
  })
}

/**
 *  获取用户账号下多类型权益信息如，有大会员、超级订阅等权益
 * @returns {*}
 */
export function getUserAccountInfo() {
  return request({
    url: '/jypay/user/account/show',
    method: 'POST'
  })
}
