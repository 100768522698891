<template>
  <div class="j-steps">
    <div
      class="j-step step-line"
      :class="calcClassName(item)"
      v-for="(item, index) in stepList"
      :key="index"
    >
      <div
        class="step-items clickable"
        @click="linkTo(item)">
        <div
          class="step-item step-circle"
          :data-tip="index === 0 ? newTagText : ''"
          :class="{
            text: index === 0 && newTag
          }"
        >
          <slot name="tag-item" :step="item">
            <div class="tag-list">
              <span
                class="step-tag j-tag-item border main round"
                v-for="(tag, index) in item.tags"
                :key="index"
                v-show="tag"
                >{{ tag }}</span
              >
              <span class="step-tag item-time">{{ item.step_time }}</span>
              <span
                class="step-tag j-tag-item red round"
                v-if="activeTagShow(item)"
              >
                当前信息
              </span>
            </div>
            <span
              class="step-tag j-tag-item border round"
              v-if="item.step_bidamount"
            >
              {{ item.step_bidamount }}
            </span>
          </slot>
        </div>
        <div class="step-item" v-if="item.content">
          <span
            class="item-label content-container"
            v-html="item.content"
          ></span>
        </div>
        <div class="step-item step-footer">
          <slot name="step-footer" :step="item"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'time-line',
  props: {
    lineTheme: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'grey'].includes(value)
      }
    },
    lineThrough: {
      type: Boolean,
      default: false
    },
    newTag: {
      type: Boolean,
      default: false
    },
    newTagText: {
      type: String,
      default: '最新'
    },
    // 信息id，当此id与列表信息id匹配时，会显示<当前信息>tag
    markedSameId: {
      type: String,
      default: ''
    },
    stepList: {
      type: Array,
      default() {
        return [
          // {
          //   id: '226',
          //   time: '2024-02-26',
          //   bidamount: 11,
          //   tags: [111],
          //   content: '226-内容内容内容内容内容内容'
          // },
          // {
          //   id: '225',
          //   time: '2024-02-25',
          //   tags: [111, 222, 333],
          //   content: '225-内容内容内容内容内容内容'
          // },
          // {
          //   id: '224',
          //   time: '2024-02-24',
          //   tags: [111, 222, 333],
          //   content: '224-内容内容内容内容内容内容'
          // }
        ]
      }
    }
  },
  computed: {
    useLineThrough() {
      return this.lineThrough || this.newTag
    }
  },
  methods: {
    calcClassName(item) {
      const useThrough = this.useLineThrough ? '' : 'through'
      const useActive = this.activeTagShow(item) ? 'is-active' : ''
      return [this.lineTheme, useThrough, useActive]
    },
    activeTagShow(item) {
      return this.markedSameId === item.id || item.isActive
    },
    linkTo(item) {
      this.$emit('itemClick', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.j-steps {
  padding-left: 20px;
  overflow: hidden;
}

// .j-tag-item {
//   $blue: #05a6f3;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 3px 6px;
//   font-size: 12px;
//   height: 22px;
//   color: #5f5e64;
//   background-color: #f7f9fa;
//   &.border {
//     border: 1px solid rgba(0, 0, 0, 0.05);
//   }
//   &.main {
//     color: $main;
//     border-color: $main;
//     background-color: rgba($main, 0.1);
//   }
//   &.red {
//     color: $red;
//     border-color: $red;
//     background-color: rgba($red, 0.1);
//   }
//   &.orange {
//     color: $orange;
//     border-color: $orange;
//     background-color: rgba($orange, 0.1);
//   }
//   &.blue {
//     color: $blue;
//     border-color: $blue;
//     background-color: rgba($blue, 0.1);
//   }
//   &.round {
//     border-radius: 4px;
//   }
// }

.step-tag-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding: 0 6px;
  color: $color_main;
  font-size: 12px;
  line-height: 20px;
  border: 1px solid $color_main;
  border-radius: 5px;
}
.step-line {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 2px;
    width: 1px;
    height: 100%;
    background-color: #2abed1;
    transform: translate(50%, 0) scaleX(0.8);
  }
  &.through {
    &::before {
      content: '';
      top: 0;
    }
  }
  &.grey {
    &::before {
      background-color: #ececec;
    }
  }
}
.step-circle {
  position: relative;
  &.text {
    &::before {
      @extend .step-tag-main;
      content: attr(data-tip);
      left: -44px;
      width: unset;
      color: #fff;
      border: 1px solid $color_main;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -30px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: translate(0, -50%);
    background-color: $color_main;
    border: 2px solid #d2f6fc;
  }
}

.j-step {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  .step-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 26px;
    padding-right: 12px;
    .step-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      line-height: 22px;
      &:not(:first-of-type) {
        margin-bottom: 8px;
      }
    }
    .tag-list {
      display: flex;
    }
    .step-tag {
      margin-right: 8px;
    }
    .item-time {
      color: #999;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
.content-container {
  color: #5f5e64;
}

.step-footer {
  font-size: 12px;
  line-height: 18px;
  color: #9b9ca3;
}
</style>
