/* eslint-disable */
// 城市选择/筛选 - 省份与字母IndexBar对照数据
export const provinceListMapExp = {
  '#': ['全国'],
  A: ['安徽', '澳门'],
  B: ['北京'],
  C: ['重庆'],
  F: ['福建'],
  G: ['广东', '广西', '贵州', '甘肃'],
  H: ['河北', '湖北', '黑龙江', '海南', '河南', '湖南'],
  J: ['吉林', '江苏', '江西'],
  L: ['辽宁'],
  N: ['内蒙古', '宁夏'],
  Q: ['青海'],
  S: ['山西', '陕西', '上海', '山东', '四川'],
  T: ['天津', '台湾'],
  X: ['西藏', '新疆', '香港'],
  Y: ['云南'],
  Z: ['浙江']
}

// 行业
export const industryListMapExp = {
  建筑工程: ['勘察设计', '工程施工', '监理咨询', '材料设备', '机电安装', '其他'],
  水利水电: ['水利工程', '发电工程', '航运工程', '其他工程'],
  能源化工: ['原材料', '仪器仪表', '新能源', '设备物资', '化工产品', '设备', '其他'],
  弱电安防: ['综合布线', '智能系统', '智能家居', '其他'],
  信息技术: ['系统集成及安全', '软件开发', '运维服务', '其他'],
  行政办公: ['办公家具', '通用办公设备', '专业设备', '办公用品', '生活用品', '其他'],
  机械设备: [
    '矿山机械',
    '工程机械',
    '机械零部件',
    '机床相关',
    '车辆',
    '其他机械设备'
  ],
  交通工程: ['道路', '轨道', '桥梁', '隧道', '其他'],
  医疗卫生: ['设备', '耗材', '药品', '其他'],
  市政设施: ['道路', '绿化', '线路管网', '综合项目', '其他'],
  服务采购: [
    '法律咨询',
    '会计',
    '物业',
    '审计',
    '安保',
    '仓储物流',
    '广告宣传印刷',
    '其他'
  ],
  农林牧渔: ['生产物资', '生产设备', '相关服务', '其他'],
  其他: []
}

// 采购单位类型数据
export const cateListMapExp = {
  '#': ['全部'],
  C: ['财政', '传媒', '城管', '采矿业', '出版广电'],
  D: ['档案', '党委办', '电信行业'],
  F: ['法院', '发改'],
  G: ['工信', '公安', '国资委', '公共资源交易'],
  H: ['海关'],
  J: ['教育', '军队', '交通', '纪委', '金融业', '建筑业', '检察院', '机关事务'],
  K: ['科技'],
  M: ['民政', '民宗'],
  N: ['农业', '能源化工', '农林牧渔'],
  P: ['批发零售'],
  Q: ['气象'],
  R: ['人行', '人社', '人大'],
  S: [
    '税务',
    '水利',
    '市政',
    '审计',
    '商务',
    '司法',
    '社会团体',
    '市场监管',
    '生态环境'
  ],
  T: ['统计', '统战', '体育'],
  W: ['文旅', '卫健委'],
  X: ['学校', '宣传', '信息技术'],
  Y: ['医疗', '银保监', '运输物流', '应急管理'],
  Z: [
    '组织',
    '政协',
    '住建',
    '证监',
    '政府办',
    '制造业',
    '政务中心',
    '住宿餐饮',
    '自然资源'
  ]
}

// 信息类型数据
export const infoTypeMapExp = {
  拟建项目: ['拟建项目'],
  采购意向: ['采购意向'],
  招标预告: ['预告', '预审', '预审结果', '论证意见', '需求公示'],
  招标公告: ['招标', '邀标', '询价', '竞谈', '单一', '竞价', '变更'],
  招标结果: ['中标', '成交', '废标', '流标'],
  招标信用信息: ['合同', '验收', '违规']
}

// 信息类型筛选数据拆分(仅超前项目:采购意向，拟建项目)
export const infoTypeAdvancedList = [
  {
    name: '全部',
    value: '',
    level: 0,
    children: []
  },
  {
    name: '拟建项目',
    value: '拟建',
    showHelp: true,
    level: 1,
    children: []
  },
  {
    name: '采购意向',
    value: '采购意向',
    showHelp: true,
    level: 1,
    children: []
  }
]

// 信息类型筛选数据拆分(移除超前项目)
export const infoTypeNotAdvancedList = [
  {
    name: '全部',
    value: '',
    level: 0,
    children: []
  },
  {
    name: '招标预告',
    value: '招标预告',
    level: 1,
    children: [
      {
        name: '预告',
        value: '预告',
        level: 2
      },
      {
        name: '预审',
        value: '预审',
        level: 2
      },
      {
        name: '预审结果',
        value: '预审结果',
        level: 2
      },
      {
        name: '论证意见',
        value: '论证意见',
        level: 2
      },
      {
        name: '需求公示',
        value: '需求公示',
        level: 2
      }
    ]
  },
  {
    name: '招标公告',
    value: '招标公告',
    level: 1,
    children: [
      {
        name: '招标',
        value: '招标',
        level: 2
      },
      {
        name: '邀标',
        value: '邀标',
        level: 2
      },
      {
        name: '询价',
        value: '询价',
        level: 2
      },
      {
        name: '竞谈',
        value: '竞谈',
        level: 2
      },
      {
        name: '单一',
        value: '单一',
        level: 2
      },
      {
        name: '竞价',
        value: '竞价',
        level: 2
      },
      {
        name: '变更',
        value: '变更',
        level: 2
      }
      // {
      //   name: '其它',
      //   value: '其它',
      //   level: 2
      // }
    ]
  },
  {
    name: '招标结果',
    value: '招标结果',
    level: 1,
    children: [
      {
        name: '中标',
        value: '中标',
        level: 2
      },
      {
        name: '成交',
        value: '成交',
        level: 2
      },
      {
        name: '废标',
        value: '废标',
        level: 2
      },
      {
        name: '流标',
        value: '流标',
        level: 2
      }
    ]
  },
  {
    name: '招标信用信息',
    value: '招标信用信息',
    level: 1,
    children: [
      {
        name: '合同',
        value: '合同',
        level: 2
      },
      {
        name: '验收',
        value: '验收',
        level: 2
      },
      {
        name: '违规',
        value: '违规',
        level: 2
      }
    ]
  }
]

// 信息类型筛选数据(包含全部信息类型的完整数据)
export const infoTypeListExp = (function () {
  const arr = [...infoTypeAdvancedList]
  infoTypeNotAdvancedList.forEach((item) => {
    if (item.level !== 0 && item.value) {
      arr.push(item)
    }
  })
  return arr
})()

// 采购单位类型
export const buyerClassListMapExp = {
  党政机关事业单位: [
    '人大',
    '政协',
    '党委办',
    '组织',
    '宣传',
    '统战',
    '纪委',
    '政府办',
    '发改',
    '财政',
    '教育',
    '科技',
    '工信',
    '民政',
    '民宗',
    '人社',
    '公安',
    '检察院',
    '法院',
    '司法',
    '应急管理',
    '军队',
    '自然资源',
    '生态环境',
    '住建',
    '市政',
    '城管',
    '交通',
    '水利',
    '农业',
    '气象',
    '文旅',
    '卫健委',
    '医疗',
    '学校',
    '档案',
    '体育',
    '政务中心',
    '机关事务',
    '国资委',
    '海关',
    '税务',
    '市场监管',
    '商务',
    '人行',
    '银保监',
    '证监',
    '审计',
    '出版广电',
    '统计',
    '公共资源交易',
    '社会团体'
  ],
  企业: [
    '传媒',
    '采矿业',
    '电信行业',
    '金融业',
    '建筑业',
    '能源化工',
    '农林牧渔',
    '批发零售',
    '信息技术',
    '运输物流',
    '制造业',
    '住宿餐饮'
  ]
}

export const dateTimeKeys = [
  {
    label: '昨天',
    key: 'yesterday'
  },
  {
    label: '今天',
    key: 'today'
  },
  {
    label: '最近3天',
    key: 'lately3'
  },
  {
    label: '最近7天',
    key: 'lately7'
  },
  {
    label: '最近30天',
    key: 'lately30'
  },
  {
    label: '近3个月',
    key: 'lately90'
  },
  {
    label: '近半年',
    key: 'lately180'
  },
  {
    label: '去年',
    key: 'lastYear'
  },
  {
    label: '今年全年',
    key: 'thisYear'
  },
  {
    label: '去年至今',
    key: 'sinceLastYear'
  },
  {
    label: '前年至今',
    key: 'sinceYearBeforeLast'
  },
  {
    label: '最近1年',
    key: 'thisyear'
  },
  {
    label: '最近3年',
    key: 'threeyear'
  },
  {
    label: '最近5年',
    key: 'fiveyear'
  }
]

// 关键词匹配模式
export const wordsModeList = [
  {
    label: '包含所有关键词',
    key: '0'
  },
  {
    label: '包含任意关键词',
    key: '1'
  }
]

// 搜索模式列表
export const searchModeList = [
  {
    label: '精准搜索',
    key: '0'
  },
  {
    label: '模糊搜索',
    key: '1'
  }
]

// 领域数据源
export const lingyuDataSource = [
  {
    label: '货物',
    value: '',
    children: [  
      {  
        value: 'A01',  
        label: '房屋和构筑物',  
        children: [  
          { value: 'A01010000', label: '房屋' },  
          { value: 'A01020000', label: '构筑物' },  
          { value: 'A01030000', label: '土地' }  
        ]
      },  
      {  
        value: 'A02',  
        label: '设备',  
        children: [  
          { value: 'A02010000', label: '信息化设备' },  
          { value: 'A02020000', label: '办公设备' },  
          { value: 'A02030000', label: '车辆' },  
          { value: 'A02040000', label: '图书档案设备' },  
          { value: 'A02050000', label: '机械设备' },  
          { value: 'A02060000', label: '电气设备' },  
          { value: 'A02070000', label: '雷达、无线电和卫星导航设备' },  
          { value: 'A02080000', label: '通信设备' },  
          { value: 'A02090000', label: '广播、电视、电影设备' },  
          { value: 'A02100000', label: '仪器仪表' },  
          { value: 'A02110000', label: '电子和通信测量仪器' },  
          { value: 'A02120000', label: '计量标准器具及量具、衡器' },  
          { value: 'A02130000', label: '探矿、采矿、选矿和造块设备' },  
          { value: 'A02140000', label: '石油天然气开采设备' },  
          { value: 'A02150000', label: '石油和化学工业设备' },  
          { value: 'A02160000', label: '炼焦和金属冶炼轧制设备' },  
          { value: 'A02170000', label: '电力工业设备' },  
          { value: 'A02180000', label: '非金属矿物制品工业设备' },  
          { value: 'A02190000', label: '核工业设备' },  
          { value: 'A02200000', label: '航空航天工业设备' },  
          { value: 'A02210000', label: '工程机械' },  
          { value: 'A02220000', label: '农业和林业机械' },  
          { value: 'A02230000', label: '木材采集和加工设备' },  
          { value: 'A02240000', label: '食品加工设备' },  
          { value: 'A02250000', label: '饮料加工设备' },  
          { value: 'A02260000', label: '烟草加工设备' },  
          { value: 'A02270000', label: '粮油作物和饲料加工设备' },  
          { value: 'A02280000', label: '纺织设备' },  
          { value: 'A02290000', label: '缝纫、服饰、制革和毛皮加工设备' },  
          { value: 'A02300000', label: '造纸和印刷机械' },  
          { value: 'A02310000', label: '化学药品和中药设备' },  
          { value: 'A02320000', label: '医疗设备' },  
          { value: 'A02330000', label: '电工、电子生产设备' },  
          { value: 'A02340000', label: '安全生产设备' },  
          { value: 'A02350000', label: '邮政设备' },  
          { value: 'A02360000', label: '环境污染防治设备' },  
          { value: 'A02370000', label: '政法、消防、检测设备' },  
          { value: 'A02380000', label: '水工机械' },  
          { value: 'A02390000', label: '货币处理设备' },  
          { value: 'A02400000', label: '殡葬设备及用品' },  
          { value: 'A02410000', label: '铁路运输设备' },  
          { value: 'A02420000', label: '水上交通运输设备' },  
          { value: 'A02430000', label: '航空器及其配套设备' },  
          { value: 'A02440000', label: '海洋仪器设备' },  
          { value: 'A02450000', label: '文艺设备' },  
          { value: 'A02460000', label: '体育设备设施' },  
          { value: 'A02470000', label: '娱乐设备' }  
        ]  
      },  
      {  
        value: 'A03',  
        label: '文物和陈列品',  
        children: [  
          { value: 'A03020000', label: '可移动文物' },  
          { value: 'A03030000', label: '文创衍生品' },  
          { value: 'A03040000', label: '标本' },  
          { value: 'A03050000', label: '模型' }  
        ]  
      },  
      {  
        value: 'A04',  
        label: '图书和档案',  
        children: [  
          { value: 'A04010000', label: '图书' },  
          { value: 'A04020000', label: '期刊' },  
          { value: 'A04030000', label: '资料' },  
          { value: 'A04040000', label: '档案' }  
        ]  
      },  
      {  
        value: 'A05',  
        label: '家具和用具',  
        children: [  
          { value: 'A05010000', label: '家具' },  
          { value: 'A05020000', label: '用具' },  
          { value: 'A05030000', label: '装具' },  
          { value: 'A05040000', label: '办公用品' }  
        ]  
      },  
      {  
        value: 'A06',  
        label: '特种动植物',  
        children: [  
          { value: 'A06010000', label: '特种用途动物' },  
          { value: 'A06020000', label: '特种用途植物' }  
        ]  
      },
      {  
        value: 'A07',  
        label: '物资',  
        children: [  
          {  
            value: 'A07010000',  
            label: '建筑建材'  
          },  
          {  
            value: 'A07020000',  
            label: '医药品'  
          },  
          {  
            value: 'A07030000',  
            label: '农林牧渔业产品'  
          },  
          {  
            value: 'A07040000',  
            label: '矿与矿物'  
          },  
          {  
            value: 'A07050000',  
            label: '电力、城市燃气、蒸汽和热水、水'  
          },  
          {  
            value: 'A07060000',  
            label: '食品、饮料和烟草原料'  
          },  
          {  
            value: 'A07070000',  
            label: '炼焦产品、炼油产品'  
          },  
          {  
            value: 'A07080000',  
            label: '基础化学品及相关产品'  
          },  
          {  
            value: 'A07090000',  
            label: '橡胶、塑料、玻璃和陶瓷制品'  
          },  
          {  
            value: 'A07100000',  
            label: '纸及纸质品'  
          }  
        ]  
      },  
      {  
        value: 'A08',  
        label: '无形资产',  
        children: [  
          {  
            value: 'A08010000',  
            label: '专利类无形资产'  
          },  
          {  
            value: 'A08020000',  
            label: '非专利技术类无形资产'  
          },  
          {  
            value: 'A08030000',  
            label: '著作权类无形资产'  
          },  
          {  
            value: 'A08040000',  
            label: '资源资质类无形资产'  
          },  
          {  
            value: 'A08050000',  
            label: '商标权类无形资产'  
          },  
          {  
            value: 'A08060000',  
            label: '信息数据类无形资产'  
          },  
          {  
            value: 'A08070000',  
            label: '经营类无形资产'  
          }  
        ]  
      }  
    ]
  }
]

// 省份列表(前12个为产品定的热门省份)
export const onlyProvinceList = [
  '北京',
  '广东',
  '山东',
  '河南',
  '浙江',
  '江苏',
  '陕西',
  '上海',
  '四川',
  '湖北',
  '福建',
  '河北',
  '安徽',
  '湖南',
  '辽宁',
  '江西',
  '山西',
  '云南',
  '新疆',
  '重庆',
  '广西',
  '吉林',
  '贵州',
  '天津',
  '甘肃',
  '黑龙江',
  '内蒙古',
  '宁夏',
  '海南',
  '青海',
  '西藏',
  '香港',
  '澳门',
  '台湾'
]
