import router from '../router'
router.afterEach(() => {
  try {
    const nowLink = location.href.replace(location.origin, '')
    window._hmt.push(['_trackPageview', nowLink])
  } catch (error) {
    console.warn(error)
  }
  try {
    window.__EasyJTrack.manualReportPV()
  } catch (e) {
    console.warn('Warn manualReportPV', e)
  }
})
