export default [
  {
    path: '/my_points',
    name: 'myPoints',
    component: () => import('@/views/points/myPoints.vue'),
    meta: {
      header: true,
      title: '我的剑鱼币',
      showShareBtn: true
    }
  },
  {
    path: '/earn',
    name: 'earnPoints',
    component: () => import('@/views/points/earn.vue'),
    meta: {
      header: true,
      title: '赚剑鱼币',
      showShareBtn: false
    }
  },
  {
    path: '/detail',
    name: 'pointsDetail',
    component: () => import('@/views/points/detail.vue'),
    meta: {
      header: true,
      title: '剑鱼币明细',
      showShareBtn: true
    }
  }
]
