<template>
  <div class="drop-filter--layout">
    <van-sticky ref="sticky-node" :offset-top="getStickyTop">
      <van-dropdown-menu
        class="drop-filter--tab-menu dropdown-menu-arrow"
        v-bind="dropdownMenuOptions"
      >
        <van-dropdown-item
          v-for="(option, index) in getOptions"
          :key="option._updateKey || index"
          :ref="getPrefix.item + index"
          :title="option.menu.title"
          :get-container="getObjectValue(option.menu, 'getContainer', '')"
          v-bind="getObjectValue(option, 'props')"
          @opened="openDropItem(option, index)"
          @close="closeDropItem(option, index)"
        >
          <!-- 菜单标题 -->
          <template #title>
            <div
              class="drop-filter--tab-menu-title"
              :class="{
                selected:
                  typeof option.menu.selected === 'boolean'
                    ? option.menu.selected
                    : diffValue(option.key)
              }"
              v-on="getObjectValue(option.menu, 'hooks')"
            >
              <span>{{ option.menu.title }}</span>
              <slot
                v-if="option.menu.slot"
                :name="option.menu.slot"
                :option="option"
              />
              <div v-if="option.menu.tag" class="jy-tag">
                {{ option.menu.tag }}
              </div>
            </div>
          </template>
          <!-- 内容区域 -->
          <div
            class="drop-filter--tab-content"
            :class="calcDropdownItemClassName(option, index)"
          >
            <DropdownLayout
              :max-height="calcMaxHeight"
              @cancel="onItemCancel(option, index)"
              @confirm="onItemConfirm(option, index)"
            >
              <!-- @component 自定义组件, 自定义插槽提供 $index 索引  -->
              <Component
                :is="option.content.component"
                v-if="option.content.component"
                :ref="getPrefix.component + index"
                v-bind="option.content.props"
                v-on="{
                  ...getObjectValue(option.content, 'hooks'),
                  [option.content.hooks.cancel]: () =>
                    onItemCancel(option, index),
                  [option.content.hooks.confirm]: () =>
                    onItemConfirm(option, index)
                }"
              />
              <!-- @slot 默认插槽提供 item, 自定义插槽提供 $index 索引  -->
              <slot
                v-else-if="option.content.slot"
                :ref="getPrefix.slot + index"
                :name="option.content.slot"
                :option="option"
                :$index="index"
              />
              <div v-else>
                {{ option.menu.title }}
              </div>
              <template
                v-if="
                  option.content.hooks.confirm || option.content.hooks.cancel
                "
                #footer
              >
                <div />
              </template>
            </DropdownLayout>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </van-sticky>
  </div>
</template>

<script>
import { DropdownItem, DropdownMenu, Sticky } from 'vant'
import { isEqual } from 'lodash'
import DropdownLayout from '@/components/common/DropdownLayout'

export default {
  name: 'DropFilter',
  components: {
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Sticky.name]: Sticky,
    [DropdownLayout.name]: DropdownLayout
  },
  model: {
    prop: 'state',
    model: 'input'
  },
  props: {
    dropdownMenuMaxHeight: [String, Number],
    /**
     * v-model 缓冲区状态，获取组件数据状态
     *
     * key: 组件key,对应 options.item.key
     *
     * value: 组件当前数据状态，同组件 getState, setState 数据格式
     */
    state: {
      type: Object,
      default: () => {}
    },
    /**
     * 默认状态，用于重置时恢复组件数据状态
     *
     * 同 state, 仅用于 setState 恢复组件状态
     */
    defaultState: {
      type: Object,
      default: () => {}
    },
    // 绑定在dropdownMenu上的props
    dropdownMenuOptions: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * 配置数组
     *
     * [option, { key, menu, content }]
     *
     * key: 组件唯一 key，用于对应缓存区、默认的组件状态数据
     *
     * menu.title 菜单标题
     *
     * menu.selected Boolean 自由控制菜单是否选中状态 (默认对比缓存区数据与默认值)
     *
     * menu.tag 菜单标签文字 (开通)
     *
     * content.component 组件
     *
     * content.slot 插槽名称 (插槽状态下不支持 hooks 事件冒泡，需注意)
     *
     * content.props 组件 props
     *
     * content.hooks 用于自定义底部重置、确认按钮事件
     *
     * content.hooks.cancel 绑定组件用于触发 cancel 事件的函数名称
     *
     * content.hooks.confirm 绑定组件用于触发 confirm 事件的函数名称
     *
     */
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isMounted: false,
      maxHeight: '100%'
    }
  },
  computed: {
    calcMaxHeight() {
      if (this.dropdownMenuMaxHeight) {
        return this.dropdownMenuMaxHeight
      } else {
        return this.maxHeight
      }
    },
    getOptions() {
      return this.options.map((v) => {
        const option = Object.assign({}, v)
        if (!option.content?.hooks) {
          option.content.hooks = {}
        }
        return option
      })
    },
    getPrefix() {
      return {
        item: 'drop-item-',
        component: 'drop-component-',
        slot: 'drop-slot-'
      }
    },
    getStickyTop() {
      if (!this.isMounted) {
        return 0
      }
      return this.$refs['sticky-node']?.$el?.getBoundingClientRect().top
    }
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    /**
     * 打开弹出层事件
     * @param option
     * @param index
     */
    openDropItem(option, index) {
      console.log('openDropItem')
      const vNode = this.getOptionNode(option, index)
      // 从缓冲区恢复组件状态
      this.setStateOfKey(option.key, vNode)
      /**
       * 打开弹出层事件 ({ option, index, $ref })
       * @event open
       */
      this.$emit('open', {
        option,
        index,
        $ref: vNode
      })
      // 高度修正
      this.onResizeDropDownItemHeight(this.getPrefix.item + index)
    },
    closeDropItem(option, index) {
      const vNode = this.getOptionNode(option, index)
      this.$emit('close', {
        option,
        index,
        $ref: vNode
      })
    },
    /**
     * 弹出层重置事件
     * @param option
     * @param index
     */
    onItemCancel(option, index) {
      console.log('onItemCancel')
      const vNode = this.getOptionNode(option, index)
      const hasState = Object.prototype.hasOwnProperty.call(
        this.defaultState,
        option.key
      )

      // 使用默认值，重置缓冲区数据
      if (hasState) {
        /**
         * v-model 同步缓冲区数据 (state)
         * @event input
         */
        this.$emit(
          'input',
          Object.assign({}, this.state, {
            [option.key]: this.defaultState[option.key]
          })
        )
        this.$nextTick(() => {
          // 从缓冲区恢复组件状态
          this.setStateOfKey(option.key, vNode)
        })
      }

      /**
       * 弹出层重置事件 ({ option, index, $ref })
       * @event cancel
       */
      this.$emit('cancel', {
        option,
        index,
        $ref: vNode
      })
      this.togglePop(index)
    },
    /**
     * 弹出层确认事件
     * @param option
     * @param index
     */
    onItemConfirm(option, index) {
      console.log('onItemConfirm')
      const vNode = this.getOptionNode(option, index)
      const hasGetState = typeof vNode?.getState === 'function'

      // 将当前组件状态，同步到缓冲区数据
      if (hasGetState) {
        /**
         * v-model 同步缓冲区数据 (state)
         * @event input
         */
        this.$emit(
          'input',
          Object.assign({}, this.state, {
            [option.key]: this.callFnOfNode(vNode, 'getState')()
          })
        )
      }

      /**
       * 弹出层确认事件 ({ option, index, $ref })
       * @event confirm
       */
      this.$emit('confirm', {
        option,
        index,
        $ref: vNode
      })
      this.togglePop(index)
    },
    /**
     * 切换弹出层展示
     * @param index
     */
    togglePop(index, show) {
      this.$refs[this.getPrefix.item + index][0]?.toggle(show)
    },
    /**
     * 修正弹出层高度
     * @param key
     */
    onResizeDropDownItemHeight(key) {
      let refNode = this.$refs[key]
      if (Array.isArray(refNode)) {
        refNode = refNode[0]
      }
      const maxHeight =
        refNode?.$refs?.wrapper?.getBoundingClientRect().height || ''
      this.maxHeight = `${maxHeight}px`
    },
    /**
     * 从缓存区恢复组件状态
     * @param key
     * @param vNode
     */
    setStateOfKey(key, vNode) {
      const hasState = Object.prototype.hasOwnProperty.call(this.state, key)
      if (hasState) {
        this.callFnOfNode(vNode, 'setState')(this.state[key])
      }
    },
    /**
     * 获取指定的 $ref
     * @param option
     * @param index
     * @returns {null}
     */
    getOptionNode(option, index) {
      let vNode = null
      if (option.content.component) {
        vNode = this.$refs[this.getPrefix.component + index]
      } else if (option.content.slot) {
        vNode = this.$refs[this.getPrefix.slot + index]
      }
      if (Array.isArray(vNode)) {
        vNode = vNode[0]
      }
      return vNode
    },
    /**
     * 调用 $ref 函数
     * @param vNode
     * @param fn
     * @returns {*|(function(): null)}
     */
    callFnOfNode(vNode, fn = '') {
      const defaultFn = () => null
      if (vNode) {
        return typeof vNode[fn] === 'function' ? vNode[fn] : defaultFn
      }
      return defaultFn
    },
    getObjectValue(option, key, defaultType = {}) {
      if (option[key]) {
        return option[key]
      } else {
        return defaultType
      }
    },
    calcDropdownItemClassName(option, index) {
      const prefix = 'drop-filter--item-'
      let suffix = index
      if (option && option.key) {
        suffix = option.key
      }
      return `${prefix}${suffix}`
    },
    /**
     * 对比默认值与当前缓存区数据状态是否一致
     * @param key
     * @returns {boolean}
     */
    diffValue(key) {
      return !isEqual(this.state[key], this.defaultState[key])
    }
  }
}
</script>

<style lang="scss" scoped>
.drop-filter-- {
  &tab-menu {
    ::v-deep {
      .van-dropdown-menu__bar {
        box-shadow: unset;
      }
      .van-dropdown-menu {
        color: $gray_7;
      }
      .van-dropdown-menu__title {
        color: $gray_9;
        &--active {
          color: $main;
        }
      }
      .van-dropdown-menu__title--active {
        color: $main;
      }
    }
    &-title {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      &.selected {
        color: $main;
      }
    }
  }
  &tab-content {
    max-width: 100%;
    overflow: hidden;
  }
  &list {
    .mt-100 {
      margin-top: -100px;
    }
  }
}
</style>
