// 默认 request
let globalRequest = {
  request: function() {
    console.warn('Stop use default request !!!');
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({})
      }, 1000)
    })
  }
}

function injectRequest(server) {
  globalRequest.request = server
}


function useRequest(...arg) {
  // 使用传递的 request
  return globalRequest.request.apply(this, arg)
}

export { useRequest, injectRequest }

