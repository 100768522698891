export default [
  // 移动端采购单位画像中转路由
  {
    path: '/unit_portrayal/:id',
    name: 'unit_portrayal',
    component: () => import('@/views/redirect/UnitPortrayal.vue'),
    meta: {
      header: true,
      title: '采购单位画像'
    }
  }
]
