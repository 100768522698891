import MonitorEntApi from '../plugins/ent-api'
import MonitorProjectApi from '../plugins/project-api'
import MonitorClientApi from '../plugins/client-api'

/**
 * 一键使用项目监控、企业监控功能
 * 1. 根据 type 查询当前监控信息
 * 2. 返回监控信息及改变监控状态的事件钩子 { state, doChange }
 */

const APIS = {
  project: MonitorProjectApi,
  ent: MonitorEntApi,
  client: MonitorClientApi
}
function useQuickMonitor({ type, params }) {
  const useMonitorApi = APIS[type]
  return new useMonitorApi(params)
}

export default useQuickMonitor
