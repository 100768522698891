<template>
  <section
    class="project-cell cell-clickable"
    v-on="$listeners"
    :class="{
      visited: visited,
      'colorful-cell': isNotDefault,
      'jy-hairline--bottom': line
    }"
  >
    <div class="title-group">
      <div
        class="title"
        :class="{ 'van-multi-ellipsis--l5': ellipsis }"
        @click="$emit('click-title')"
      >
        <span v-if="isDefault && isFile" class="title-file">
          <slot>{{ fileText }}</slot>
        </span>
        <span v-html="getTitle"></span>
        <span v-if="filetext_search" style="padding-left: 8px"
          >(<span class="highlight-text-orange-bd">{{ setKeyLength }}</span
          >在附件中)</span
        >
      </div>
      <div class="title-icon" v-if="isDefault">
        <slot name="icon"></slot>
      </div>
    </div>
    <div class="info-group">
      <template v-if="isDefault">
        <div class="tag-group">
          <span
            class="tag-item"
            :class="item.className"
            v-for="(item, index) in tagList"
            :key="index"
            @click="$emit('click-tag', item)"
          >
            <slot name="tag-item">{{ item.name }}</slot>
          </span>
        </div>
        <span class="tag-item--time">{{ getTime }}</span>
      </template>
      <template v-else>
        <div class="tag-group">
          <slot name="left-top-badge">
            <div class="tag-item red" v-if="leftTopBadgeText">
              {{ leftTopBadgeText }}
            </div>
          </slot>
          <span
            v-if="pushSourceTag.text"
            class="tag-item push-source-tag"
            :class="pushSourceTag.className"
            >{{ pushSourceTag.text }}</span
          >
          <span class="tag-item red" v-if="publishType">
            <slot name="tag-item">{{ publishType }}</slot>
          </span>
          <span v-if="isFile && fileText" class="tag-item title-file main">
            <slot>{{ fileText.replace(/[\[\]]/g, '') }}</slot>
          </span>
          <span
            class="tag-item"
            :class="item.className"
            v-for="(item, index) in tagList"
            :key="index"
            @click="$emit('click-tag', item)"
          >
            <slot name="tag-item">{{ item.name }}</slot>
          </span>
        </div>
      </template>
    </div>
    <div
      class="detail"
      v-if="detail && getDetail"
      :class="{ 'van-ellipsis': ellipsis }"
      v-html="getDetail"
    ></div>
    <div
      class="more-detail detail-list"
      v-show="isDetailed && detailList.length"
    >
      <div
        class="detail-item"
        v-for="(item, index) in detailList"
        :class="item.className"
        v-show="item.text"
        :key="index"
      >
        <!-- 使用此处插槽必须保证 item.text 有值 -->
        <slot
          v-if="item.detailItemSlot"
          :name="item.detailItemSlot"
          :item="item"
        ></slot>
        <template v-else>
          <span class="detail-label">{{ item.label }}</span>
          <span class="detail-splitter">{{ item.splitter }}</span>
          <span
            class="detail-text"
            :class="{ 'highlight-text': item.highlightText }"
            key="detail-text"
          >
            <template v-if="item.detailTextSlot">
              <slot :name="item.detailTextSlot" :item="item"></slot>
            </template>
            <template v-else>{{ item.text }}</template>
          </span>
        </template>
      </div>
    </div>
    <div class="footer-container">
      <template v-if="isDefault">
        <slot name="left-top-badge">
          <div class="badge left-top red" v-if="leftTopBadgeText">
            {{ leftTopBadgeText }}
          </div>
        </slot>
        <div class="footer-container">
          <div class="tag-group" v-if="pushSourceTag.text">
            <span
              class="tag-item push-source-tag"
              :class="pushSourceTag.className"
              >{{ pushSourceTag.text }}</span
            >
          </div>
        </div>
      </template>
      <template v-else>
        <span class="tag-item--time">{{ getTime }}</span>
        <div class="title-icon">
          <slot name="icon"></slot>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { dateFormatter, dateFromNow, replaceKeyword } from '@/utils'
import { AppIcon } from '@/ui'

export default {
  name: 'ProjectCell',
  components: {
    [AppIcon.name]: AppIcon
  },
  props: {
    /**
     * cell的状态
     * ''为默认值，表示最初的样式
     * simplify 表示精简列表
     * detailed  表示详细列表
     */
    cardType: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'simplify', 'detailed'].includes(value)
      }
    },
    title: {
      type: String,
      default: ''
    },
    // 发布平台标签
    publishType: {
      type: String,
      default: ''
    },
    ellipsis: {
      type: Boolean,
      default: true
    },
    line: {
      type: Boolean,
      default: true
    },
    detail: {
      type: String,
      default: ''
    },
    // 有匹配词且匹配上才显示正文 是否开启
    autoShowDetail: {
      type: Boolean,
      default: true
    },
    filetext_search: {
      type: Boolean,
      default: false
    },
    visited: {
      type: Boolean,
      default: false
    },
    keys: {
      type: Array,
      default() {
        return []
      }
    },
    fs_keys: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * ['string', 'string']
     * 或者
     * [{ name: 'xxx', className: 'grey' }]
     */
    tags: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * [
     *   {
     *     label: 'xxx',
     *     highlightText: true,
     *     splitter: ':',
     *     className: ':',
     *     text: '123123'
     *   }
     * ]
     */
    detailList: {
      type: Array,
      default() {
        return []
      }
    },
    time: {
      type: [Number, String],
      default: ''
    },
    timeFmt: {
      type: String,
      default: ''
    },
    /**
     * 左上角角标
     */
    leftTopBadgeText: {
      type: String,
      default: ''
    },
    isFile: {
      type: Boolean,
      default: false
    },
    // 1个人订阅 2企业自动分发 3企业手动分发 0或者空则不显示
    pushSource: {
      type: Number,
      default: 0
    },
    fileText: {
      type: String,
      default: '[有附件]'
    }
  },
  data() {
    return {
      tagSourceMap: {
        1: {
          className: 'main',
          text: '个人订阅'
        },
        2: {
          className: 'main',
          text: '企业自动分发'
        },
        3: {
          className: 'main',
          text: '企业手动分发'
        }
      }
    }
  },
  computed: {
    isDefault() {
      return this.cardType === ''
    },
    isSimplify() {
      return this.cardType === 'simplify'
    },
    isDetailed() {
      return this.cardType === 'detailed'
    },
    isNotDefault() {
      return this.isSimplify || this.isDetailed
    },
    tagList() {
      return this.tags.map((tag) => {
        if (!tag || typeof tag === 'string') {
          return {
            name: tag,
            className: 'grey'
          }
        } else {
          return tag
        }
      })
    },
    pushSourceTag() {
      return this.tagSourceMap[this.pushSource] || {}
    },
    getTitle() {
      return replaceKeyword(this.title, this.keys, [
        '<span class="highlight-text-orange-bd">',
        '</span>'
      ])
    },
    getDetail() {
      if (this.autoShowDetail) {
        // 有匹配词且匹配上才显示正文
        if (this.setDetailText) {
          return replaceKeyword(this.setDetailText, this.keys, [
            '<span class="highlight-text-orange-bd">',
            '</span>'
          ])
        } else {
          return ''
        }
      } else {
        return replaceKeyword(this.detail, this.keys, [
          '<span class="highlight-text-orange-bd">',
          '</span>'
        ])
      }
    },
    // 正文开头和高亮词之间，最多保留10个字符
    setDetailText() {
      // 正则匹配标签置为空
      if (!this.detail) return ''
      const detail = this.detail.replace(/<[^>]+>/g, '')
      const detailLower = detail.toLowerCase()
      const keyLower = this.keys[0]?.toLowerCase()
      let postion = detailLower.indexOf(keyLower)
      if (postion !== -1) {
        if (postion - 10 > 0) {
          return detail.substring(postion - 10)
        } else {
          return detail
        }
      } else {
        return ''
      }
    },
    getTime() {
      if (this.timeFmt) {
        return dateFormatter(this.time, this.timeFmt)
      } else {
        return dateFromNow(this.time)
      }
    },
    setKeyLength() {
      let key = this.keys[0]
      if (this.fs_keys && this.fs_keys[0]) {
        key = this.fs_keys[0]
      }
      if (key) {
        if (key.length > 3) {
          return key.substring(0, 3) + '...'
        } else {
          return key
        }
      } else {
        return ''
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 500;
}
.project-cell {
  position: relative;
  margin: 0 8px;
  border-radius: 12px;
  padding: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #171826;
  background-color: $white;
  .badge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    border-radius: 0px 0px 12px 0px;
    font-size: 12px;
    line-height: 19px;
    &.left-top {
      left: 0;
      top: 0;
    }
    &.red {
      color: $orange_red;
      background-color: $color_red_background;
    }
  }
  .title-group {
    margin: 4px 0 12px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .title {
      flex: 1;
    }
    .title-file {
      margin-right: 4px;
      color: $color_main;
      font-size: 16px;
    }
  }
  .title-icon {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 18px;
    font-size: 14px;
    line-height: 20px;
    color: $gray_7;
    > div,
    span {
      display: flex;
      align-items: center;
    }
  }
  .tag-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  .tag-item {
    margin: 2px 2px 4px;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1px 8px;
    white-space: nowrap;
    border: 0.5px solid transparent;
    &.grey {
      color: #5f5e64;
      background: #f7f9fa;
      border-color: rgba(0, 0, 0, 0.05);
    }
    &.main {
      color: $main;
      background: $color_main_background;
      border-color: $color_main_background;
    }
    &.orange {
      color: $gold_deep;
      background: $color_gold_deep_background;
      border-color: $color_gold_deep_background;
    }
    &.red {
      color: $orange_red;
      background-color: $color_red_background;
      &.border {
        border: 1px solid $orange_red;
      }
    }
    &--time {
      color: #9b9ca3;
      white-space: nowrap;
    }
  }
  .info-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
  .detail {
    font-size: 13px;
    line-height: 20px;
    color: #5f5e64;
    margin-top: 12px;
    margin-bottom: 4px;
  }
  &.visited,
  &.hover {
    .title,
    .detail {
      color: #c0c4cc;
    }
    .tag-item {
      opacity: 0.6;
    }
  }
  .footer-container {
    .tag-group {
      margin-top: 6px;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .more-detail {
    margin-bottom: 4px;
  }
  .detail-item {
    font-size: 13px;
    line-height: 20px;
    color: $gray_9;
  }
  .detail-label {
    color: $gray_7;
  }
  &.colorful-cell {
    display: flex;
    flex-direction: column;
    .title-group {
      margin: 4px 0;
    }
    .info-group {
      order: 4;
      margin-bottom: 2px;
      align-items: flex-start;
      flex-direction: column;
    }
    .detail {
      order: 2;
      margin-top: 0;
      margin-bottom: 4px;
    }
    .more-detail {
      order: 6;
    }
    .tag-item {
      &--time {
        font-size: 12px;
      }
    }
    .footer-container {
      order: 8;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
