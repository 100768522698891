<template>
  <div class="buyer-cell cell-clickable">
    <div class="buyer-top" @click="$emit('cellClick')">
      <div class="buyer-icon">
        <slot name="custom-icon" :title="title">
          <icon :name="icon" :color="color" :size="20"></icon>
        </slot>
      </div>
      <div class="buyer-info">
        <div class="buyer-title">{{ title }}</div>
        <div class="buyer-sub-title">
          <p v-for="(val, key, index) in tags" :key="index">
            <span :style="{ color: tagKeyColor }">{{ key }}</span>
            <span class="tag-val">{{ val }}</span>
          </p>
        </div>
      </div>
    </div>
    <slot name="default"></slot>
    <div
      v-if="showAttentionAction || showClaimAction"
      :class="{ 'action-split-line': actionSplitLine }"
    ></div>
    <div class="buyer-bottom">
      <div
        class="action-item"
        @click.stop="$emit('action-left')"
        v-if="showAttentionAction"
      >
        <icon
          :name="followVal ? checkedFollowIcon : followIcon"
          :color="followVal ? '#ff9f40' : '#C0C4CC'"
          size="20"
        ></icon>
        <span class="action-item-text">{{
          followVal ? checkedFollowText : followText
        }}</span>
      </div>
      <div
        class="action-item"
        @click.stop="$emit('action-right')"
        v-if="showClaimAction"
      >
        <icon
          :name="claimVal ? checkedClaimIcon : claimIcon"
          :color="claimVal ? '#2ABED1' : '#C0C4CC'"
          size="20"
        ></icon>
        <span class="action-item-text">{{
          claimVal ? checkedClaimText : claimText
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/ui/icon/'
export default {
  name: 'company-cell',
  components: {
    Icon
  },
  props: {
    /**
     * 标题图标
     */
    icon: {
      type: String,
      default: 'mine_company'
    },
    /**
     * 标题图标颜色
     */
    color: {
      type: String,
      default: '#2ABED1'
    },
    /**
     * 标题文案
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * 副标题标签
     */
    tags: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * 是否展示认领图标
     */
    showClaimAction: {
      type: Boolean,
      default: false
    },
    /**
     * 是否展示关注图标
     */
    showAttentionAction: {
      type: Boolean,
      default: false
    },
    /**
     * 是否关注
     */
    isFollow: {
      type: Boolean,
      default: false
    },
    /**
     * 是否认领
     */
    isClaim: {
      type: Boolean,
      default: false
    },
    /**
     * 未关注图标
     */
    followIcon: {
      type: String,
      default: 'guanzhu_weiguanzhu'
    },
    /**
     * 已关注图标
     */
    checkedFollowIcon: {
      type: String,
      default: 'guanzhu_yiguanzhu'
    },
    /**
     * 未认领图标
     */
    claimIcon: {
      type: String,
      default: 'renling_weirenling'
    },
    /**
     * 已认领图标
     */
    checkedClaimIcon: {
      type: String,
      default: 'renling_yirenling'
    },
    /**
     * 未关注文案
     */
    followText: {
      type: String,
      default: '关注'
    },
    /**
     * 已关注文案
     */
    checkedFollowText: {
      type: String,
      default: '已关注'
    },
    /**
     * 未认领文案
     */
    claimText: {
      type: String,
      default: '认领'
    },
    /**
     * 已认领文案
     */
    checkedClaimText: {
      type: String,
      default: '已认领'
    },
    /**
     * 标签label颜色值
     */
    tagKeyColor: {
      type: String,
      default: '#5f5e64'
    },
    /**
     * 操作区域与文案区域分割线
     */
    actionSplitLine: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isFollow(val) {
      this.followVal = val
    },
    isClaim(val) {
      this.claimVal = val
    }
  },
  data() {
    return {
      followVal: this.isFollow,
      claimVal: this.isClaim
    }
  },
  methods: {
    setFollow(val) {
      this.followVal = val
    },
    setClaim(val) {
      this.claimVal = val
    }
  }
}
</script>

<style lang="scss" scoped>
.buyer-cell {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 14px 16px;
  background: #fff;
  &:not(:last-child)::after {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    //width: calc(100% - 16px);
    height: 1px;
    background: rgba($color: #000000, $alpha: 0.05);
  }
  .buyer-top {
    display: flex;
  }
  .buyer-info {
    flex: 1;
    max-width: calc(100% - 64px);
    padding: 0 8px;
  }
  .buyer-title {
    font-size: 16px;
    //font-weight: 700;
    line-height: 20px;
    color: $gray_9;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .buyer-sub-title {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 6px;
    font-size: 12px;
    line-height: 20px;
    color: $gray_7;
    p {
      //max-width: 50%;
      //min-width: 30%;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .tag-val {
      color: #2abed1;
    }
    //p:nth-of-type(2n-1){
    //  margin-right: 12px;
    //}
  }
  .buyer-bottom {
    display: flex;
    justify-content: flex-end;
  }
  .action-item {
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
  .action-item-text {
    margin-left: 4px;
    font-size: 14px;
    line-height: 20px;
    color: #5f5e64;
  }
  .action-split-line {
    height: 1px;
    background: rgba(0, 0, 0, 0.05);
    margin: 12px 0 12px 64px;
  }
}
</style>
