import request from '@/api'
/**
 * 获取用户剑鱼文库会员身份信息
 * @type {post}
 */
export function ajaxGetDocMemberUserInfo() {
  return request({
    url: '/jydocs/user/info?t=' + Date.now(),
    method: 'get'
  })
}
