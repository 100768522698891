import dayjs from 'dayjs'

/**
 * 根据 exact 返回格式化后的时间，支持单位 ms\s
 * @param exact
 * @param unit
 * @returns {{start: number, end: number}}
 */
export function calcNotExactTime(exact = 'lately7', unit = 'ms') {
  const t = {
    start: 0,
    end: dayjs().valueOf()
  }
  const durations = {
    hour1: 60 * 60 * 1000,
    day1: 60 * 60 * 1000 * 24 * 1,
    day7: 60 * 60 * 1000 * 24 * 7,
    day30: 60 * 60 * 1000 * 24 * 30
  }
  switch (exact) {
    case 'today': {
      t.start = dayjs().startOf('day').valueOf() // 当天0点时间戳
      t.end = dayjs().endOf('day').valueOf() // 当天23:59:59时间戳
      break
    }
    case 'tomorrow': {
      t.start = dayjs().add(1, 'days').startOf('day').valueOf()
      t.end = dayjs().add(1, 'days').endOf('day').valueOf()
      break
    }
    case 'next7days': {
      t.start = dayjs().valueOf()
      t.end = dayjs().add(7, 'days').endOf('day').valueOf()
      break
    }
    case 'next30days': {
      t.start = dayjs().valueOf()
      t.end = dayjs().add(30, 'days').endOf('day').valueOf()
      break
    }
    case 'yesterday': {
      t.start = dayjs().startOf('day').valueOf() - durations.day1
      t.end = dayjs().endOf('day').valueOf() - durations.day1
      break
    }
    // 本周
    case 'thisWeek': {
      t.start = dayjs().startOf('week').valueOf()
      t.end = dayjs().endOf('week').valueOf()
      break
    }
    // 上周
    case 'lastWeek': {
      t.start = dayjs().subtract(1, 'week').startOf('week').valueOf()
      t.end = dayjs().subtract(1, 'week').endOf('week').valueOf()
      break
    }
    // 上一个月
    case 'lastMonth': {
      t.start = dayjs().subtract(1, 'month').startOf('month').valueOf()
      t.end = dayjs().subtract(1, 'month').endOf('month').valueOf()
      break
    }
    // 本月
    case 'thisMonth': {
      t.start = dayjs().startOf('month').valueOf()
      t.end = dayjs().endOf('month').valueOf()
      break
    }
    // 下月
    case 'nextMonth': {
      t.start = dayjs().add(1, 'month').startOf('month').valueOf()
      t.end = dayjs().add(1, 'month').endOf('month').valueOf()
      break
    }
    case 'lately3': {
      // day操作减去3天，再取0点和23点
      t.start = dayjs().subtract(3, 'day').startOf('day').valueOf()
      t.end = dayjs().endOf('day').valueOf()
      break
    }
    case 'lately-7':
    case 'lately7': {
      t.start = dayjs().subtract(7, 'day').startOf('day').valueOf()
      t.end = dayjs().endOf('day').valueOf()
      break
    }
    case 'lately-30':
    case 'lately30': {
      t.start = dayjs().subtract(30, 'day').startOf('day').valueOf()
      t.end = dayjs().endOf('day').valueOf()
      break
    }
    case 'lately90': {
      // 近90天
      t.start = dayjs().subtract(90, 'day').startOf('day').valueOf()
      t.end = dayjs().endOf('day').valueOf()
      break
    }
    case 'lately180': {
      // 180天
      t.start = dayjs().subtract(180, 'day').startOf('day').valueOf()
      t.end = dayjs().endOf('day').valueOf()
      break
    }
    case 'thisYear': {
      // 今年全年
      t.start = dayjs().startOf('year').valueOf()
      t.end = dayjs().endOf('year').valueOf()
      break
    }
    case 'lastYear': {
      // 去年全年
      t.start = dayjs().subtract(1, 'year').startOf('year').valueOf()
      t.end = dayjs().subtract(1, 'year').endOf('year').valueOf()
      break
    }
    case 'sinceLastYear': {
      // 去年至今
      t.start = dayjs().subtract(1, 'year').startOf('year').valueOf()
      t.end = dayjs().valueOf()
      break
    }
    case 'sinceYearBeforeLast': {
      // 前年至今
      t.start = dayjs().subtract(2, 'year').startOf('year').valueOf()
      t.end = dayjs().valueOf()
      break
    }
    // 最近1年
    case 'thisyear': {
      t.start = dayjs().subtract(1, 'year').valueOf()
      t.end = dayjs().valueOf()
      break
    }
    // 最近3年
    case 'threeyear': {
      t.start = dayjs().subtract(3, 'year').valueOf()
      t.end = dayjs().valueOf()
      break
    }
    // 最近5年
    case 'fiveyear': {
      t.start = dayjs().subtract(5, 'year').valueOf()
      t.end = dayjs().valueOf()
      break
    }
    default: {
      t.start = 0
      t.end = 0
      break
    }
  }
  if (unit === 's') {
    t.start = dayjs(t.start).unix()
    t.end = dayjs(t.end).unix()
  }
  return t
}
