import MonitorApiBase from './base'
import {
  ajaxFollowClientInfo,
  ajaxFollowClientChange
} from "../api/follow-client";

export default class MonitorClientApi extends MonitorApiBase {
  constructor({ id }) {
    super()
    this.id = id
  }

  /**
   * 提供覆盖的ajax请求
   * @return {{}}
   */
  async ajaxGetState() {
    return ajaxFollowClientInfo({ name_list: [this.id] }).then((res) => {
      const result = this.createModel()
      result.canFollow = true
      result.follow = false
      if (res?.data?.follow) {
        if (res.data.follow[this.id]) {
          result.follow = true
        }
      }

      if (res?.data?.count) {
        result.expands = {
          used: res?.data?.count?.use || 0,
          surplus: res?.data?.count?.surplus || 0
        }
      }
      return result
    })
  }
  async ajaxAdd() {
    return ajaxFollowClientChange({ name: this.id, b: false }).then((res) => {
      const result = {
        success: false,
        msg: '',
        data: {}
      }
      result.success = res?.error_code === 0 && res?.data?.status
      result.data = res?.data
      if (result.success) {
        this.model.expands.used += 1
        this.model.expands.surplus = Math.max(this.model.expands.surplus - 1, 0)
      } else {
        result.msg = res?.error_msg || '抱歉，操作失败'
      }
      return result
    })
  }
  async ajaxRemove() {
    return ajaxFollowClientChange({ name: this.id, b: true }).then(
      (res) => {
        const result = {
          success: false,
          msg: '',
          data: {}
        }
        result.success = res?.error_code === 0 && res?.data?.status
        result.data = res?.data
        if (result.success) {
          this.model.expands.surplus += 1
          this.model.expands.used = Math.max(this.model.expands.used - 1, 0)
        } else {
          result.msg = res?.error_msg || '抱歉，操作失败'
        }
        return result
      }
    )
  }
}
