import {
  getEntDataInfo,
  getEntList,
  ajaxGetUserInfo,
  getMypersondept,
  getMySelectEntInfo
} from '@/api/modules'
import { union } from 'lodash/array'
import { appStorage } from '@/utils/storage'

/**
 * 数组交集
 * @param array1
 * @param array2
 * @returns {*}
 */
function intersect(array1, array2) {
  return array1.filter((x) => array2.includes(x))
}

/**
 * 判断选中状态
 * @param n1 - 需判断的长度
 * @param n2 - 全量数据长度
 * @returns {string} - 结果 'none' | 'check' | 'all'
 */
function diffCheckStatus(n1, n2) {
  if (n1 === 0) {
    return 'none'
  } else if (n1 === n2) {
    return 'all'
  } else {
    return 'check'
  }
}

/**
 * 格式化企业架构数据
 * @param data
 * @returns {{groupMap: {}, idMap: {}}}
 */
function formatEntListToMap(data, filter = false) {
  const groupMap = {}
  const idMap = {}

  function traverse(item, names = []) {
    const formatItemInfo = {
      ids: [],
      names: names.concat([item?.departmentName || '']),
      groups: [],
      persons: [],
      id: item.id,
      label: item.departmentName
    }

    if (item?.persons) {
      if (filter) {
        item.persons = item.persons?.filter((v) => v?.logonUser !== 1)
      }
      item.persons.forEach((person) => {
        // positionId
        const pId = person.id || `异常ID:${person.id}`
        formatItemInfo.persons.push(pId)
        formatItemInfo.ids.push(pId)
        person.label = person.personName
        // 兼容异常职位ID
        person._id = person.id
        person.id = pId
        idMap[pId] = person
      })
    }

    if (item?.childDepartment) {
      item?.childDepartment.forEach((child) => {
        formatItemInfo.groups.push(child.id)
        formatItemInfo.ids.push(...traverse(child, formatItemInfo.names))
      })
    }

    // 新增格式化数据
    groupMap[item.id] = formatItemInfo

    return formatItemInfo.ids
  }

  traverse(data)

  return {
    idMap: idMap,
    groupMap: groupMap
  }
}

export default {
  namespaced: true,
  state: () => ({
    // 快速根据部门 id 获取部门成员 id 信息
    groupMap: {},
    // 快速根据id获取成员信息
    idMap: {},
    info: [],
    // 选中的数据列表
    selectList: [],
    // 是否多选
    inMultipleChoice: true,
    // 是否创建新群聊
    createChecked: true,
    // 企业列表
    entList: [],
    // 是否排除自身
    filterSelf: false,
    // 已经在群组中的企业成员Id
    alreadySelectIds: [],
    // 部门下自己的信息
    ownInfo: {},
    // 点击完成保存的选中ID
    saveChoose: appStorage.get('save-choose', []),
    saveTime: appStorage.get('save-time', null),
    mySelectEntInfo: {},
    // 参标项目看板数据推送汇总新增项-标讯来源、投标类型
    addSourceType: appStorage.get('add-source-type', {
      bidSource: [-1],
      bidType: [0]
    }),
    // 参标项目看板数据-项目明细数据
    boardDetailFilters: appStorage.get('board-detail-filter', {
      // 参标状态
      bidStatus: [0],
      // 投标类型
      bidType: [0],
      // 标讯来源
      bidSource: [-1],
      // 订阅推送时间
      bidPushTime: {},
      // 状态更新时间
      bidUpdateTime: {},
      // 部门人员
      entUserIdArr: [],
      // tab下标
      active: 0,
      // drop展开状态
      showDrop: false,
      cacheParams: {}
    })
  }),
  mutations: {
    changeMySelectEntInfo(state, data) {
      state.mySelectEntInfo = data
    },
    setSaveChoose(state, arr) {
      state.saveChoose = arr
      appStorage.set('save-choose', state.saveChoose)
    },
    setSaveTime(state, obj) {
      state.saveTime = obj
      appStorage.set('save-time', state.saveTime)
    },
    setAlreadySelect(state, { ids }) {
      state.alreadySelectIds = ids
    },
    clearAlreadySelectInfo(state) {
      state.alreadySelectIds = []
    },
    setFilterSelf(state, status) {
      state.filterSelf = status
    },
    setInfo(state, info) {
      const listInfo = info
      const result = formatEntListToMap(listInfo[0] || {}, state.filterSelf)
      state.idMap = result.idMap
      state.groupMap = result.groupMap
      state.info = listInfo
    },
    setOwnInfo(state, data) {
      state.ownInfo = data
    },
    setEntList(state, data) {
      state.entList = data
    },
    toggleSelectItem(state, item) {
      if (!state.inMultipleChoice) {
        state.selectList = []
      }
      const hasItem = state.selectList.find((v) => v.id === item.id)
      if (hasItem) {
        state.selectList = state.selectList.filter((v) => v.id !== item.id)
      } else {
        state.selectList.push(item)
      }
    },
    toggleMultipleChoice(state, type) {
      state.inMultipleChoice = type
      // 清除历史选中数据
      state.selectList = []
    },
    toggleCreateCheck(state, type) {
      state.createChecked = type
    },
    setSelectList(state, list) {
      state.selectList = list
    },
    saveAddSourceType(state, params = { bidSource, bidType }) {
      Object.assign(state.addSourceType, params)
      appStorage.set('add-source-type', state.addSourceType)
    },
    saveBoardDetailFilter(
      state,
      params = {
        bidStatus,
        bidType,
        bidSource,
        bidPushTime,
        bidUpdateTime,
        entUserIdArr,
        active,
        showDrop
      }
    ) {
      Object.assign(state.boardDetailFilters, params)
      appStorage.set('board-detail-filter', state.boardDetailFilters)
    },
    removeBoardPageCache(state) {
      appStorage.rm('save-choose')
      appStorage.rm('save-time')
      appStorage.rm('add-source-type')
      appStorage.rm('board-detail-filter')
    }
  },
  actions: {
    resetState({ commit }, { invite = false } = {}) {
      if (invite) {
        // 邀请进群页面操作
        commit('toggleMultipleChoice', true)
      } else {
        // 默认操作
        commit('clearAlreadySelectInfo')
        commit('toggleMultipleChoice', false)
      }
    },
    setFilterSelf({ commit }, type) {
      commit('setFilterSelf', type)
    },
    // 设置邀请入群相关成员信息
    setAlreadySelect({ commit }, { groupId, ids }) {
      commit('clearAlreadySelectInfo')
      commit('setAlreadySelect', { groupId, ids })
    },
    toggleCreateCheck({ commit }, type) {
      commit('toggleCreateCheck', type)
    },
    toggleAllCheck({ state, commit, getters }, type) {
      // 判断当前是否全选
      // --> 不是全选，则全部选中
      // --> 是全选，取消全部选中
      if (getters.allChecked) {
        commit('setSelectList', [])
      } else {
        const selectAllList = getters.allPersons.map((v) => {
          return state.idMap[v]
        })
        commit('setSelectList', selectAllList)
      }
    },
    setCheck({ state, commit, getters }, { ids }) {
      const selectList = []
      getters.allPersons.forEach((v) => {
        if (ids.includes(v)) {
          selectList.push(state.idMap[v])
        }
      })
      commit('setSelectList', selectList)
    },
    toggleSelectItem({ state, commit, getters }, { id, type }) {
      // 部门群组
      if (type === 'group') {
        const nowGroup = getters.formatEntGroupMap[id] || {}
        const groupSelectIds = nowGroup.ids.map((v) => state.idMap[v])
        const otherSelectList = getters.selectIds
          .filter((i) => !nowGroup.ids.includes(i))
          .map((v) => state.idMap[v])
        // 已在群组中的无法操作，需要过滤
        if (nowGroup.checked === 'all') {
          commit(
            'setSelectList',
            otherSelectList.filter(
              (i) => !getters.alreadySelectIds.includes(i.id)
            )
          )
        } else {
          commit(
            'setSelectList',
            otherSelectList
              .concat(groupSelectIds)
              .filter((i) => !getters.alreadySelectIds.includes(i.id))
          )
        }
      } else {
        // 已在群组中的无法操作
        if (getters.alreadySelectIds.includes(id)) {
          return
        }
        // 单人
        commit('toggleSelectItem', state.idMap[id])
      }
    },
    async getOwnUserInfo({ commit }) {
      try {
        const { data, error_code: code } = await ajaxGetUserInfo()
        if (code === 0 && data) {
          commit('setOwnInfo', data)
        } else {
          commit('setOwnInfo', {})
        }
        return data || {}
      } catch (error) {
        commit('setOwnInfo', [])
      }
    },
    async getEntListApi({ commit }) {
      try {
        const { data, error_code: code } = await getEntList()
        if (code === 0 && data) {
          commit('setEntList', data)
        } else {
          commit('setEntList', [])
        }
        return data || []
      } catch (e) {
        console.warn(e)
        commit('setEntList', [])
        return []
      }
    },
    async getEntData({ commit, state }, { type, payload }) {
      try {
        if (type === 'permissionFilter') {
          const { data = [], error_code: code } = await getMypersondept({
            isFilterate: true
          })
          if (code === 0 && data) {
            console.log(data)
            const obj = {}
            obj.departmentName = state.mySelectEntInfo.ent_name || '--'
            obj.childDepartment = data
            commit('setInfo', [obj])
          } else {
            commit('setInfo', [])
          }
          return data || []
        } else {
          const { data = [], error_code: code } = await getEntDataInfo(payload)
          if (code === 0 && data) {
            console.log(data)
            commit('setInfo', data)
          } else {
            commit('setInfo', [])
          }
          return data || []
        }
      } catch (e) {
        console.warn(e)
        commit('setInfo', [])
        return []
      }
    },
    async getMySelectEntInfo({ commit }) {
      try {
        const { error_code: code, data = {} } = await getMySelectEntInfo()
        if (code === 0) {
          commit('changeMySelectEntInfo', data || {})
        }
        return data
      } catch (error) {}
    }
  },
  getters: {
    // 是否是管理员(系统管理员或者部门管理员)
    isAdmin(state) {
      const info = state.mySelectEntInfo
      return info.admin_department || info.admin_system
    },
    // 是否为个人版
    isPrivateVersion(state) {
      const info = state.mySelectEntInfo
      return info.dept_id === 0 && info.dept_pid === 0
    },
    // 企业首页信息
    formatEntHomeInfo(state, getters) {
      return getters.formatPageEntMap[state.info[0]?.id] || {}
    },
    // 格式化 group 数据，新增选中状态判断
    formatEntGroupMap(state, getters) {
      const groupMap = state.groupMap
      // 对 group 进行判断选中状态
      const formatMap = Object.fromEntries(
        Object.entries(groupMap).map(([key, value]) => {
          const includeIds = intersect(
            value.ids,
            getters.unionAlreadyAndSelectIds
          )
          const disabledIds = intersect(value.ids, getters.alreadySelectIds)
          const disabled =
            diffCheckStatus(disabledIds.length, value.ids.length) === 'all'
          return [
            key,
            {
              ...value,
              // 部门群组选中状态
              checked: disabled
                ? 'all'
                : diffCheckStatus(includeIds.length, value.ids.length),
              disabled: disabled
            }
          ]
        })
      )

      return formatMap
    },
    // 格式化用户 user 数据，新增选中状态判断
    formatEntUserMap(state, getters) {
      const userMap = state.idMap
      // 对 group 进行判断选中状态
      const formatMap = Object.fromEntries(
        Object.entries(userMap).map(([key, value]) => {
          const disabled = getters.alreadySelectIds.includes(key)
          return [
            key,
            {
              ...value,
              // 员工选中状态
              checked: disabled
                ? 'all'
                : getters.selectIds.includes(key)
                ? 'all'
                : 'none',
              disabled: disabled
            }
          ]
        })
      )

      return formatMap
    },
    // 格式化页面所需的数据
    formatPageEntMap(state, getters) {
      const groupMap = getters.formatEntGroupMap
      // 对 group 进行判断选中状态
      const formatMap = Object.fromEntries(
        Object.entries(groupMap).map(([key, value]) => {
          return [
            key,
            {
              ...value,
              // 部门群组
              groups: value.groups.map((id) => {
                const groupInfo = groupMap[id] || {}
                return groupInfo
              }),
              // 部门用户
              persons: value.persons.map((id) => {
                const personInfo = getters.formatEntUserMap[id] || {}
                return personInfo
              })
            }
          ]
        })
      )

      return formatMap
    },
    // 选中的所有ID （不包含已在群聊默认选中的数据）
    selectIds(state, getters) {
      return state.selectList
        .filter((v) => !getters.alreadySelectIds.includes(v.id))
        .map((v) => v.id)
    },
    // 是否仅选中单一信息
    onlySelectOne(state, getters) {
      return getters.selectIds.length === 1
    },
    // 公司下所有用户 id 信息
    allPersons(state) {
      return state.groupMap[state.info[0]?.id]?.ids || []
    },
    // 返回全选状态 Boolean
    allChecked(state, getters) {
      const canAll = intersect(
        getters.allPersons,
        getters.unionAlreadyAndSelectIds
      )
      return canAll.length === getters.allPersons.length
    },
    // 返回当前用户的身份信息
    currentUserIdentity(state) {
      return state.entList.filter((item) => item.checked)?.length > 0
        ? state.entList.filter((item) => item.checked)[0]
        : {}
    },
    // 去重后的全部选中用户
    unionAlreadyAndSelectIds(state, getters) {
      return union(getters.alreadySelectIds, getters.selectIds)
    },
    // 已在群组中的企业用户ID
    alreadySelectIds(state) {
      return state.alreadySelectIds || []
    }
  }
}
