import request from '@/api'
import qs from 'qs'

// 查询数据报告详情
export function getReportDetail(data) {
  data = qs.stringify(data)
  return request({
    url: '/datareport/api/getDetail',
    method: 'post',
    data
  })
}

// 查询用户是否已经填写过邮箱或者手机号
export function getReportUserMsg() {
  return request({
    url: '/datareport/api/userMsg',
    method: 'get'
  })
}

// 发送邮箱验证码
export function sEmailCode(data) {
  data = qs.stringify(data)
  return request({
    url: '/datareport/api/sendEmailCode',
    method: 'post',
    data
  })
}

// 校验邮箱验证码
export function cEmailCode(data) {
  data = qs.stringify(data)
  return request({
    url: '/datareport/api/checkEmailCode',
    method: 'post',
    data
  })
}
