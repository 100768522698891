export default [
  {
    path: '/friend',
    name: 'giving-friend',
    component: () => import('@/views/giving/friend.vue'),
    meta: {
      title: '送给朋友',
      header: true
    }
  },
  {
    path: '/record',
    name: 'giving-record',
    component: () => import('@/views/giving/record.vue'),
    meta: {
      title: '超级订阅赠送记录',
      header: true
    }
  },
  {
    path: '/notify/:id',
    name: 'giving-notify',
    component: () => import('@/views/giving/notify.vue'),
    meta: {
      title: '告知好友',
      header: true
    }
  },
  {
    path: '/share',
    name: 'giving-share',
    component: () => import('@/views/giving/share.vue'),
    meta: {
      title: '分享有礼',
      header: true
    }
  }
]
