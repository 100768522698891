import request from '@/api'
import qs from 'qs'

export function getBusinessDetails(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/project/businessDetails',
    method: 'POST',
    data
  })
}

// 是否需要完善信息提示
export function isNeedCompleteInfo () {
  return request({
    url: '/salesLeads/businessRetain',
    method: 'POST'
  })
}

// 获取客服信息
export function getCommercialCustomerInfo(params) {
  return request({
    url: '/commercial/customer/info',
    method: 'GET',
    params
  })
}
