export default [
  {
    path: '/exchange',
    name: 'fileExchange',
    component: () => import('@/views/fileRecord/exchange.vue'),
    meta: {
      header: true,
      title: '兑换/购买附件下载明细',
      showShareBtn: true
    }
  }
]
