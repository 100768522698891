<template>
  <div
    class="company-cell cell-clickable"
    :class="{ visited: visited }"
    @click="$emit('click', $event)"
  >
    <div class="ent-info-top">
      <div class="ent-info-head" :style="{ background: logoBg }">
        {{ shortName }}
      </div>
      <div class="ent-info-keep">
        <div class="ent-info-name" :class="{ 'van-ellipsis': ellipsis }">
          {{ name }}
        </div>
        <div class="ent-info-label" v-if="tags && Object.keys(tags).length > 0">
          <div v-for="(val, key, index) in tags" :key="index">
            <span class="sub-label">{{ key }}</span>
            <span class="sub-value">{{ val }}</span>
          </div>
        </div>
        <slot name="zhima"></slot>
      </div>
    </div>
    <div class="ent-info-bottom">
      <div class="ent-info-status">
        <span
          :style="{ color: statusColor, color: statusColor }"
          v-if="status"
          >{{ status }}</span
        >
      </div>
      <div class="ent-info-address">
        <div :class="{ 'van-multi-ellipsis--l2': ellipsis }">{{ address }}</div>
        <icon name="weizhidingwei" color="#2cb7ca"></icon>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/ui/icon/'
export default {
  name: 'company-cell',
  components: {
    Icon
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    shortName: {
      type: String,
      default: ''
    },
    tags: {
      type: Object,
      default() {
        return {}
      }
    },
    address: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    statusColor: {
      type: String,
      default: ''
    },
    logoBg: {
      type: String,
      default: ''
    },
    ellipsis: {
      type: Boolean,
      default: true
    },
    visited: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.company-cell {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 16px;
  background: #fff;
  &.visited {
    .ent-info-name {
      color: $gray_5;
    }
  }
  .ent-info-top {
    display: flex;
    align-items: flex-start;
    padding-bottom: 12px;
  }
  .ent-info-head {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    flex-shrink: 0;
    font-size: 16px;
    color: #fff;
    padding: 0 8px 0 10px;
    letter-spacing: 2px;
    text-align: center;
    line-height: 18px;
  }
  .ent-info-keep {
    flex: 1;
    margin-left: 12px;
  }
  .ent-info-name {
    max-width: 250px;
    font-size: 16px;
    line-height: 24px;
    color: $gray_9;
  }
  .ent-info-label {
    margin-top: 4px;
  }
  .sub-label,
  .sub-value {
    font-size: 13px;
    line-height: 18px;
    color: $gray_7;
  }
  .sub-value {
    color: $main;
  }
  .ent-info-bottom {
    display: flex;
    align-items: center;
  }
  .ent-info-status {
    width: 64px;
    padding-top: 12px;
    & > span {
      display: inline-block;
      padding: 0 4px;
      font-size: 12px;
      line-height: 18px;
      color: $main;
      border: 1px solid;
      border-radius: 4px;
    }
  }
  .ent-info-address {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    margin-left: 12px;
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 240px;
      height: 1px;
      background: rgba(0, 0, 0, 0.05);
    }
    & > div {
      flex: 1;
      font-size: 11px;
      line-height: 16px;
      color: $gray_7;
      margin-right: 16px;
    }
    .iconfont {
      font-size: 20px;
    }
  }
}
</style>
