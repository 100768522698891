// 数据超市路由
export default [
  {
    path: '/search',
    name: 'ai-search',
    component: () => import('@/views/ai-search/index.vue'),
    meta: {
      header: false,
      title: '剑鱼AI搜索'
    }
  }
]
