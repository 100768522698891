import request from '@/api'
import qs from 'qs'

export function ajaxInvoiceQuery(data) {
  // 扫码查询发票信息
  data = qs.stringify(data)
  return request({
    url: '/jypay/invoice/query',
    method: 'post',
    data
  })
}
export function ajaxInvoiceSubmit(data) {
  //  开发票提交
  data = qs.stringify(data)
  return request({
    url: '/jypay/invoice/submit',
    method: 'post',
    data
  })
}
export function ajaxInvoiceSwitch(data) {
  //开发票开关
  return request({
    url: '/jypay/invoice/switch?t=' + Date.now(),
    method: 'get',
    params: data
  })
}
export function ajaxInvoiceShowList(data) {
  //查看发票列表
  return request({
    url: '/jypay/invoice/showList',
    method: 'get',
    params: data
  })
}
export function ajaxInvoiceAavailable(data) {
  //  查询是否可以开发票
  data = qs.stringify(data)
  return request({
    url: '/jypay/invoice/available',
    method: 'post',
    data
  })
}
export function ajaxInvoiceNewReplace(data) {
  //换票
  return request({
    url: '/jypay/invoice/newReplace',
    method: 'get',
    params: data
  })
}
export function ajaxInvoiceNewshow(data) {
  //我的订单 发票回显
  return request({
    url: '/jypay/invoice/newShow',
    method: 'get',
    params: data
  })
}



