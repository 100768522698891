<template>
  <div
    ref="layoutCard"
    class="j-container layout-card"
    :style="{ 'max-height': maxHeight }"
  >
    <div class="j-header">
      <slot name="header" />
    </div>
    <div class="j-main">
      <slot name="default" />
      <slot name="main" />
    </div>
    <div class="j-footer">
      <slot name="footer">
        <div class="j-button-group height40">
          <button class="j-button-cancel" @click="$emit('cancel')">
            {{ cancelText }}
          </button>
          <button class="j-button-confirm" @click="$emit('confirm')">
            {{ confirmText }}
          </button>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownLayout',
  props: {
    maxHeight: {
      type: [String, Number],
      default: '60vh'
    },
    cancelText: {
      type: String,
      default: '重置'
    },
    confirmText: {
      type: String,
      default: '确认'
    }
  }
}
</script>
