<template>
  <div
    class="customer-cell cell-clickable"
    :class="{ visited: visited }"
    @click="$emit('click', $event)"
  >
    <div class="customer-hd">
      <icon name="mine_company" color="#2cb7ca"></icon>
    </div>
    <div class="customer-bd">
      <div class="customer-name" :class="{ 'van-ellipsis': ellipsis }">
        {{ name }}
      </div>
      <div class="cell-info-list">
        <span
          class="cell-info-item"
          v-for="(item, index) in subInfoList"
          :key="index"
        >
          <span class="info-label">{{ item.label }}：</span>
          <span class="info-value">{{ item.value }}</span>
        </span>
      </div>
      <div class="cell-info-list customer-location">
        <span class="cell-info-item">
          <span class="info-label">所在地：</span>
          <span class="info-value highlight-text">{{ location }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/ui/icon/'
export default {
  name: 'CustomerCell',
  components: {
    Icon
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    ellipsis: {
      type: Boolean,
      default: false
    },
    visited: {
      type: Boolean,
      default: false
    },
    location: {
      type: String,
      default: ''
    },
    subInfoList: {
      type: Array,
      default() {
        return [
          // {
          //   label: '项目数量',
          //   value: '8248'
          // },
          // {
          //   label: '项目总金额',
          //   value: '24.07亿元'
          // }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-cell {
  display: flex;
  padding: 14px 16px;
  background-color: #fff;
  &.visited {
    .customer-name,
    .cell-info-list {
      color: $gray_5;
    }
  }
  .customer-hd {
    .iconfont {
      font-size: 24px;
    }
  }
  .customer-bd {
    margin-left: 4px;
    flex: 1;
  }
  .customer-name {
    font-size: 14px;
    line-height: 22px;
    color: $gray_9;
    &.van-ellipsis {
      max-width: 300px;
    }
  }
  .cell-info-list {
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
    color: #5f5e64;
  }
  .customer-location {
    font-size: 11px;
    line-height: 18px;
    color: #9b9ca3;
  }

  .cell-info-item {
    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }
}
</style>
