// 静态路由
export default [
  {
    path: '/change/identity',
    name: 'guide',
    component: () => import('@/views/mine/ChangeIdentity.vue'),
    meta: {
      header: true,
      title: '切换身份'
    }
  }
]
