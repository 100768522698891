import request from '@/api'
import qs from 'qs'
import { envs } from '@/utils/prototype/modules/platform'

// 标讯搜索数据导出逻辑
export function searchIndexDataExport(data) {
  data = qs.stringify(data)
  return request({
    url: envs.inWX
      ? '/front/wx_dataExport/searchExport'
      : '/jyapp/front/dataExport/searchExport',
    method: 'post',
    data
  })
}
export function setDontPromptAgain(data) {
  data = qs.stringify(data)
  return request({
    url: envs.inWX
      ? '/front/dataExport/setDontPromptAgain'
      : '/jyapp/front/dataExport/setDontPromptAgain',
    method: 'post',
    data
  })
}
export function getDontPromptAgain(data) {
  data = qs.stringify(data)
  return request({
    url: envs.inWX
      ? '/front/dataExport/getDontPromptAgain'
      : '/jyapp/front/dataExport/getDontPromptAgain',
    method: 'post',
    data
  })
}
