import {
  ajaxChangeEntGroup,
  ajaxFollowEntAdd,
  ajaxFollowEntCancel,
  ajaxFollowEntInfo,
  ajaxGetGroupList,
  ajaxMonitorGroup
} from '../api/follow-ent'
import MonitorApiBase from './base'

export default class MonitorEntApi extends MonitorApiBase {
  constructor({ id }) {
    super()
    this.id = id
  }

  /**
   * 提供覆盖的ajax请求
   * @return {{}}
   */
  async ajaxGetState() {
    return ajaxFollowEntInfo({ entId: this.id }).then((res) => {
      const result = this.createModel()
      result.canFollow = res?.data?.isShow || false
      result.follow = res?.data?.followed || false
      result.followedGroup = res?.data?.followedGroup || ''

      if (res?.data?.info) {
        result.expands = res.data.info
      }
      return result
    })
  }

  async ajaxAdd() {
    return ajaxFollowEntAdd({ entId: this.id, groups: this.groupId }).then(
      (res) => {
        const result = {
          success: false,
          msg: '',
          data: {}
        }
        result.success = res?.error_code === 0 && res?.data?.status
        result.data = res?.data
        if (result.success) {
          this.model.expands.used += 1
          this.model.expands.surplus = Math.max(
            this.model.expands.surplus - 1,
            0
          )
        }
        else {
          result.msg = res?.error_msg || '抱歉，操作失败'
        }
        return result
      }
    )
  }

  async ajaxRemove() {
    return ajaxFollowEntCancel({ entId: this.id }).then((res) => {
      const result = {
        success: false,
        msg: '',
        data: {}
      }
      result.success = res?.error_code === 0 && res?.data === 'success'
      result.data = res?.data
      if (result.success) {
        this.model.expands.surplus += 1
        this.model.expands.used = Math.max(this.model.expands.used - 1, 0)
        this.model.followedGroup = ''
      }
      else {
        result.msg = res?.error_msg || '抱歉，操作失败'
      }
      return result
    })
  }

  async ajaxGroup(params = {}) {
    const { type, name, groupId, callback } = params
    return ajaxMonitorGroup({ type, name, groupId }).then((res) => {
      const result = {
        success: false,
        msg: '',
        data: {}
      }
      result.success = res?.error_code === 0
      result.data = res?.data
      if (result.success) {
        // 非获取（新增、修改、删除）成功后再次获取分组列表
        if (type !== 'get') {
          this.ajaxGroup({ type: 'get' })
        }
        else {
          this.groupList = res?.data
        }
        callback && callback()
      }
      else {
        result.msg = res?.error_msg || '抱歉，操作失败'
      }
      return result
    })
  }

  async ajaxChange(params = {}) {
    const { groupId, callback } = params
    return ajaxChangeEntGroup({ entId: this.id, groups: groupId }).then(
      (res) => {
        const result = {
          success: false,
          msg: '',
          data: {}
        }
        result.success = res?.error_code === 0 && res?.data === 'success'
        result.data = res?.data
        if (result.success) {
          callback && callback()
        }
        else {
          result.msg = res?.error_msg || '抱歉，操作失败'
        }
        return result
      }
    )
  }

  async ajaxOnlyGroup(type = 'get') {
    return ajaxGetGroupList(type).then((res) => {
      const result = {
        success: false,
        msg: '',
        data: {}
      }
      result.success = res?.error_code === 0
      result.data = res?.data
      if (result.success) {
        this.groupList = res.data?.groupUserArr
      }
      else {
        result.msg = res?.error_msg || '抱歉，操作失败'
      }
      return result
    })
  }
}
