import qs from 'qs'
import request from '@/api'
import { envs } from '@/utils/prototype/modules/platform'

// 删除/批量删除标讯搜索历史
export function ajaxRemoveBiddingSearchHistory(data) {
  return request({
    url: envs.inWX
      ? '/swordfish/delWxHistorySearch'
      : '/jyapp/swordfish/delWxHistorySearch',
    method: 'post',
    data
  })
}

// 标讯搜索
export function getBiddingSearchList(data, type = 'fType') {
  return request({
    url: `/jyapi/jybx/core/${type}/searchList`,
    method: 'post',
    noToast: true,
    data
  })
}

// 阳光采购搜索
export function getSunSearchList(data) {
  return request({
    url: '/jyapi/jybx/core/purchaseSearch',
    method: 'post',
    noToast: true,
    data
  })
}

// 标讯搜索
export function getBiddingSearchListOfNoLogin(data) {
  return request({
    url: `/jyapi/jybx/core/nologin/search`,
    method: 'post',
    noToast: true,
    data
  })
}

// 获取已存筛选列表
export function getBiddingFilterList() {
  return request({
    url: '/jyapi/jybx/base/showSearchScreen',
    method: 'post',
    noToast: true
  })
}

// 删除/批量删除已存筛选
export function deleteBiddingFilter(data) {
  return request({
    url: '/jyapi/jybx/base/delSearchScreen',
    method: 'post',
    data
  })
}

// 检查已存筛选是否pass
export function checkBiddingFilterPass(data) {
  return request({
    url: '/jyapi/jybx/base/checkSearchScreen',
    method: 'post',
    data
  })
}

// 添加筛选条件
export function addBiddingFilter(data) {
  return request({
    url: '/jyapi/jybx/base/addSearchScreen',
    method: 'post',
    data
  })
}

/**
 * 查询用户标讯搜索历史词
 * @returns Promise
 */
export function getBiddingSearchHistory() {
  return request({
    url: envs.inWX
      ? '/jylab/mainSearch/getHK'
      : '/jyapp/jylab/mainSearch/getHK',
    method: 'post',
    query: {
      t: Date.now()
    }
  })
}

/**
 * 商机管理 获取采购单位信息 为新增接口（相比老接口优化了聚合语句，附带了关注和认领状态查询结果
 * fType：免费用户,pType：付费用户,vType：超级订阅用户,mType：大会员用户,eType：商机管理用户
 */
export function getManageBuyerList(data, userType = 'eType') {
  return request({
    url: `/jyapi/jybx/buyer/${userType}/buyerList`,
    method: 'post',
    data
  })
}

/**
 *  根据采购单位名称获取相关项目补充统计数据
 * @param data{buyer}
 * @returns {*}
 */
export function getBuyerSupplyInfo(data) {
  return request({
    url: '/jyapi/jybx/buyer/supply/info',
    method: 'post',
    data
  })
}

/**
 * 获取中标企业名称联想
 * @param data
 * @returns {*}
 */
export function ajaxGetSearchWinnerNameList(data) {
  data = qs.stringify(data)
  return request({
    url: '/jylab/entsesearch/recList',
    method: 'post',
    data
  })
}

/**
 * 根据筛选条件，获取中标企业搜索结果
 * @param data
 * @returns {*}
 */
export function ajaxGetSearchWinnerList(data) {
  data = qs.stringify(data)
  return request({
    url: '/jylab/entsesearch/proList',
    method: 'post',
    data
  })
}

/**
 * 获取搜索热词
 * @param data
 * @returns {*}
 */
export function ajaxGetSearchHotKeyList(data) {
  return request({
    url: '/jyapi/jybx/core/mobileHotWord',
    method: 'post',
    data
  })
}

/**
 * 获取运营商专版搜索动态配置项
 * @returns {*}
 */
export function ajaxGetSearchCriteria() {
  return request({
    url: '/jyapi/jybx/core/operatorSearchCriteria',
    method: 'post'
  })
}

// 获取已存筛选列表
export function getBiddingCustomSearchList(data) {
  return request({
    url: '/jyapi/jybx/core/operatorSearch',
    method: 'post',
    data
  })
}
