<template>
  <van-popup
    v-model="showKeepDialog"
    class="keep-label-popup"
    :close-on-click-overlay="!isLoading && !isAddLoading"
    :close-on-popstate="true"
    :get-container="getContainer"
    @close="$emit('close')"
    @open="$emit('open')"
  >
    <div
      class="keep-label-content-group"
      v-bind:class="{ 'disabled-event': isLoading || isAddLoading }"
    >
      <div class="keep-label-title-group">
        <slot name="title">
          <h3 class="title-text">{{ title }}</h3>
        </slot>
        <div class="icon-padding-box" @click="togglePopStatus(false)">
          <AppIcon name="close_heidi" />
        </div>
      </div>
      <div class="keep-label-content">
        <div class="keep-label-input-group">
          <van-field
            :maxlength="maxLength"
            v-model.trim="tagInput"
            type="text"
            :placeholder="placeholder"
          ></van-field>
          <van-button
            size="mini"
            v-show="isAddLoading || getShowAddButton"
            @click="addTag"
            :loading="isGetTagsLoading || isAddLoading"
            >{{ addText }}</van-button
          >
        </div>
        <div class="content">
          <AppEmpty
            v-if="!isGetTagsLoading && !getOptionLabels.length"
            state="sleep"
            >{{ emptyText }}</AppEmpty
          >
          <CheckboxGroup
            class="keep-label-check-group"
            :options="tagConfig.options"
            :config="getCheckBoxGroupConfig"
            :before-change-action="beforeChangeSelectAction"
            v-model="tags"
          />
          <van-loading v-if="isGetTagsLoading"></van-loading>
        </div>
      </div>
      <div class="keep-label-bottom-group">
        <van-button
          block
          @click="submitSave"
          :loading="isLoading"
          loading-text="保存中..."
          type="confirm"
          >{{ confirmText }}</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
import { Button, Field, Popup, Loading } from 'vant'
import { AppIcon, AppEmpty, CheckboxGroup } from '@/ui'

export default {
  name: 'KeepLabel',
  components: {
    [CheckboxGroup.name]: CheckboxGroup,
    [AppIcon.name]: AppIcon,
    [AppEmpty.name]: AppEmpty,
    [Loading.name]: Loading,
    [Button.name]: Button,
    [Field.name]: Field,
    [Popup.name]: Popup
  },
  props: {
    /**
     * 默认是否展示
     */
    open: {
      type: Boolean,
      default: true
    },
    /**
     * 弹窗标题
     */
    title: {
      type: String,
      default: '选择标签'
    },
    emptyText: {
      type: String,
      default: '暂无标签'
    },
    placeholder: {
      type: String,
      default: '请输入标签'
    },
    addText: {
      type: String,
      default: '添加并使用'
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    /**
     * 最大输入字符长度
     */
    maxLength: {
      type: Number,
      default: 10
    },
    maxSelectTipText: {
      type: String,
      default: '最多选择三个标签'
    },
    /**
     * checkbox-group config 配置项
     */
    checkGroupConfig: {
      type: Object,
      default: () => {}
    },
    /**
     * 最多选择标签数量
     */
    maxSelectLength: {
      type: Number,
      default: 3
    },
    /**
     * 添加新增标签前置函数，提供回调函数添加标签
     *
     * (item, action) => action(item)
     */
    addLabelAction: {
      type: Function,
      default: (item, action) => action(item)
    },
    /**
     * 获取标签集合函数，返回 check-group 所需数据集合
     */
    getLabelAction: {
      type: Function,
      default: async () => []
    },
    /**
     * 参考 vant getContainer，指定挂载节点
     */
    getContainer: {
      type: [String, Function],
      default: ''
    }
  },
  data() {
    return {
      showKeepDialog: false,
      isLoading: false,
      isAddLoading: false,
      isGetTagsLoading: true,
      tagInput: '',
      tags: [],
      tagConfig: {
        options: []
      }
    }
  },
  computed: {
    getShowAddButton() {
      return (
        this.tagInput !== '' && !this.getOptionLabels.includes(this.tagInput)
      )
    },
    getOptionLabels() {
      return this.tagConfig.options.map(
        (v) => v[this.getCheckBoxGroupConfig.findLabel]
      )
    },
    getMaxSelectStatus() {
      return this.tags.length >= this.maxSelectLength
    },
    getCheckBoxGroupConfig() {
      return Object.assign(
        {
          max: this.maxSelectLength,
          findKey: 'key',
          findLabel: 'label'
        },
        this.checkGroupConfig
      )
    }
  },
  mounted() {
    if (this.open) {
      this.showKeepDialog = true
      this.getKeepLabel()
    }
  },
  methods: {
    async getKeepLabel() {
      this.isGetTagsLoading = true
      const resultArr = await this.getLabelAction()
      if (Array.isArray(resultArr)) {
        this.tagConfig.options = [].concat(resultArr)
      }
      this.isGetTagsLoading = false
    },
    beforeChangeSelectAction(arr) {
      if (this.getMaxSelectStatus && arr.length >= this.maxSelectLength) {
        this.$toast(this.maxSelectTipText)
      }
      return arr
    },
    togglePopStatus(type) {
      this.showKeepDialog = type
    },
    addKeepLabel(item) {
      if (item) {
        this.tagConfig.options.unshift(item)
        this.tagInput = ''

        if (!this.getMaxSelectStatus) {
          const tempKey = item[this.getCheckBoxGroupConfig.findKey]
          if (!this.tags.includes(tempKey)) {
            this.tags.push(tempKey)
          }
        }
      }
    },
    addTag() {
      const item = {
        key: this.tagInput,
        label: this.tagInput
      }
      this.isAddLoading = true
      this.addLabelAction(item, (item) => {
        this.addKeepLabel(item)
        this.isAddLoading = false
      })
    },
    getTagList() {
      const tagList = this.tagConfig?.options
      if (Array.isArray(tagList) && tagList.length) {
        return tagList
      } else {
        return []
      }
    },
    submitSave() {
      this.isLoading = true
      const tagList = this.getTagList()
      const selectedTagIds = this.tags
      const selectedTagList = tagList.filter((t) =>
        selectedTagIds.includes(t.lid)
      )
      /**
       * 确认按钮点击回调，提供 { keys, options, complete }
       *
       * @event submit
       */
      this.$emit('submit', {
        keys: selectedTagIds, // 选中的标签的id(兼容)
        keysTags: selectedTagList, // 选中的标签的列表(包含id和名称)
        tagList: tagList, // 所有标签的列表
        options: this.tagConfig.options,
        complete: () => {
          this.isLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$keep-label-bg: #ffffff;
$keep-label-radius: 16px;

$kp-title--color: #171826;
$kp-title--size: 20px;
$kp-title--height: 30px;

$kp-add--color: #2abed1;
$kp-add--size: 14px;
$kp-add--height: 20px;

$kp-icon-close--color: #c0c4cc;
$kp-icon-close--size: 20px;

.disabled-event {
  pointer-events: none;
}

.keep-label {
  &-popup {
    background: $keep-label-bg;
    border-radius: $keep-label-radius;
  }

  &-bottom-group {
    padding: 24px;
    padding-bottom: 14px;
  }

  &-content-group {
    width: 327px;
    padding: 10px 0;
    .content {
      .van-loading {
        text-align: center;
        color: #2abed1;
      }
    }
  }

  &-title-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
  }

  &-input-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2abed1;
    ::v-deep .van-cell::after {
      content: unset;
    }
    ::v-deep .van-button {
      height: 40px;
      padding: 10px;
      padding-right: 16px;
      flex-shrink: 0;
      border-color: transparent;
      color: inherit;
    }
    ::v-deep input {
      width: 100%;
      transition: width 0.2s;
      flex: 1;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #5f5e64;
      padding: 13px 16px;
      &:focus {
        border-color: #2abed1;
      }
    }
  }

  &-check-group {
    padding: 0 16px;
    margin-top: -22px;
    max-height: 30vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.title-text {
  font-weight: 500;
  font-size: $kp-title--size;
  line-height: $kp-title--height;
  color: $kp-title--color;
}
.icon-padding-box {
  padding: 5px 0;
  .iconfont {
    font-size: $kp-icon-close--size;
    color: $kp-icon-close--color;
  }
}
.add-button {
  font-weight: 500;
  font-size: $kp-add--size;
  line-height: $kp-add--height;
  color: $kp-add--color;
}
</style>
