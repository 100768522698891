/**
 * P445 会员服务列表（整合版）
 */
export const serviceListData = [
  {
    项目信息: [
      {
        two: '拟在建项目',
        three:
          '提供发改委等政府部门审批、核准、备案等阶段的拟建和在建的工程项目信息，可以提供各阶段项目相关负责人及联系方式等。为用户提前介入项目运作提供有力支持，提升中标机率。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '推荐项目',
        three:
          '通过专业团队精心筛选优质项目，提供各阶段项目详细进展情况，负责人及联系方式，可全面了解项目信息，有效进行跟进。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '业主委托项目',
        three:
          '剑鱼标讯受业主方/采购单位委托发布其招标采购项目信息，客户可直接联系业主方/采购单位参与投标采购。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '审批项目',
        three:
          '提供已经通过部门审批，取得建设许可的项目，提供业主相关联系人及联系方式等。',
        four: 'icon-tick',
        five: 'icon-tick'
      }
    ]
  },
  {
    招标采购信息: [
      {
        two: '采购意向',
        three:
          '提供未发布招标公告前1-3个月，政府单位的采购意向信息，包含采购内容、预算金额、预计采购时间、采购联系人及联系方式等相关信息。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '招标预告',
        three:
          '提供公开招标前采购计划预告信息，便于潜在投标企业提前了解项目招标需求参与投标。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '招标公告',
        three:
          '每日更新10万条以上政府公开招标信息，提供公告正文、项目预算、项目联系人及联系方式等内容。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '招标结果',
        three:
          '提供项目中标、成交等结果类公告信息，包括中标单位、中标金额、中标项目详情、联系方式等内容。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '询价采购',
        three: '提供国家机关、事业单位和团体组织所需货物的询价采购信息。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '谈判采购',
        three: '提供国家机关、事业单位和团体组织所需货物的谈判采购信息。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '企业采购',
        three:
          '企业业主发布的直接采购货物，供应商可直接和业主对接洽谈采购事宜。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '招标信用信息',
        three:
          '提供合同、验收、违规等信用类信息。包括采购人、采购信息、联系人及联系方式等内容。',
        four: 'icon-tick',
        five: 'icon-tick'
      }
    ]
  },
  {
    业务拓展: [
      {
        two: '招标/采购进度监控<br>（项目进度监控）',
        three:
          '监控项目最新的招标、中标、开标等动态，并通过微信、邮件实时推送信息提醒，保证客户及时把握重要商机。',
        four: '100个',
        five: '500个'
      },
      {
        two: '业主监控',
        three:
          '监控业主最新发布的招标动态，并通过微信、邮件实时推送信息提醒，便于企业了解业主实时动态、及时跟标。',
        four: '100个',
        five: '500个'
      },
      {
        two: '企业情报监控',
        three:
          '监控企业工商变动信息、最新招标采购、中标、项目动态，并通过微信、邮件实时推送信息提醒，帮助企业了解竞争对手和合作伙伴的动向。',
        four: '100个',
        five: '500个'
      },
      {
        two: '潜在项目预测',
        three:
          '根据同类项目历史采购周期、工程项目采购阶段，预测下一阶段可能会采购项目信息，提供相关采购方的联系方式。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '企业人脉信息<br>（企业通讯录）',
        three:
          '通过招标采购大数据分析与挖掘，获取企业及企业历史中标项目的联系人及联系方式，帮助企业快速搭建自己的人脉商圈。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '业主人脉信息<br>（采购单位通讯录）',
        three:
          '通过招标采购大数据分析与挖掘，获取业主单位负责人联系方式，帮助企业快速拓展人脉，赢得更多合作机会。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '向业主推荐',
        three:
          '剑鱼标讯与众多采购单位/招标代理机构都有良好的合作关系，对于业主委托招标项目，会根据客户的企业资质、综合实力等，直接推荐给采购单位，帮其获得更多机会。',
        four: 'icon-red-close',
        five: 'icon-tick'
      },
      {
        two: '向业主唯一推荐',
        three:
          '剑鱼标讯与众多采购单位/招标代理机构都有良好的合作关系，对于业主委托招标的优质项目，会根据客户的企业资质、综合实力等，享受“向业主唯一推荐”，不再推荐其他客户，有效屏蔽竞争企业，减少竞争。',
        four: 'icon-red-close',
        five: '1次'
      },
      {
        two: '潜在客户挖掘',
        three:
          '根据区域、业务范围、客户类型帮助企业快速找到目标地区潜在业务需求客户及联系方式。',
        four: 'icon-red-close',
        five: 'icon-tick'
      },
      {
        two: '潜在竞争对手/合作伙伴挖掘',
        three:
          '根据区域、业务范围、客户类型帮助企业快速匹配到目标地区潜在的竞争对手/合作伙伴名录，获取渠道合作机会。',
        four: 'icon-red-close',
        five: 'icon-tick'
      },
      {
        two: '招标采购信息发布',
        three:
          '认证后的企业，支持发布采购信息、招标预告、招标公告等招标采购信息。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '供应信息发布',
        three:
          '认证后的企业，支持发布供应信息。提高信息曝光机会，获取更多合作机会。',
        four: '30条/月',
        five: '30条/月'
      },
      {
        two: '广告服务',
        three:
          '提供剑鱼平台高流量的广告位置，提高企业曝光机会，让客户更快找到您。',
        four: '议价',
        five: '议价'
      }
    ]
  },
  {
    阳光直采: [
      {
        two: '采购信息',
        three: '企业公开直采信息，直接对接采购人。',
        four: '议价',
        five: '议价'
      },
      {
        two: '采购发布',
        three: '采购发布成功后，可收到至少5家供应商参与报价。',
        four: '限时免费',
        five: '限时免费'
      }
    ]
  },
  {
    人脉管理: [
      {
        two: '人脉维护',
        three:
          '添加您密切关联的人和企业，自动生成熟人的项目信息，不漏掉任何最大概率能成单的项目。',
        four: '-',
        five: '面议'
      },
      {
        two: '潜客挖掘',
        three: '通过密切关联的人和企业，自动生成能够快速对接上的潜在客户。',
        four: '-',
        five: '面议'
      },
      {
        two: '商机挖掘',
        three: '通过密切关联的人和企业，自动生成能够快速对接的项目信息。',
        four: '-',
        five: '面议'
      },
      {
        two: '找人脉',
        three: '添加项目名称，自动生成精准的联系人通讯录。',
        four: '-',
        five: '面议'
      }
    ]
  },
  {
    营销分析: [
      {
        two: '市场分析周报',
        three:
          '提供本周项目分析周报及项目明细，动态分析本周市场招标项目预算、开标数量、项目规模、监控企业动态，精准掌握市场动向。',
        four: 'icon-red-close',
        five: 'icon-tick'
      },
      {
        two: '市场分析月报',
        three:
          '提供本月项目分析月报及项目明细，全面分析本月市场招标项目规模及数量、采购行业热度、采购单位、中标企业排行榜，全面分析市场变化趋势。',
        four: 'icon-red-close',
        five: 'icon-tick'
      },
      {
        two: '市场分析定制报告',
        three:
          '定制化分析报告，支持自定义范围分析市场规模和采购趋势、地区采购热度、客户分布情况以及细分市场明细，精准洞察客户、竞对、市场。',
        four: 'icon-red-close',
        // five: 'icon-tick'
        five_type: 'Tworows',
        five_text: '在线查看: ',
        five_ic: 'icon-tick',
        five_desc: '报告下载: 1份/年'
      },
      {
        two: '企业分析<br>（企业画像-中标分析）',
        three:
          '通过企业多维度数据分析，洞悉企业历史中标动态、市场区域分布、客户类型分布、重点及首次合作客户，利于了解潜在竞争对手的竞争实力。',
        // four: 'icon-tick',
        four_type: 'Tworows',
        four_text: '在线查看: ',
        four_ic: 'icon-tick',
        four_desc: '报告下载: 1份/年',
        // five: 'icon-tick'
        five_type: 'Tworows',
        five_text: '在线查看: ',
        five_ic: 'icon-tick',
        five_desc: '报告下载: 3份/年'
      },
      {
        two: '业主分析<br>（采购单位画像-采购分析）',
        three:
          '通过业主多维度采购分析，了解业主招标动向、采购规模方式、招标方式占比、合作代理机构、重点及首次合作企业，便于企业洞察业主采购需求，开发新客户、客户维护。',
        // four: 'icon-tick',
        // five: 'icon-tick'
        four_type: 'Tworows',
        four_text: '在线查看: ',
        four_ic: 'icon-tick',
        four_desc: '报告下载: 1份/年',
        five_type: 'Tworows',
        five_text: '在线查看: ',
        five_ic: 'icon-tick',
        five_desc: '报告下载: 3份/年'
      },
      {
        two: '投标决策分析',
        three:
          '分析同类项目，查看采购单位、项目地区、全国同类项目信息明细，获取同类项目热点中标企业、同类项目预算分布等信息，帮助投标人员编制标书、投标报价参考，辅助投标决策。',
        four: 'icon-red-close',
        five: 'icon-tick'
      },
      {
        two: '中标企业预测',
        three:
          '根据AI+大数据智能预测，预测项目潜在投标企业、中标概率、企业排名。',
        four: 'icon-red-close',
        five: '200次'
      }
    ]
  },
  {
    标讯服务: [
      {
        two: '高级搜索',
        three:
          '在按关键词搜索的基础上，支持按采购单位类型、项目名称、招标代理机构等多维条件搜索，可以找到与业务需求更匹配的商机。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '附件下载',
        three:
          '招标文件/报名表/采购清单等相关文件资料下载，方便了解公告详细内容。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '查看原文链接',
        three:
          '提供公告原始网站地址，便于用户核验信息真实性或去原网站购买标书投标。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '标讯收藏',
        three: '支持标讯收藏，添加收藏标签，方便用户随时查找关注标讯进展。',
        four: '5000条',
        five: '5000条'
      },
      {
        two: '服务平台',
        three: '支持在电脑或手机端网页、APP端、微信公众号使用剑鱼相关功能',
        four: 'icon-tick',
        five: 'icon-tick'
      }
    ]
  },
  {
    秘书服务: [
      {
        two: '标讯订阅',
        three:
          '按需订阅，支持按照区域、关键词、信息类型等精准订阅全国招标采购信息，通过微信、邮箱等多形式推送，及时获取精准项目信息。',
        // four: '订阅区域：全国<br>关键词：300组<br>信息推送：3000条/天',
        // five: '订阅区域：全国<br>关键词：300组<br>信息推送：3000条/天'
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '订阅关键词优化',
        three:
          '提供一对一售后客服服务，帮助客户根据业务需求优化订阅关键词，及时获取标讯商机。。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '产品操作培训',
        three:
          '提供售后产品功能使用说明和专业的产品使用培训服务，以实战形式让用户更快熟悉产品功能。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '资深客服服务',
        three:
          '提供一对一的专业资深客户专员服务，定期电话回访，解决服务中的任何问题。',
        four: 'icon-red-close',
        five: 'icon-tick'
      },
      {
        two: '微信提醒',
        three:
          '用户订阅商机信息后，系统每日会自动推送符合的业务信息至微信，便捷查看最新项目动态。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: 'APP提醒',
        three:
          '用户订阅商机信息后，系统每日会自动推送符合的业务信息至APP，以免漏掉重要项目信息。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '邮件提醒',
        three:
          '用户订阅商机信息后，系统每日会自动推送最新的业务信息至邮箱，方便汇总和查看最新项目信息。',
        four: 'icon-tick',
        five: 'icon-tick'
      }
    ]
  },
  {
    专家服务: [
      {
        two: '标书制作',
        three:
          '剑鱼标讯合作经验丰富的标书制作专家团队，提供一对一服务，中标率高。',
        four: '议价',
        five: '议价'
      },
      {
        two: '企业认证服务',
        three:
          '剑鱼合作专业企业认证服务团队，可办理ISO体系认证、信用评定、服务体系认证等证书，全程一对一服务，快速审核下证！',
        four: '议价',
        five: '议价'
      },
      {
        two: '招投标课程<br>（中标必听课）',
        three:
          '专为投标人打造的在线课程，邀请金牌讲师授课，抛弃传统教科书式教学，课程围绕招标实务技巧、招投标干货知识等全面解密招投标。',
        four: '议价',
        five: '议价'
      },
      {
        two: '剑鱼文库',
        three:
          '招投标垂直行业资料库，可以精准地找到全面的行业招投标资料，随查随用，更便捷。',
        four: '议价',
        five: '议价'
      },
      {
        two: '招投标攻略',
        three:
          '招投标专业知识库，提供投标指南、中标技巧、问答百科等信息，满足您的学习要求。',
        four: 'icon-tick',
        five: 'icon-tick'
      }
    ]
  },
  {
    数据服务: [
      {
        two: '数据导出',
        three:
          '根据业务需求，指定关键词、行业等条件，自由选择数据以表格形式导出行业标讯信息，帮助客户全面获取市场信息。',
        four: '100条/天',
        five: '200条/天'
      },
      {
        two: '数据定制服务',
        three:
          '通过定制化为您提供数据私有化服务方案，精准匹配您需要的数据内容，帮助客户分析市场、挖掘商机线索。',
        four: '议价',
        five: '议价'
      },
      {
        two: '数据对接服务',
        three:
          '千亿级数据支持API接口调用，满足企业低成本、高效接入数据的需求，支持多种应用场景。',
        four: '议价',
        five: '议价'
      },
      {
        two: '数据清洗服务',
        three:
          '拥有经验丰富的数据清洗团队，可根据客户需求，提供人工清洗数据服务，提高数据精准度，保证数据质量。',
        four: '议价',
        five: '议价'
      }
    ]
  },
  {
    企业管理: [
      {
        two: '企业订阅分发',
        three:
          '企业可统一订阅获取商机线索，按照业务区域、关键词等，通过自动化或手动方式分配线索给合适的销售人员重点关注及跟进，避免遗漏重要项目线索。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '客户管理',
        three:
          '从客户认领、客户添加、客户划转到客户跟踪、添加跟进记录，精细化管理客户；客户跟进全流程可视化数据看板，可查看企业总体客户及个人客户的跟进情况，帮助企业实现高效、客观的销售行为管理。',
        four: '议价',
        five: '议价'
      },
      {
        two: '账号使用统计',
        three:
          '企业可全面了解使用员工的标讯搜索、查看、数据导出动态，帮助管理者进行人员过程管理。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '企业协作',
        three:
          '在剑鱼平台内，支持将标讯、企业分析、业主分析转发给企业内同事；支持企业内员工一对一聊天、群聊等。',
        four: 'icon-tick',
        five: 'icon-tick'
      },
      {
        two: '年终报告',
        three:
          '输入公司名称、经营类型、业务区域、团队成员等销售数据汇总分析后，系统自动生成个人或团队年终报告，多维度展示全年业绩数据，重点项目跟进情况，让年终汇报更轻松。',
        four: 'icon-tick',
        five: 'icon-tick'
      }
    ]
  }
]
