/**
 * 不同平台的链接集合
 *
 * 类似的文件还有 @/assets/js/productLink.js
 */

export const LINKS = {
  下载APP: {
    app: '',
    h5: 'https://h5.jianyu360.cn/front/downloadapppage/normal', // 需要参数 ?source=H5 / source=WX
    wx: 'https://wx.jianyu360.cn/front/downloadapppage/normal'
  },
  客服: {
    app: '/jyapp/free/customer',
    h5: '/jyapp/free/customer',
    wx: '/big/wx/page/customer'
  },
  我的订单页面: {
    app: '/jyapp/front/myOrder/toMyOrder',
    h5: '/jyapp/front/myOrder/toMyOrder',
    wx: '/weixin/pay/toMyWxOrder'
  },
  设置页面: {
    app: '/jyapp/free/set',
    h5: '/jyapp/free/set',
    wx: '/front/wxMyOrder/myset'
  },
  绑定手机号: {
    app: '/jyapp/account/phone/bind',
    h5: '/jyapp/account/phone/bind',
    wx: '/front/account/phone/bind'
  },
  绑定手机号合并: {
    app: '/jyapp/account/phone/bind?mode=mergeBind',
    h5: '/jyapp/account/phone/bind?mode=mergeBind',
    wx: '/front/account/phone/bind?mode=mergeBind'
  },
  超级订阅设置页面: {
    app: '/jyapp/vipsubscribe/toSubVipSetPage?vSwitch=v',
    h5: '/jyapp/vipsubscribe/toSubVipSetPage?vSwitch=v',
    wx: '/front/vipsubscribe/toSubVipSetPage?vSwitch=v'
  },
  超级订阅落地页: {
    app: '/jyapp/vipsubscribe/introducePage',
    h5: '/jyapp/vipsubscribe/introducePage',
    wx: '/front/vipsubscribe/introducePage'
  },
  APP登录页: {
    app: '/jyapp/free/login',
    h5: '/jyapp/free/login'
  },
  标讯详情页前缀: {
    app: '/jyapp/article/content/',
    h5: '/jyapp/article/content/',
    wx: '/article/content/'
  },
  旧版企业信息前缀: {
    app: '/jyapp/followent/newInfo/',
    h5: '/jyapp/followent/newInfo/',
    wx: '/jylab/followent/newInfo/'
  },
  订阅管理页面: {
    app: '/jyapp/vipsubscribe/toSubVipSetPage',
    h5: '/jyapp/vipsubscribe/toSubVipSetPage',
    wx: '/front/vipsubscribe/toSubVipSetPage'
  },
  关键词列表: {
    app: '/jyapp/vipsubscribe/toSetKeyWordPage',
    h5: '/jyapp/vipsubscribe/toSetKeyWordPage',
    wx: '/front/vipsubscribe/toSetKeyWordPage'
  },
  省份订阅包设置省份: {
    app: '/jyapp/areaPack/page/set_area',
    h5: '/jyapp/areaPack/page/set_area',
    wx: '/areaPack/wx/page/set_area'
  },
  老关键词列表: {
    app: '/jyapp/wxkeyset/keyset/index',
    h5: '/jyapp/wxkeyset/keyset/index',
    wx: '/wxkeyset/keyset/index'
  },
  留资: {
    app: '/jyapp/frontPage/bigmember/free/perfect_info',
    h5: '/jyapp/frontPage/bigmember/free/perfect_info',
    wx: '/weixin/frontPage/bigmember/free/perfect_info'
  },
  用户反馈: {
    app: '/jyapp/swordfish/feedback',
    h5: '/jyapp/swordfish/feedback',
    wx: '/swordfish/feedback'
  },
  企业画像页面: {
    // query参数eId
    app: '/jyapp/big/page/ent_portrait',
    h5: '/jyapp/big/page/ent_portrait',
    wx: '/weixin/frontPage/collection/sess/ent_portrait'
  },
  标讯收藏: {
    // query参数tag
    app: '/jyapp/frontPage/collection/sess/index',
    h5: '/jyapp/frontPage/collection/sess/index',
    wx: '/weixin/frontPage/collection/sess/index'
  },
  大会员超级订阅采购单位画像页面: {
    // query参数entName
    app: '/jyapp/big/page/unit_portrayal',
    h5: '/jyapp/big/page/unit_portrayal',
    wx: '/big/wx/page/unit_portrayal'
  },
  商机管理采购单位画像页面: {
    // query参数entName
    app: '/jyapp/big/page/client_portrayal',
    h5: '/jyapp/big/page/client_portrayal',
    wx: '/big/wx/page/client_portrayal'
  },
  数据导出页面: {
    app: '/jyapp/front/dataExport/toSieve',
    h5: '/jyapp/front/dataExport/toSieve',
    wx: '/front/wx_dataExport/toSieve'
  },
  中标必听课页面: {
    app: '/jyxsapp/',
    h5: '/jyxsapp/',
    wx: '/jyxsapp/'
  },
  数据流量包页面: {
    app: '/jy_mobile/common/order/create/datapack',
    h5: '/jy_mobile/common/order/create/datapack',
    wx: '/jy_mobile/common/order/create/datapack'
  },
  APP下载页面: {
    app: '',
    h5: '',
    wx: '/front/downloadapppage/normal?source=weixinAutoRpl'
  },
  商机管理页面: {
    app: '/page_entniche_new/page/introduce_page.html',
    h5: '/page_entniche_new/page/introduce_page.html',
    wx: '/weixin/frontPage/entpc/free/entpc'
  },
  剑鱼文库页面: {
    app: '/page_docs_mobile/home',
    h5: '/page_docs_mobile/home',
    wx: '/page_docs_mobile/home'
  },
  结构化数据页面: {
    app: '/jyapp/structed/index',
    h5: '/jyapp/structed/index',
    wx: '/front/structed/index'
  },
  数据报告页面: {
    app: '/datareport/page/introduce',
    h5: '/datareport/page/introduce',
    wx: '/datareport/page/introduce'
  },
  企业情报监控列表: {
    app: '/jyapp/big/page/ent_follow',
    h5: '/jyapp/big/page/ent_follow',
    wx: '/big/wx/page/ent_follow'
  },
  业主监控列表: {
    app: '/jyapp/big/page/client_list',
    h5: '/jyapp/big/page/client_list',
    wx: '/big/wx/page/client_list'
  },
  项目进度监控列表: {
    app: '/jyapp/big/page/pro_follow_list',
    h5: '/jyapp/big/page/pro_follow_list',
    wx: '/big/wx/page/pro_follow_list'
  },
  商机管理项目进度监控列表: {
    app: '/jyapp/big/page/client_follow_list',
    h5: '/jyapp/big/page/client_follow_list',
    wx: '/big/wx/page/client_follow_list'
  },
  // 商机管理项目详情
  商机管理项目进度监控: {
    app: '/jyapp/big/page/client_follow_detail',
    h5: '/jyapp/big/page/client_follow_detail',
    wx: '/big/wx/page/pro_follow_detail'
  },
  // 大会员以及其他用户项目详情
  项目详情页: {
    app: '/jyapp/big/page/pro_follow_detail',
    h5: '/jyapp/big/page/pro_follow_detail',
    wx: '/big/wx/page/pro_follow_detail'
  },
  周报月报: {
    app: '/vipreport/page/subreport.html',
    h5: '/vipreport/page/subreport.html',
    wx: '/vipreport/page/subreport.html'
  },
  大会员落地页面: {
    app: '/jyapp/big/page/landingPage',
    h5: '/jyapp/big/page/landingPage',
    wx: '/big/wx/page/landingPage'
  },
  人脉落地页面: {
    app: '/jyapp/frontPage/declaration/free/relationship',
    h5: '/jyapp/frontPage/declaration/free/relationship',
    wx: '/weixin/frontPage/declaration/free/relationship'
  },
  投标决策分析筛选页面: {
    app: '/jyapp/big/page/analysis_result',
    h5: '/jyapp/big/page/analysis_result',
    wx: ''
  },
  大会员首页: {
    app: '/jyapp/big/page/main_root',
    h5: '/jyapp/big/page/main_root',
    wx: '/big/wx/page/main_root'
  },
  打赏页面: {
    app: '',
    h5: '',
    wx: '/jypay/weixin/reward/redirect'
  },
  数据导出记录: {
    app: '/jyapp/dataPack/recordList',
    h5: '/jyapp/dataPack/recordList',
    wx: '/swordfish/dataPack/recordList'
  },
  企业认证服务: {
    app: '/page_activity_mobile/tripartiteAuth/index.html',
    h5: '/page_activity_mobile/tripartiteAuth/index.html',
    wx: '/page_activity_mobile/tripartiteAuth/index.html'
  },
  市场分析报告: {
    app: '/jyapp/big/page/report_analysis',
    h5: '/jyapp/big/page/report_analysis',
    wx: '/big/wx/page/report_analysis'
  },
  阳光采购落地页: {
    app: '/jyapp/frontPage/sunshine/free/index',
    h5: '/jyapp/frontPage/sunshine/free/index',
    wx: '/weixin/frontPage/sunshine/free/index'
  },
  首页: {
    app: '/jyapp/jylab/mainSearch',
    h5: '/jyapp/jylab/mainSearch',
    wx: '/jylab/mainSearch?msg=0'
  }
}
