const contentTypeMap = [
  // 'content',
  'yyszb',
  'entservice',
  'bdprivate',
  'mailprivate',
  'bdcontent',
  'indexcontent',
  'advancedProject'
]
function createArticleAlias(typeList = []) {
  const list = []
  typeList.forEach((type) => {
    list.push(`/article/${type}/:id`)
    list.push(`/article/${type}/:id.html`)
    list.push(`/jyapp/article/${type}/:id`)
    list.push(`/jyapp/article/${type}/:id.html`)
  })
  return list
}

const articleAliasList = createArticleAlias(contentTypeMap)

export default [
  {
    path: '/content/:id*.html',
    name: 'article-content',
    alias: [
      '/article/content/:id',
      '/nologin/content/:id',
      '/jyapp/article/content/:id.html',
      '/jyapp/nologin/content/:id.html',
      // 配置详情页其他别名
      ...articleAliasList
    ],
    component: () => import('@/views/article/content.vue'),
    meta: {
      header: true,
      title: '公告信息'
    }
  },
  {
    path: '/issued/:id',
    name: 'issued-content',
    component: () => import('@/views/article/index.vue'),
    meta: {
      header: true,
      title: '供应信息'
    }
  }
]
