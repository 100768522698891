import qs from 'qs'
import request from '@/api'

/**
 * 获取用户信息
 */
export function ajaxUserPower(config = {}) {
  return request(
    Object.assign(
      {
        url: '/bigmember/use/isAdd',
        noToast: true,
        method: 'post'
      },
      config
    )
  )
}

/**
 * 获取用户大会员订阅信息
 */
export function getUserSubscribeInfo() {
  return request({
    url: '/bigmember/use/info',
    method: 'post'
  })
}

/**
 * 根据输入词获取采购单位名称，用于模糊搜索
 */
export function ajaxGetBuyerAssociation(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/search/buyer/association',
    method: 'post',
    data
  })
}

/**
 * 根据输入词获取企业名称，用于模糊搜索
 */
export function ajaxGetCompanyAssociation(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/search/ent/association',
    method: 'post',
    data
  })
}

/**
 * 附件下载，发送到邮箱
 */
export function attachmentSendToEmail(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/attachment/email',
    method: 'post',
    data
  })
}

/**
 * 一键订阅、暂不订阅
 */
export function freeUserSubscribeSearch(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/subscribe/freeUser/subscribeSearch',
    method: 'post',
    data
  })
}

// 免费用户是否弹出一键订阅弹框查询
export function freeUserNeedOneKeySubscribe() {
  return request({
    url: '/bigmember/subscribe/freeUser/searchSubscribe',
    method: 'get',
    params: {
      t: Date.now()
    }
  })
}

/**
 * 采购单位关注、认领（大会员）
 */
export function bigmemberFollowAndClaim(data) {
  return request({
    url: '/publicapply/customer/attention',
    method: 'post',
    data
  })
}

// 查询项目是否已经关注
// 同时, 查询标讯是否关联有可关注的项目
export function projectFollowCheck(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/follow/project/check',
    method: 'post',
    data
  })
}

// 获取项目详情
export function projectDetailApi(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/follow/project/detail',
    method: 'post',
    data
  })
}

// 获取采购 buyer、 中标 winner 企业基础画像信息
export function ajaxGetMiniEntInfo(type, id) {
  return request({
    url: `/bigmember/portrait/${type}/miniData`,
    method: 'post',
    noToast: true,
    data: qs.stringify({
      [type === 'buyer' ? 'buyer' : 'entId']: id
    })
  })
}

// 获取标讯详情页附件信息
export function ajaxGetAttachmentList(data) {
  return request({
    url: '/bigmember/attachment/get',
    method: 'post',
    noToast: true,
    data: qs.stringify(data)
  })
}

// 下载项目报告，获取报告url
export function getProjectReport(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/project/getPdfFile',
    method: 'POST',
    data
  })
}
// 定制化分析数据
export function getAnalysisResult(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/marketAnalysis/getAnalysisResult',
    method: 'POST',
    data
  })
}

// 我关注的企业列表
export function getEntFollowList(data) {
  data = qs.stringify(data)
  return request({
    url: '/bigmember/follow/ent/labelGroupList',
    method: 'post',
    data
  })
}
