import BaseModel from '../../../core/base'
import useSummaryModel from '../transform/summary2'
import useCommonTitleModel from '../transform/content'
import useThirdPartyVerifyModel from '../transform/third-party-verify'
import { replaceKeywordWithRichText } from '@jy/util'
const thirdPartyVerify = useThirdPartyVerifyModel()

class ContentModel extends BaseModel {
  constructor(config) {
    super(config)
  }
  createModel() {
    return {
      moduleShow: {
        // 正文模块是否显示
        content: false,
        // 摘要模块是否显示
        summary: false
      },
      content: useCommonTitleModel().createModel(),
      summary: useSummaryModel().createModel()
    }
  }

  formatModel(data, isInit = false) {
    const result = this.createModel()

    const commonTitleModel = useCommonTitleModel()
    const summaryModel = useSummaryModel()

    // 基础信息
    if (data?.baseInfo) {
      result.content = commonTitleModel.transformModel(data)
    }
    // 摘要
    if (data?.abstract) {
      result.summary = summaryModel.transformModel(data)
    }

    if (result.content) {
      result.content.titleHighlighted = this.highlightTitleHTML(result.content.title, data, result)
      try {
        const _summary = summaryModel.getSummaryContentObject(data)
        result.content._summary = _summary
      } catch (error) {
        console.log(error)
      }
      
      try {
        result.content.contentHighlighted = this.highlightContentHTML(result.content.content, data, result)
      } catch (error) {
        console.error(error)
      }
    }

    // 根据数据情况判断模块是否展示
    result.moduleShow.content = !!result.content?.content
    result.moduleShow.summary= Array.isArray(result.summary?.list) && result.summary?.list.length > 0

    return result
  }

  // 高亮标题
  highlightTitleHTML (title, data, formatted) {
    if (!title) return ''
    const { baseInfo } = data
    const projectName = baseInfo?.projectName
    const projectCode = baseInfo?.projectCode
    // 下划线高亮项目名称编号
    if (projectName && title.toLowerCase().indexOf(projectName.toLowerCase()) > -1) {
      title = replaceKeywordWithRichText(title, projectName, '<span class="keyword keyword-underline project project-name hide-underline">$1</span>')
    }
    if (projectCode && title.toLowerCase().indexOf(projectCode.toLowerCase()) > -1) {
      title = replaceKeywordWithRichText(title, projectCode, '<span class="keyword keyword-underline project project-code">$1</span>')
    }

    // ------------------
    // 关键词高亮
    let highlightKeys = []
    if (formatted.content && formatted.content.highlightKeys) {
      highlightKeys = formatted.content.highlightKeys
    }
    highlightKeys.forEach((key) => {
      title = replaceKeywordWithRichText(title, key, '<span class="keyword highlight-text-orange-bd">$1</span>')
    })
    return title
  }

  // 高亮内容文本
  highlightContentHTML(content, data, formatted) {
    if (!content) return ''
    const { baseInfo } = data
    const { summary } = formatted
    const projectName = baseInfo?.projectName
    const projectCode = baseInfo?.projectCode

    content = content.replace(/[^\{\u4e00-\u9fa5]{1,90}{[^\}\u4e00-\u9fa5]+?}/g, '').trim()

    let tempNode = document.createElement('div')
    tempNode.innerHTML = content




    // 下划线高亮项目名称编号
    if(projectName && content.toLowerCase().indexOf(projectName.toLowerCase()) > -1){
      content = replaceKeywordWithRichText(content, projectName, '<span class="keyword keyword-underline my-follow project project-name hide-underline">$1</span>')
    }
    if(projectCode && content.toLowerCase().indexOf(projectCode.toLowerCase()) > -1){
      content = replaceKeywordWithRichText(content, projectCode, '<span class="keyword keyword-underline my-follow project project-code">$1</span>')
    }
    // 下划线高亮中标企业
    const winners = summary.winners
    if (Array.isArray(winners) && winners.length > 0) {
      for (let i = 0; i < winners.length; i++) {
        const winnerName = winners[i].name
        const winnerId = winners[i].id
        if (winnerName && content.toLowerCase().indexOf(winnerName.toLowerCase()) > -1) {
          content = replaceKeywordWithRichText(content, winnerName, `<span data-eid='${winnerId}' class='keyword keyword-underline winner-name my-follow-ent'>$1</span>`)
        }
      }
    }
    // 表格兼容
    if(content.length > 10 && content.substring(0,6).toLowerCase() === '<tbody' && content.substring(content.length-8).toLowerCase() == '</tbody>'){
      content = '<table>' + content + '</table>'
    }
    // 表格外面添加盒子
    content = content.replace(/<table/g, `<div class="content-table-container"><table`).replace(/<\/table>/g, `</table></div>`)

    // 高亮第三方服务
    if (content) {
      content = thirdPartyVerify.replaceKeysAndInsertMark(content)
    }
    // ------------------
    // 关键词高亮
    let highlightKeys = []
    if (formatted.content && formatted.content.highlightKeys) {
      highlightKeys = formatted.content.highlightKeys
    }
    highlightKeys.forEach((key) => {
      content = replaceKeywordWithRichText(content, key, '<span class="keyword highlight-text-orange-bd">$1</span>')
    })

    // 将多个连续的br替换成一个
    content = content.replace(/(<br\s*\/?>)+/gi, '<br>')
    content = content.replace(/(<br>\s*)+/gi, '<br>')
    content = content.replace(/^((<br\/?>)|(&nbsp;))+/g, '')
    content = content.replace(/((<br\/?>)|(&nbsp;))+$/g, '')

    // freeView点击查看高亮
    // <span class="freeView">点击查看</span>     ==替换==>    <span class="freeView free-view highlight-text">点击查看</span>
    content = content.replace(/freeView/g, 'freeView free-view highlight-text')
    return content
  }
}

/**
 * /publicapply/detail/baseInfo
 * 基础信息接口数据模型转换
 */
function useContentModel() {
  return new ContentModel()
}

export default useContentModel
