export const MESSAGE = {
  1: {
    title: '活动通知',
    icon: new URL('@/assets/image/message/huodong.png', import.meta.url).href
  },
  2: {
    title: '服务通知',
    icon: new URL('@/assets/image/message/fuwu.png', import.meta.url).href
  },
  3: {
    title: '订阅消息',
    icon: new URL('@/assets/image/message/dingyue.png', import.meta.url).href
  },
  4: {
    title: '项目动态',
    icon: new URL('@/assets/image/message/xiangmu.png', import.meta.url).href
  },
  5: {
    title: '企业动态',
    icon: new URL('@/assets/image/message/qiye.png', import.meta.url).href
  },
  6: {
    title: '分析报告',
    icon: new URL('@/assets/image/message/fenxi.png', import.meta.url).href
  },
  7: {
    title: '系统通知',
    icon: new URL('@/assets/image/message/xitong.png', import.meta.url).href
  },
  8: {
    title: '剑鱼学堂',
    icon: new URL('@/assets/image/message/xuetang.png', import.meta.url).href
  },
  9: {
    title: '商机情报',
    icon: new URL('@/assets/image/message/shangji.png', import.meta.url).href
  }
}
