import request from '@/api'
import qs from 'qs'

/**
 * 获取用户消息列表，用于首页展示
 */
export function ajaxGetMessageCardList() {
  return request({
    url: '/jymessageCenter/latestNews',
    method: 'post'
  })
}

/**
 * 获取用户消息未读列表条数
 */
export function ajaxGetMessageCount() {
  return request({
    url: '/jymessageCenter/getCount',
    method: 'get',
    noToast: true
  })
}

/**
 * 修改用户消息阅读状态
 */
export function ajaxSetMessageStatus(data) {
  data = qs.stringify(data)
  return request({
    url: '/jymessageCenter/markRead',
    method: 'post',
    data
  })
}

/**
 * 消息列表
 */
export function ajaxGetMessageList(data) {
  data = qs.stringify(data)
  return request({
    url: '/jymessageCenter/classAndNew',
    method: 'post',
    data
  })
}

/**
 * 获取私信
 */
export function ajaxGetPrivateLetter(data) {
  return request({
    url: '/jyapi/message/messageCount',
    method: 'post',
    noToast: true,
    data
  })
}

/**
 * 获取置顶消息
 */
export function ajaxGetLastMessage(data) {
  data = qs.stringify(data)
  return request({
    url: '/jymessageCenter/lastMessage',
    method: 'post',
    data
  })
}

/**
 * 消息点击
 */
export function ajaxClickMessage(data) {
  return request({
    url: '/jymessageCenter/clickMessage',
    method: 'post',
    data
  })
}

/**
 * 私信
 */
export function ajaxMsgUserList(data) {
  return request({
    url: '/jyapi/message/userList',
    method: 'post',
    data
  })
}

/**
 * 消息详情列表
 */
export function ajaxMsgDetailList(data) {
  data = qs.stringify(data)
  return request({
    url: '/jymessageCenter/messageList',
    method: 'post',
    data
  })
}

/**
 * 设置已读
 */
export function ajaxSetReadStatus(data) {
  data = qs.stringify(data)
  return request({
    url: '/jymessageCenter/setReadStatus',
    method: 'post',
    data
  })
}

/**
 * appClick
 */
export function ajaxAppClickMessage(data) {
  data = qs.stringify(data)
  return request({
    url: '/jymessageCenter/appClickMessage',
    method: 'post',
    data
  })
}

/**
 * 获取消息列表
 */
export function ajaxMessageList(data) {
  // data = qs.stringify(data)
  return request({
    url: '/jyapi/messageCenter/MessageList',
    method: 'post',
    data
  })
}

/**
 * 首页、工作桌面未读消息、未读消息数量
 */
export function ajaxUnreadMessages(data) {
  // data = qs.stringify(data)
  return request({
    url: '/jyapi/messageCenter/unreadMessages',
    method: 'post',
    data
  })
}

/**
 * 一键清空未读消息
 */
export function ajaxClearUnreadMsg(data) {
  data = qs.stringify(data)
  return request({
    url: '/jyapi/messageCenter/ClearUnreadMsg',
    method: 'post',
    data
  })
}

/**
 * 获取消息分类
 */
export function ajaxGetMsgType(data) {
  // data = qs.stringify(data)
  return request({
    url: '/jyapi/messageCenter/getMsgType',
    method: 'post',
    data
  })
}

/**
 * 点击查看消息消息状态修改成已读
 */
export function ajaxMarkRead(data) {
  // data = qs.stringify(data)
  return request({
    url: '/jyapi/messageCenter/markRead',
    method: 'post',
    data
  })
}

/**
 * 查看消息详情
 */
export function ajaxMessageDetail(data) {
  // data = qs.stringify(data)
  return request({
    url: '/jyapi/messageCenter/messageDetail',
    method: 'post',
    data
  })
}

/**
 * 消息查看记录
 */
export function ajaxMessageOpenLog(data) {
  // data = qs.stringify(data)
  return request({
    url: '/jyapi/messageCenter/msgOpenLog',
    method: 'post',
    data
  })
}
