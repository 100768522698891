import { getEntFollowList } from '@/api/modules/'

export default {
  namespaced: true,
  state: () => ({
    // 企业分组数据
    entGroupList: []
  }),
  mutations: {
    setEntGroupList(state, entGroupList) {
      state.entGroupList = entGroupList
    }
  },
  actions: {
    async setEntGroupList({ commit }) {
      const res = await getEntFollowList()
      if (res.error_code === 0) {
        commit('setEntGroupList', res.data?.groupUserArr || [])
        return res || []
      }
    }
  },
  getters: {
    getEntGroupList: (state) => {
      return state.entGroupList
    }
  }
}
