import { dateFromNow } from '@jy/util'

/**
 * 模型转换-招标、采购进度数据
 * @param data
 * @return {{id: string, tag: *, time: (*|string), isActive: *, title: string}}
 */
function tranProjectProgressItem(data) {
  const { id = '', title = '' } = data
  return {
    _data: data,
    id,
    tag: data?.subtype || data?.toptype,
    time: data?.publishTime ? dateFromNow(data?.publishTime * 1000) : '',
    isActive: data?.label,
    bidAmount: data?.bidAmount ? data?.bidAmount : 0,
    title
  }
}

/**
 * 模型转换-招标、采购进度数据列表
 * @param data
 * @return {{name: string, list: *[]}}
 */
function tranProjectProgress(data) {
  const projectProgressModel = {
    list: [],
    name: ''
  }

  projectProgressModel.list = data.list?.map((item) =>
    tranProjectProgressItem(item)
  )
  projectProgressModel.name = data.projectName

  return projectProgressModel
}

export default tranProjectProgress
