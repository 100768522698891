export default [
  {
    path: '/joined/list',
    name: 'JoinedProjectList',
    component: () => import('@/views/project/JoinedList.vue'),
    meta: {
      header: true,
      title: '参标项目'
    }
  },
  {
    path: '/joined/operate',
    name: 'JoinedProjectOperate',
    component: () => import('@/views/project/JoinedProjectOperate.vue'),
    meta: {
      header: true,
      title: '参标项目'
    }
  },
  {
    path: '/operatingRecord',
    name: 'operatingRecord',
    component: () =>
      import('@/views/project/operatingRecord/operatingList.vue'),
    meta: {
      header: true,
      title: '操作记录'
    }
  },
  {
    path: '/ReferenceKanban',
    name: 'ReferenceKanban',
    component: () => import('@/views/project/ReferenceKanban/index.vue'),
    meta: {
      header: true,
      title: '参标项目看板'
    }
  }
]
