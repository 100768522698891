export function calcAreaText(area = {}, splitter = '、') {
  if (!area) return
  if (Object.keys(area).length <= 0) {
    return '全国'
  }
  // 选择全省的
  const pInfo = {
    list: [],
    textList: []
  }
  // 选全市的
  const cInfo = {
    list: [],
    textList: []
  }
  // 选区的
  const xInfo = {
    list: [],
    textList: []
  }

  for (const pName in area) {
    const pObj = area[pName]
    if (pObj && Object.keys(pObj).length > 0) {
      for (const cName in pObj) {
        const cArr = pObj[cName]
        if (Array.isArray(cArr) && cArr.length > 0) {
          xInfo.list = xInfo.list.concat(cArr)
          // const newTextList = cArr.map(item => `${cName}${item}`)
          const newTextList = cArr.map(item => `${item}`)
          xInfo.textList = xInfo.textList.concat(newTextList)
        } else {
          cInfo.list.push(cName)
          cInfo.textList.push(`${cName}`)
        }
      }
    } else {
      pInfo.list.push(pName)
      pInfo.textList.push(pName)
    }
  }

  // console.log(pInfo, cInfo, xInfo)
  const textArr = [].concat(pInfo.textList).concat(cInfo.textList).concat(xInfo.textList)
  return textArr.join(splitter)
}