// 商机情报
export default [
  {
    path: '/detail/:id',
    name: 'business-detail',
    component: () => import('@/views/business/Detail.vue'),
    meta: {
      header: true,
      title: '公告信息'
    }
  }
]
