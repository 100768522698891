import Storage from './storage'
import config from '../../../package.json'
import Cookies from 'js-cookie'

export const appStorage = new Storage({
  prefix: (config?.name || 'jy-mobile').toUpperCase() + '-'
})

function check(storage, now, { key = 'ONLY_CHECK_SESSION' } = {}) {
  const oldSESSIONID = storage.get(key, now)
  const status = now === oldSESSIONID
  if (!status) {
    storage.delete({
      login: true
    })
  }
  return {
    storage,
    success: () => {
      storage.set(key, now)
    }
  }
}

export function checkCacheValidity(id) {
  const onlyID = Cookies.get('SESSIONID')
  return check(appStorage, id || onlyID)
}
