import BaseModel from '../../../core/base'
import tranProjectProgress from '../transform/project-progress'
import tranServices from '../transform/services'
import { formatMoney } from '@jy/util'

class ContentExpandModel extends BaseModel {
  constructor(config) {
    super(config)
  }
  createModel() {
    return {
      moduleShow: {
        // 招投标进度
        timeline: false,
        // 投标服务
        tbService: false,
        // 商机推荐(招标推荐)
        zbRecommend: false,
        // 客户推荐
        customerRecommend: false
      },
      projectProgress: {
        list: [],
        name: ''
      },
      services: [],
      recommendProjects: {
        list: [],
        search: {},
        more: false,
        total: 0,
        title: '超前项目',
        popup: false
      },
      recommendBuyers: {
        list: [],
        more: false,
        total: 0,
        title: ''
      },
      recommendWinners: {
        list: [],
        more: false,
        total: 0,
        title: ''
      },
      recommendCustomers: {}
    }
  }

  preSortBiddingList(list) {
    if (Array.isArray(list)) {
      return list.map((r) => {
        return this.preSortBiddingItem(r)
      })
    } else {
      return []
    }
  }

  preSortBiddingItem(item) {
    let bidAmount = ''
    // 有中标金额取中标金额，没有取预算，预算没有置空
    if (item?.bidAmount) {
      bidAmount = formatMoney(item?.bidAmount - 0)
    } else if (item?.budget) {
      bidAmount = formatMoney(item?.budget - 0)
    }

    const area = item.area || '全国'
    const type = item?.type || item?.subtype
    const buyerclass = item.buyerClass || ''

    const tagList = [
      area,
      buyerclass,
      type,
      bidAmount
    ].filter((v) => v)

    return {
      id: item.id,
      title: item.title,
      tags: tagList,
      area,
      type,
      buyerclass,
      bidAmount,
      time: item.publishTime ? item.publishTime * 1000 : undefined,
      _o: item
    }
  }

  formatModel(data, isInit = false) {
    const result = this.createModel()
    // 项目进度
    if (data?.schedule) {
      result.projectProgress = tranProjectProgress(data.schedule)
    }
    // 服务推荐
    if (data?.services) {
      result.services = tranServices(data.services)
    }
    if (data?.recommend?.ahead) {
      result.recommendProjects = data.recommend.ahead
      result.recommendProjects.list = this.preSortBiddingList(result.recommendProjects.list)
    }
    if (data?.recommend?.buyer) {
      result.recommendBuyers = data.recommend.buyer
      result.recommendBuyers.list = this.preSortBiddingList(result.recommendBuyers.list)
    }
    if (data?.recommend?.winner) {
      result.recommendWinners = data.recommend.winner
      result.recommendWinners.list = this.preSortBiddingList(result.recommendWinners.list)
    }
    if (data?.customerRec) {
      if (Array.isArray(data?.customerRec?.list)) {
        data.customerRec.list = data.customerRec.list.map((c) => {
          const arr = []
          if (c.area) {
            arr.push(c.area)
          }
          if (c.city) {
            arr.push(c.city)
          }
          return {
            ...c,
            totalAmountFormatted: c.totalAmount ? formatMoney(c.totalAmount) : '',
            areaCity: arr.length >= 1 ? arr.filter(a => !!a).join(' ') : ''
          }
        })
      }
      result.recommendCustomers = data.customerRec
    }

    // 根据数据情况判断模块是否展示
    // 招投标进度
    result.moduleShow.timeline = Array.isArray(result.projectProgress?.list) && result.projectProgress?.list.length > 0
    // 投标服务
    result.moduleShow.tbService = Array.isArray(result.services) && result.services.length > 0
    // 商机推荐(招标推荐)
    const recProjectsExist = Array.isArray(result.recommendProjects?.list) && result.recommendProjects?.list.length > 0
    const recBuyersExist = Array.isArray(result.recommendBuyers?.list) && result.recommendBuyers?.list.length > 0
    const recWinnersExist = Array.isArray(result.recommendWinners?.list) && result.recommendWinners?.list.length > 0
    result.moduleShow.zbRecommend = recProjectsExist || recBuyersExist || recWinnersExist
    // 客户推荐(这个比较特殊。如果是有权限用户(权限7)，则有数据展示，无数据不展示。无权限7用户则一直展示，展示广告引导)
    // 在页面中使用是请注意权限判断，此处只判断是否有数据
    result.moduleShow.customerRecommend = Array.isArray(result.recommendCustomers?.list) && result.recommendCustomers?.list.length > 0

    return result
  }
}

/**
 * /publicapply/detail/advancedInfo
 * 扩展信息接口数据模型转换
 */
function useContentExpandModel() {
  return new ContentExpandModel()
}

export default useContentExpandModel
