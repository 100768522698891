import { cloneDeep } from 'lodash'
import useContentModel from '@jy/data-models/modules/article/model/content'
import useExpandModel from '@jy/data-models/modules/article/model/expand'
import { appStorage } from '@/utils/storage'

import {
  getArticleAdvancedInfo,
  getArticleBaseInfo,
  getArticlePreAgentInfo
} from '@/api/modules/article'

const { model: mainModel, transformModel: contentTransform } = useContentModel()
const { model: expandModel, transformModel: expandTransform } = useExpandModel()

const CACHE_HISTORY_KEY = 'MOBILE_ARTICLE_CONTENT'
const storageConf = {
  login: true,
  storage: sessionStorage
}

const defaultValueMap = {
  preAgentInfo: {
    token: ''
  },
  reqInfo: {
    baseInfoReqToken: ''
  },
  otherModel: {
    scrollTop: 0,
    hasProject: false, // 当前标讯是否关联有项目，如果有，则可以点击查看项目信息
    forecastShow: false, // 是否显示中标企业预测
    // 项目关注状态同步
    projectFollowState: {},
    // 项目收藏标签
    collectionTags: [],
    // 参标人列表
    inBiddingPersonList: []
  },
  mainModel,
  expandModel,
  bindPhone: false
  // mainModel: {
  //   content: {
  //     id: '', // ABCY1xFfylYGSY7I2Bhc3ISCzAoIyFmYXh1KSgwNi8NaGpzZg1UCbM%3D
  //     isSelfSite: false,
  //     projectCode: '', // HNEC-YGZB230061001H013
  //     projectName: '', // 鹤壁煤电股份有限公司2023年设备采购第三批八矿水环真空泵
  //     title: '',// '[公开招标]鹤壁煤电股份有限公司2023年设备采购第三批八矿水环真空泵中标结果公告'
  //     keys: [], // ['发布', '信息']
  //     tags: [
  //       // {
  //       //   label: '河南-鹤壁市-淇滨区',
  //       //   link: 'javascript:volid(0);'
  //       // },
  //       // {
  //       //   label: '成交',
  //       //   link: '/list/stype/ZBJG.html'
  //       // },
  //       // {
  //       //   label: '能源化工',
  //       //   link: 'javascript:volid(0);'
  //       // },
  //       // {
  //       //   label: '1159.8万元',
  //       //   link: 'javascript:volid(0);'
  //       // },
  //       // {
  //       //   label: '2023-06-06',
  //       //   link: 'javascript:volid(0);'
  //       // }
  //     ],
  //     tdk: {
  //       description: '',
  //       // '[公开招标]鹤壁煤电股份有限公司2023年设备采购第三批八矿水环真空泵中标结果公告,项目所属地区是河南鹤壁市,项目采购单位是鹤壁煤电股份有限公司,项目发布时间是2023年06月06日,查看该项目信息详情请访问剑鱼标讯官网。',
  //       keywords: '',
  //       // '鹤壁煤电股份有限公司,淄博水环真空泵厂有限公司,成交公告,河南招标,鹤壁市招标,剑鱼标讯',
  //       title: '',
  //       // '[公开招标]鹤壁煤电股份有限公司2023年设备采购第三批八矿水环真空泵中标结果公告'
  //     }
  //   },
  //   summary: {
  //     buyers: [],
  //     list: [],
  //     winners: []
  //   }
  // },
  // expandModel: {
  //   services: [],
  //   projectProgress: {},
  //   recommendBuyers: [],
  //   recommendCustomers: [],
  //   recommendWinners: [],
  //   recommendProjects: []
  // }
}

export default {
  namespaced: true,
  // state() {
  //   return appStorage.get(
  //     CACHE_HISTORY_KEY,
  //     cloneDeep(defaultValueMap),
  //     storageConf
  //   )
  // },
  state() {
    return cloneDeep(defaultValueMap)
  },
  mutations: {
    // 重置store数据
    resetState(state, resetKey) {
      if (resetKey) {
        state[resetKey] = cloneDeep(defaultValueMap[resetKey]) || {}
      }
      else {
        const keysArr = Object.keys(state)
        keysArr.forEach((key) => {
          state[key] = cloneDeep(defaultValueMap[key]) || {}
        })
      }
    },
    setState(state, { key, data }) {
      state[key] = data
    },
    setPreAgentInfo(state, payload) {
      state.preAgentInfo = payload
    },
    setBaseInfoReqToken(state, payload) {
      state.reqInfo.baseInfoReqToken = payload
    },
    setMainContentModel(state, payload) {
      state.mainModel = payload
    },
    setMainContentModelChild(state, { key, data }) {
      if (state.mainModel.content) {
        state.mainModel.content[key] = data
      }
    },
    setMainSummaryModelChild(state, { key, data }) {
      if (state.mainModel.summary) {
        state.mainModel.summary[key] = data
      }
    },
    setExpandModel(state, payload) {
      state.expandModel = payload
    },
    setExpandModelChild(state, { key, data }) {
      if (state.expandModel) {
        state.expandModel[key] = data
      }
    },
    setOtherModelChild(state, { key, data }) {
      state.otherModel[key] = data
    },
    setBaseInfoBindPhone(state, payload) {
      state.bindPhone = payload
    }
  },
  actions: {
    // 获取当前标讯id
    getStateId({ state }) {
      return state.mainModel?.content?.id
    },
    // 重置当前store
    pageStateReset({ commit }) {
      commit('resetState')
    },
    // 缓存恢复
    async pageStateRestore({ commit, dispatch }) {
      const storage = await dispatch('getStateStorage')
      const thisId = await dispatch('getStateId')
      if (storage) {
        // 恢复数据
        let same = false
        try {
          same = thisId === storage.mainModel?.content?.id
        }
        catch (error) {
          console.log(error)
          same = false
        }
        if (same) {
          for (const key in storage) {
            commit('setState', { key, data: storage[key] })
          }
          // 恢复完数据，就清除缓存
          await dispatch('clearThisStorage')
        }
        return storage
      }
    },
    // 将数据存入缓存
    async saveStateToStorage({ state, dispatch }) {
      const storage = await dispatch('getAllStateFromStorage')
      const id = await dispatch('getStateId')
      if (storage) {
        // 如果缓存存在
        storage[id] = state
        appStorage.set(CACHE_HISTORY_KEY, storage, storageConf)
      }
      else {
        // 无缓存
        const storageNew = {
          [id]: state
        }
        appStorage.set(CACHE_HISTORY_KEY, storageNew, storageConf)
      }
    },
    // 获取缓存数据
    async getAllStateFromStorage() {
      return appStorage.get(CACHE_HISTORY_KEY, null, storageConf)
    },
    // 从缓存中获取当前标讯数据(id默认当前标讯id)
    async getStateStorage({ dispatch }, id) {
      const storage = await dispatch('getAllStateFromStorage')
      if (!id) {
        id = await dispatch('getStateId')
      }
      if (storage) {
        return storage[id]
      }
      else {
        return null
      }
    },
    // 清除缓存
    async clearAllStorage() {
      return appStorage.rm(CACHE_HISTORY_KEY, storageConf)
    },
    // 清除某条标讯的缓存数据
    async clearThisStorage({ dispatch }, id) {
      const storage = await dispatch('getAllStateFromStorage')
      if (!id) {
        id = await dispatch('getStateId')
      }
      if (storage) {
        delete storage[id]
      }
      return appStorage.set(CACHE_HISTORY_KEY, storage, storageConf)
    },
    async resetThisStoreAndClearStorage({ commit, dispatch }) {
      commit('resetState')
      dispatch('clearThisStorage')
    },
    async getPreAgentInfo({ commit }) {
      try {
        const {
          error_code: code,
          error_msg: msg,
          data
        } = await getArticlePreAgentInfo()
        if (code === 0 && data) {
          commit('setPreAgentInfo', data)
        }
        else {
          console.log(msg)
        }
      }
      catch (error) {
        console.error(error)
      }
    },
    async getBaseInfo({ commit, state }) {
      const { preAgentInfo } = state
      try {
        const {
          error_code: code,
          error_msg: msg,
          data
        } = await getArticleBaseInfo({ token: preAgentInfo.token })
        if (code === 0 && data) {
          const r = contentTransform(data)
          commit('setMainContentModel', r)
          commit('setBaseInfoReqToken', data.token)
          commit('setBaseInfoBindPhone', data.bindPhone)
        }
        else {
          console.log(msg)
        }
        return { data, msg }
      }
      catch (error) {
        console.error(error)
      }
    },
    async getAdvancedInfo({ commit, state }) {
      const { reqInfo } = state
      const token = reqInfo.baseInfoReqToken
      if (!token)
        return
      try {
        const {
          error_code: code,
          error_msg: msg,
          data
        } = await getArticleAdvancedInfo({ token })
        if (code === 0 && data) {
          const r = expandTransform(data)
          commit('setExpandModel', r)
        }
        else {
          console.log(msg)
        }
      }
      catch (error) {
        console.error(error)
      }
    }
  },
  getters: {}
}
