/**
 * 监控业务基础类
 * 1. 统一业务入口
 * 2. 统一接口返回值
 */
class MonitorApiBase {
  constructor() {
    this.model = this.createModel()
    // 分组列表
    this.groupList = []
    // 分组id
    this.groupId = ''
    // 通用函数Hooks
    this.doFetch = this.runFetch.bind(this)
    this.doChange = this.runChange.bind(this)
    this.doFetchGroup = this.runFetchGroup.bind(this)
    this.doChangeGroup = this.ajaxChange.bind(this)
    this.doOnlyGroup = this.ajaxOnlyGroup.bind(this)
  }

  /**
   * 获取监控状态并赋值
   */
  async runFetch() {
    let result = this.createModel()
    this.model = result = await this.ajaxGetState()
    return result
  }

  /**
   * 改变监控状态并返回结果
   * @param follow
   * @return {Promise<{}>}
   */
  async runChange(follow = this.model.follow, params = {}) {
    const { groupId } = params
    this.groupId = groupId
    let result = {
      // 操作是否成功
      success: false,
      // 接口返回值
      data: {}
    }

    const doAction = follow
      ? this.ajaxRemove.bind(this)
      : this.ajaxAdd.bind(this)
    result = await doAction()
    if (result.success) {
      this.model.follow = !follow
    }
    return result
  }

  /**
   * 获取监控分组信息并赋值
   */
  async runFetchGroup(params = {}) {
    const result = await this.ajaxGroup(params)
    if (result.success) {
      this.groupList = result.data
    }
    return result
  }

  createModel() {
    return {
      // 是否可以展示监控按钮
      canFollow: false,
      // 当前监控状态
      follow: false,
      // 可扩展字段
      expands: {
        // 剩余可用数量
        surplus: 0,
        // 已用数量
        used: 0
      },
      // 分组名
      followedGroup: ''
    }
  }

  /**
   * 提供覆盖的ajax请求
   * @return {{}}
   */
  async ajaxGetState() {
    return {}
  }

  async ajaxAdd() {
    return {}
  }

  async ajaxRemove() {
    return {}
  }

  async ajaxGroup() {
    return {}
  }

  async ajaxChange() {
    return {}
  }

  async ajaxOnlyGroup() {
    return {}
  }
}

export default MonitorApiBase
