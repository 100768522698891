import request from '@/api'
import qs from 'qs'
import { envs } from '@/utils/prototype/modules/platform'

// 发送邮箱验证码
export function sendMailCode(data) {
  data = qs.stringify(data)
  return request({
    url: envs.inWX
      ? '/front/dataExport/sendMailVerify'
      : '/jyapp/front/dataExport/sendMailVerify',
    method: 'POST',
    data
  })
}

// 验证邮箱验证码
export function checkMailCode(data) {
  data = qs.stringify(data)
  return request({
    url: envs.inWX
      ? '/front/dataExport/checkMailVerify'
      : '/jyapp/front/dataExport/checkMailVerify',
    method: 'POST',
    data
  })
}

// 数据导出预览数据
export function getPreview(data) {
  data = qs.stringify(data)
  return request({
    url: envs.inWX
      ? '/front/wx_dataExport/getPreview'
      : '/jyapp/front/dataExport/getPreview',
    method: 'POST',
    noToast: true,
    data
  })
}

export function getRewardText() {
  return request({
    method: 'get',
    url: '/front/rewardText'
  })
}
