// 用户认证信息保存
import request from '@/api'

// 根据商品ID、扩展等参数获取商品规格信息
export function ajaxGetProductInfo(data) {
  return request({
    url: '/jypay/commodity/detail',
    method: 'post',
    data
  })
}

// 根据商品ID、规格等参数获取商品优惠信息
export function ajaxGetProductOffers(data) {
  return request({
    url: '/jypay/commodity/couponInfo',
    method: 'post',
    data
  })
}

// 根据商品ID、规格、优惠等参数获取商品订单金额信息
export function ajaxGetProductOrderAmount(data) {
  return request({
    url: '/jypay/commodity/price',
    method: 'post',
    data
  })
}
// 根据商品ID、规格、优惠等参数获取商品订单金额信息
export function ajaxSubmitCreatedProductOrder(data) {
  return request({
    url: '/jypay/common/createorder',
    method: 'post',
    data
  })
}
