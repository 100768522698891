import Vue from 'vue'
import VerifyPointsComponent from '../components/VerifyPointsPopup.vue'

const VerifyPointsConstructor = Vue.extend(VerifyPointsComponent)

export default class VerifyPoints {
  vm = null // 保存实例
  // 实例
  get $vm () {
    if (this.vm) {
      return this.vm
    } else {
      return this.install()
    }
  }

  // 显示隐藏的状态
  get show () {
    if (!this.vm) {
      return false
    } else {
      return this.$vm?.value || false
    }
  }

  defaultConf = {
    noop: () => {},
    loginButtonClickNoop: () => {
      console.warn('登录事件未定义')
    },
    getContainer: 'body'
  }

  conf = {}

  constructor (conf = {}) {
    Object.assign(this.conf, this.defaultConf, conf)
  }

  install () {
    if (this.vm) {
      return this.vm
    }
    this.vm = new VerifyPointsConstructor()
    this.vm.$mount()
    this.vm.$on('input', this.onInput.bind(this))
    document.querySelector(this.conf.getContainer).appendChild(this.$vm.$el)
    return this.vm
  }

  verify (conf = {}) {
    if (!this.vm) {
      this.install()
    }

    const vm = this.$vm
    const { noop, loginButtonClickNoop } = this.conf
    const {
      value = false,
      hasLogin,
      imgBase64 = '',
      textVerify = '',
      loginButtonClick = loginButtonClickNoop,
      refresh = noop,
      confirm = noop
    } = conf

    vm.imgBase64 = imgBase64
    vm.textVerify = textVerify
    vm.showLoginButton = !hasLogin
    vm.loginButtonClick = loginButtonClick || loginButtonClickNoop

    // 移除所有事件监听
    vm.$off('refresh')
    vm.$off('confirm')

    if (value) {
      vm.$on('refresh', refresh.bind(this))
      vm.$on('confirm', confirm.bind(this))
    }
    // 显示或隐藏
    vm.value = value
  }

  onInput (value) {
    this.verify({ value })
  }
}
